import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_EXPENSE_TYPE = "fetch_expense_type";
export const LOADING_EXPENSE_TYPES = "loading_expense_types";

export const getExpenseTypes = () => {
    return dispatch => {
        dispatch({
            type: LOADING_EXPENSE_TYPES
        })
        makeRequest(GET_REQUEST, `${endpoints.expense_types}`, {}, response => {
            const payload = response.data;
            return dispatch({
                payload: payload,
                type: FETCH_EXPENSE_TYPE
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            return dispatch({
                payload: [],
                type: FETCH_EXPENSE_TYPE
            })
        }).then();
    }
}