import {parseErrorResponse, showAlert, throttle} from "../../helpers/helper_functions";
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";

class SearchFn {
    setLoading;
    setData;
    endpoint;

    /**
     *
     * @param {string} endpoint
     *
     * @param {string} value
     * @param setLoading
     * @param setData
     * @param queryParam
     * @param {number} [wait]
     * @param isPaginated
     */
    constructor(endpoint, value, setLoading, setData, queryParam = "query", isPaginated = false, wait = 500) {
        this.setLoading = setLoading;
        this.setData = setData;
        this.endpoint = endpoint;
        this.isPaginated = isPaginated;
        this.queryParam = queryParam;
        if (this.search)
            this.search(value);
        else {
            this.search = this.searchFunc(wait);
            this.search(value);
        }
    }

    searchFunc = (wait) => throttle((value) => {
        this.setLoading(true);
        const url = this.endpoint.includes('?') ? `${this.endpoint}&${this.queryParam}=${value}` : `${this.endpoint}?${this.queryParam}=${value}`;
        makeRequest(GET_REQUEST, url, {}, response => {
            const data = this.isPaginated ? response.data.data : response.data;
            this.setData(data);
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        }, () => {
            this.setLoading(false);
        }).then()
    }, wait)
}

export default SearchFn;
