import {days, months} from "../../../../../providers/courses/data";

function CalendarDate({date, color, onClick}) {
    return (
        <div onClick={() => onClick(date)}
             className={`col-md-3 col-sm-4 clickable px-3 text-center
              ${color ? color : "bg-light"} ${color ? "text-white" : ""}
                 rounded mx-2 my-2`}>
            <p className="small m-0">{months[date.getMonth()]}</p>
            <p className="m-0">{days[date.getDay()]} {date.getDate()}</p>
            <p className="small m-0">{date.getFullYear()}</p>
        </div>

    )
}

export default CalendarDate;