/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router";
import Loader_fn from "../../providers/loader_fn";
import endpoints from "../../routes/endpoints";
import CustomLoader from "../../components/loaders/custom_loader";
import Table from "../../components/Table";
import {expenseColumns, expenseColumnsNoAction} from "../../providers/expenses/data";
import {numberWithCommas, onChange, parseErrorResponse, parseUrls, showAlert} from "../../helpers/helper_functions";
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST, roles} from "../../values/globals";
import {getBranches} from "../../redux/branch/actions";
import {useDispatch, useSelector} from "react-redux";
import {getUserObject} from "../../helpers/login";
import PrintService from "../print";
import {getExpenseTypes} from "../../redux/expense_type/actions";

export const options = {
    add: "add",
    add_and_ignore: "add_and_ignore",
    delete: "delete",
    delete_all: "delete_all",
    replace: "replace"
}

const $ = window.$;

function Income() {
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [loadingStats, setLoadingStats] = useState();
    const [stats, setStats] = useState();
    const {branches} = useSelector(state => state.branchesState);
    const {expense_types} = useSelector(state => state.expenseTypesState);
    const dispatch = useDispatch();
    const [income, setInventory] = useState([]);
    const loggedInUser = getUserObject();
    const [currentEndpoint, setCurrentEndpoint] = useState();
    const [printProps, setPrintProps] = useState({});
    const [label, setLabel] = useState();
    const [fields, setFields] = useState({});

    const handleAddExpense = e => {
        e.preventDefault();
        history.push(`/income/add`)
    }

    useEffect(() => {
        getEmBranches();
        getEmExpenseTypes();
        getStats();
        return getExpenses();
    }, []);

    const getExpenses = (endpoint = endpoints.income) => {
        setCurrentEndpoint(endpoint);
        const loaderFn = new Loader_fn(
            endpoint, setLoading, setInventory);
        return loaderFn.onUnMount();
    }

    const getEmBranches = () => {
        if ((loggedInUser?.role === roles.super_admin
            || loggedInUser?.role === roles.extension_administrator
            || loggedInUser?.role === roles.super_finance))
            if (branches == null)
                dispatch(getBranches());
    }

    const getEmExpenseTypes = () => {
        if (expense_types == null)
            dispatch(getExpenseTypes());
    }

    const getStats = (endpoint = endpoints.income_stats) => {
        setLoadingStats(true);
        makeRequest(GET_REQUEST, endpoint, {}, response => {
            setStats(response.data);
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        }).then(() => setLoadingStats(false))
    }


    const handlePrintReport = () => {
        let endpoint = `${currentEndpoint}`;
        if (endpoint?.includes("?"))
            endpoint = `${endpoint}&dont_paginate=true`;
        else
            endpoint = `${endpoint}?dont_paginate=true`;
        const printProps = {
            endpoint: endpoint,
            columns: expenseColumnsNoAction,
            title: `All Expenses${label ? ` - ${label}` : ""}`,
            subtitle:
                <h4>
                    Total Income: KES <b>{numberWithCommas(stats?.total_expense ?? "-")}</b> <br/>
                </h4>,
            hideCaption: true
        };
        setPrintProps(printProps);
        $('#printServiceModal').modal('show');
    }

    //Handle automatic fetching when field urls change.
    useEffect(() => {
        parseUrls([endpoints.income, endpoints.income_stats],
            [
                (urls) => getExpenses(urls[0]),
                (urls) => getStats(urls[1]),
                setLabel("NPBC Admin")], fields)
    }, [fields])

    return (
        <div className="row">
            <PrintService {...printProps}/>
            <div className="col-12">
                {loading &&
                <CustomLoader/>
                }
                <div className="card">
                    <div className="card-body">
                        {loadingStats ? <CustomLoader/> :
                            <div className="row">
                                <div className="col-md">
                                    <div className="card mb-4 bg-purple">
                                        <div className="card-body">
                                            <div className="media d-flex align-items-center ">
                                                <div
                                                    className="mr-4 rounded-circle bg-white sr-icon-box text-purple">
                                                    <span style={{fontSize: "2rem"}} className="icon-people"/>
                                                </div>
                                                <div className="media-body text-light">
                                                    <h4 className="text-uppercase mb-0 weight500">{numberWithCommas(stats?.total_expense ?? "-")}</h4>
                                                    <span>Total Income</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect1">Filter By Type</label>
                            <select onChange={e => {
                                onChange("type", e.target.value, options.replace, fields, setFields);
                            }} className="form-control">
                                <option value={""}>All Types</option>
                                <option value={"fee"}>Fee</option>
                                {expense_types?.map(expense_type =>
                                    <option key={expense_type?.id}
                                            value={expense_type?.id}>{expense_type?.name}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect1">Filter By Center</label>
                            <select onChange={e => {
                                onChange("branch", e.target.value, options.replace, fields, setFields);
                            }} className="form-control">
                                <option value={""}>All Centers</option>
                                {branches?.map(branch =>
                                    <option key={branch?.id} value={branch?.id}>{branch?.name}</option>
                                )}
                            </select>
                        </div>
                        <div className="text-center">
                            <button onClick={handlePrintReport} className="btn btn-primary btn-sm">
                                Print
                            </button>
                        </div>
                        <Table
                            caption={"All Income"}
                            hideAction={false}
                            actionText={"Add Income"}
                            actionFunc={handleAddExpense}
                            columns={expenseColumns}
                            /*filterColumns={loggedInUser.role === roles.super_admin ? columns : []}
                            filterAction={(value, label) => {
                                setLabel(label);
                                getExpenses(`${endpoints.income}?branch=${value}`);
                                getStats(`${endpoints.income_stats}?branch=${value}`);
                            }}*/
                            data={income}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Income;