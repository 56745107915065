import React, {Component} from "react";
import FeeStatements from "../fee-statements";

class Dashboard extends Component {
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <FeeStatements/>
                </div>
            </div>
        );
    }
}

export default Dashboard;