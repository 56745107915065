import React, {Component} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../../components/Table";
import Swal from "sweetalert2";
import {makeRequest} from "../../../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";
import GradeAdd from "./add";

const $ = window.$;

let self;
const columns = [
    {
        dataField: 'from',
        text: 'From',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'to',
        text: 'To',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'grade',
        text: 'Grade',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'iddd',
        text: 'Action',
        formatter: (id, row) => <button onClick={() => self.handleEditGrade(row)}
                                        className="btn btn-sm btn-primary">
            Edit
        </button>

    },
    {
        dataField: 'idd',
        text: 'Action',
        formatter: (id, row) => <button disabled={self.state.loadingDelete}
                                        onClick={() => self.handleDeleteGrade(row.id)}
                                        className="btn btn-sm btn-danger">
            {self.state.loadingDelete ? "Loading" : "Delete"}
        </button>

    },
]

class Grades extends Component {
    state = {grades: []};

    componentDidMount() {
        self = this;
        this.getGrades();
    }

    handleAddGrade = e => {
        e.preventDefault();
        this.setState({
            grade: null
        }, () => $('#addGradeModal').modal('show'));
    }

    handleEditGrade = (grade) => {
        this.setState({
            grade: grade
        }, () => $('#addGradeModal').modal('show'));
    }

    handleDeleteGrade = (id) => {
        Swal.fire({
            title: "Confirm Delete",
            text: `Are you sure you want to delete this grade?`,
            icon: "info",
            showConfirmButton: true,
            showCancelButton: true
        }).then(result => {
            if (result.isConfirmed) {

                this.setState({
                    loadingDelete: true
                })
                makeRequest(DELETE_REQUEST, `${endpoints.grades}${id}/`, {}, () => {
                    Swal.fire(
                        'Success!',
                        `Grade was deleted successfully!`,
                        'success'
                    ).then(() => {
                        this.getGrades();
                    });
                }, (error) => {
                    showAlert('error', 'Error', parseErrorResponse(error));
                }, () => {
                    this.setState({loadingDelete: false})
                }).then()
            }
        })
    }

    getGrades = () => {
        this.setState({loading: true});
        makeRequest(GET_REQUEST, `${endpoints.grades}`, {}, response => {
            this.setState({grades: response.data});
        }, (error) => {
            showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            this.setState({loading: false})
        }).then()

    }


    render() {
        const {loading, grades,grade} = this.state;
        return (loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div>
                    <GradeAdd grade={grade} key={Math.random()}
                              getGrades={this.getGrades}/>
                    <Table
                        caption={"Grades"}
                        actionText={"Add Grade"}
                        columns={columns}
                        actionFunc={this.handleAddGrade}
                        data={grades}
                    />
                </div>
        );
    }
}

export default Grades;