import {FETCH_BRANCHES, LOADING_BRANCHES} from "./actions";

const branchesState = {
    branches: null,
};

const reducer = (state = branchesState, action) => {
    if (action.type === FETCH_BRANCHES) {
        return {...state, branches: action.payload, loadingBranches: false};
    }
    if (action.type === LOADING_BRANCHES) {
        return {...state, loadingBranches: true};
    }
    return state;
};

export default reducer;