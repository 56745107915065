import Dashboard from "../pages/coordinator/dashboard";
import Expenses from "../pages/expenses";
import SemesterTimetable from "../pages/coordinator/semester_timetable";
import ExamTimetable from "../pages/coordinator/exam_timetable";
import Inventory from "../pages/coordinator/inventory";
import InventoryDetails from "../pages/coordinator/inventory/details";
import InventoryAdd from "../pages/coordinator/inventory/add";
import ExpenseAdd from "../pages/expenses/add";
import ExpenseDetails from "../pages/expenses/details";
import Incomes from "../pages/incomes";
import IncomeAdd from "../pages/incomes/add";
import IncomeDetails from "../pages/incomes/details";

const coordinatorUrls = {
    index: {
        path: '/',
        exact: true,
        component: Dashboard,
    },

    expenses: {
        path: '/expenses',
        exact: true,
        component: Expenses,
    },
    expenses_add: {
        path: '/expenses/add',
        exact: true,
        component: ExpenseAdd,
    },
    expenses_edit: {
        path: '/expenses/add/:id',
        exact: true,
        component: ExpenseAdd,
    },
    expenses_details: {
        path: '/expenses/:id',
        exact: true,
        component: ExpenseDetails,
    },

    income: {
        path: '/income',
        exact: true,
        component: Incomes,
    },
    income_add: {
        path: '/income/add',
        exact: true,
        component: IncomeAdd,
    },
    income_edit: {
        path: '/income/add/:id',
        exact: true,
        component: IncomeAdd,
    },
    income_details: {
        path: '/income/:id',
        exact: true,
        component: IncomeDetails,
    },

    semester_timetable: {
        path: '/semester-timetable',
        exact: true,
        component: SemesterTimetable,
    },

    exam_timetable: {
        path: '/exam-timetable',
        exact: true,
        component: ExamTimetable,
    },

    inventory: {
        path: "/inventory",
        exact: true,
        component: Inventory
    },

    inventory_details: {
        path: "/inventory/:id",
        exact: true,
        component: InventoryDetails
    },

    inventory_add: {
        path: "/inventory/add",
        exact: true,
        component: InventoryAdd
    },

    inventory_edit: {
        path: "/inventory/add/:id",
        exact: true,
        component: InventoryAdd
    },
}

export default coordinatorUrls;