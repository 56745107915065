import React, {Component} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../../components/Table";
import CourseTypeAdd from "./add";
import Swal from "sweetalert2";
import {makeRequest} from "../../../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";

const $ = window.$;

let self;
const columns = [
    {
        dataField: 'id',
        text: 'ID',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'name',
        text: 'Name',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'iddd',
        text: 'Action',
        formatter: (id, row) => <button onClick={() => self.handleEditBranch(row)}
                                        className="btn btn-sm btn-primary">
            Edit
        </button>

    },
    {
        dataField: 'idd',
        text: 'Action',
        formatter: (id, row) => <button disabled={self.state.loadingDelete}
                                        onClick={() => self.handleDeleteCourse(row.id)}
                                        className="btn btn-sm btn-danger">
            {self.state.loadingDelete ? "Loading" : "Delete"}
        </button>

    },
]


class CourseTypes extends Component {
    state = {};

    componentDidMount() {
        self = this;
        this.getCourseTypes();
    }

    handleAddCourse = e => {
        e.preventDefault();
        this.setState({
            course_type: null
        }, () => $('#addCourseTypeModal').modal('show'));
    }

    handleEditBranch = (course_type) => {
        this.setState({
            course_type: course_type
        }, () => $('#addCourseTypeModal').modal('show'));

    }

    handleDeleteCourse = (id) => {
        Swal.fire({
            title: "Confirm Delete",
            text: `Are you sure you want to delete this course type?`,
            icon: "info",
            showConfirmButton: true,
            showCancelButton: true
        }).then(result => {
            if (result.isConfirmed) {

                this.setState({
                    loadingDelete: true
                })
                makeRequest(DELETE_REQUEST, `${endpoints.course_types}${id}/`, {}, () => {
                    Swal.fire(
                        'Success!',
                        `Course type was deleted successfully!`,
                        'success'
                    ).then(() => {
                        this.getCourseTypes();
                    });
                }, (error) => {
                    showAlert('error', 'Error', parseErrorResponse(error));
                }, () => {
                    this.setState({loadingDelete: false})
                }).then()
            }
        })
    }

    getCourseTypes = () => {
        this.setState({loading: true});
        makeRequest(GET_REQUEST, `${endpoints.course_types}`, {}, response => {
            this.setState({course_types: response.data});
        }, (error) => {
            showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            this.setState({loading: false})
        }).then()
    }

    render() {
        const {loading, course_types, course_type} = this.state;
        return (loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div>
                    <CourseTypeAdd course_type={course_type} key={Math.random()}
                                   getCourseTypes={this.getCourseTypes}/>
                    <Table
                        caption={"Course Types"}
                        actionText={"Add Course Type"}
                        columns={columns}
                        actionFunc={this.handleAddCourse}
                        data={course_types}
                    />
                </div>
        );
    }
}

export default CourseTypes;