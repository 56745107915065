/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import Swal from "sweetalert2";
import CircularProgress from "@material-ui/core/CircularProgress";
import notFound from "../../../assets/img/404.png";
import HTMLReactParser from "html-react-parser";
import {useHistory} from "react-router";

function NewsDetails(props) {
    const [loading, setLoading] = useState();
    const [news, setNews] = useState();
    const [loadingDelete, setLoadingDelete] = useState();
    const history = useHistory();

    const getNews = () => {
        const {id} = props.match.params;
        setLoading(true);
        makeRequest(
            GET_REQUEST,
            `${endpoints.news}${id}`,
            {},
            response => {
                setNews(response.data);
            },
            error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }, () => {
                setLoading(false);
            }).then();
    }

    const handleDelete = () => {
        Swal.fire({
                title: 'Information!',
                text: 'Are you sure you want delete this?',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancel'
            }
        ).then(result => {
            if (result.isConfirmed) {
                setLoadingDelete(true);
                makeRequest(DELETE_REQUEST, `${endpoints.news}${news?.id}`, {}, () => {
                    Swal.fire(
                        'Success!',
                        'News deleted successfully!',
                        'success',
                    ).then(() => history.push('/news'));
                }, error => {
                    showAlert('error', 'Error', parseErrorResponse(error))
                }, () => {
                    setLoadingDelete(false);
                }).then()
            }

        });
    }

    useEffect(() => {
        getNews();
    }, []);
    return (
        <div className="mb-5">
            {
                loading ?
                    <div className="text-center">
                        <CircularProgress/>
                    </div> :
                    news ?
                        <div className="col-12 card px-5 shadow-sm py-5">
                            <div className="d-flex justify-content-between mb-5">
                                <h1 className="m-0"> {news.title}</h1>
                                <div>
                                    <button onClick={() => {
                                        history.push(`/news/add/${news.slug}`)
                                    }}
                                            className="btn btn-sm btn-info ml-3">Edit
                                    </button>
                                    <button
                                        disabled={loadingDelete}
                                        onClick={handleDelete}
                                        className="btn btn-sm btn-danger ml-3">{loadingDelete ? "Loading.." : "Delete"}
                                    </button>
                                </div>
                            </div>
                            <div style={{height: "60vh"}}>
                                <img style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain"
                                }}
                                     src={news.cover_image || notFound}
                                     alt={"news placeholder"}/>
                            </div>
                            <h4 className="my-5"> {news.description}</h4>
                            <div style={{overflow: "hidden"}} className="w-100">
                                {HTMLReactParser(news?.body || "")}
                            </div>
                        </div> :
                        <div className="text-center">
                            <p>News could not be loaded.</p>
                        </div>
            }
        </div>
    );
}

export default NewsDetails;