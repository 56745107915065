import React, {Component} from 'react';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../../components/Table";
import IncomeTypeAdd from "./add";
import Swal from "sweetalert2";
import {makeRequest} from "../../../../helpers/network_utils";
import {DELETE_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";
import {getIncomeTypes} from "../../../../redux/income_type/actions";

const $ = window.$;

const mapStateToProps = state => (state.incomeTypesState);

const mapDispatchToProps = dispatch => ({
    getIncomeTypes: () => dispatch(getIncomeTypes()),
});
let self;
const columns = [
    {
        dataField: 'id',
        text: 'ID',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'name',
        text: 'Name',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'iddd',
        text: 'Action',
        formatter: (id, row) => <button onClick={() => self.handleEditBranch(row)}
                                        className="btn btn-sm btn-primary">
            Edit
        </button>
    },
    {
        dataField: 'idd',
        text: 'Action',
        formatter: (id, row) => <button disabled={self.state.loadingDelete}
                                        onClick={() => self.handleDeleteBranch(row.id)}
                                        className="btn btn-sm btn-danger">
            {self.state.loadingDelete ? "Loading" : "Delete"}
        </button>

    },
]


class IncomeTypes extends Component {
    state = {};

    componentDidMount() {
        self = this;
        const {getIncomeTypes, income_types} = this.props;
        if (income_types == null) getIncomeTypes();
    }

    handleAddIncome = e => {
        e.preventDefault();
        this.setState({
            income_type: null
        }, () => $('#addIncomeTypeModal').modal('show'));
    }

    handleEditBranch = (income_type) => {
        this.setState({
            income_type: income_type
        }, () => $('#addIncomeTypeModal').modal('show'));

    }

    handleDeleteBranch = (id) => {
        Swal.fire({
            title: "Confirm Delete",
            text: `Are you sure you want to delete this Income Type?`,
            icon: "info",
            showConfirmButton: true,
            showCancelButton: true
        }).then(result => {
            if (result.isConfirmed) {

                this.setState({
                    loadingDelete: true
                })
                makeRequest(DELETE_REQUEST, `${endpoints.income_types}${id}/`, {}, () => {
                    Swal.fire(
                        'Success!',
                        `Income Type was deleted successfully!`,
                        'success'
                    ).then(() => {
                        this.props.getBranches();
                    });
                }, (error) => {
                    showAlert('error', 'Error', parseErrorResponse(error));
                }, () => {
                    this.setState({loadingDelete: false})
                }).then()
            }
        })
    }

    render() {
        const {loadingIncomeTypes, income_types,} = this.props;
        const {income_type} = this.state;
        return (loadingIncomeTypes ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div>
                    <IncomeTypeAdd income_type={income_type} key={Math.random()}
                               getIncomeTypes={this.props.getIncomeTypes}/>
                    <Table
                        caption={"Income Types"}
                        actionText={"Add Income Type"}
                        columns={columns}
                        actionFunc={this.handleAddIncome}
                        data={income_types}
                    />
                </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomeTypes);