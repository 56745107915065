/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router";
import endpoints from "../../../../../../routes/endpoints";
import {makeRequest} from "../../../../../../helpers/network_utils";
import {GET_REQUEST, POST_REQUEST} from "../../../../../../values/globals";
import {
    handleChangeDataFn,
    parseErrorResponse,
    showAlert,
    toDateStringWordsFromString
} from "../../../../../../helpers/helper_functions";
import Swal from "sweetalert2";
import {days, months, weeks} from "../../../../../../providers/courses/data";
import CustomLoader from "../../../../../../components/loaders/custom_loader";
import FeeConfig from "./fee_config";
import ExamDeadline from "./exam_deadline";
import ExamConfig from "./exam_config";
import Units from "../units";

const pages = {
    index: "index",
    fee_config: "fee_config",
    exam_deadlines: "exam_deadlines",
    exam_config: "exam_config",
    units: "units"
}

function SemesterAdd() {
    const [loading, setLoading] = useState();
    const [loadingCourse, setLoadingCourse] = useState();
    const [data, setData] = useState({});
    const [semester, setSemester] = useState();
    const [errors, setErrors] = useState({});
    const {id, courseId} = useParams();
    const [course, setCourse] = useState();
    const history = useHistory();
    const [currentPath, setCurrentPath] = useState(pages.index);
    useEffect(() => {
        getSemestersDetails();
        getCourseDetails();
    }, [id, courseId])

    const getYears = () => {
        const months = course?.months;
        let years = [];
        if (months != null) {
            let duration = months / 12;

            for (let i = 0; i < duration; i++)
                years.push(i + 1);
        }
        return years;
    }

    const getCourseDetails = () => {
        if (courseId) {
            setLoadingCourse(true);
            makeRequest(GET_REQUEST, `${endpoints.courses}${courseId}`, {}, response => {
                setCourse(response.data)
            }, () => {
                showAlert('error', 'Error', 'Could not load course', () => history.goBack());
            }, () => {
                setLoadingCourse(false);
            }).then()
        }
    }
    const getSemestersDetails = () => {
        if (id) {
            setLoading(true);
            makeRequest(GET_REQUEST, `${endpoints.semesters}${id}`, {}, response => {
                const allowedFields = [
                    "weeks", "start_month", "start_week", "start_day", "year", "number"
                ];
                let data = {...response.data};
                const filteredData = {};
                for (let i = 0; i < allowedFields.length; i++) {
                    const key = allowedFields[i];
                    filteredData[key] = data[key];
                }
                setData(filteredData);
                setSemester(data);
            }, () => {
                showAlert('error', 'Error', 'Could not load semester', () => history.goBack());
            }, () => {
                setLoading(false);
            }).then()
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        setErrors({});
        let formData = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = data[key];
            if (key === "cover_image") {
                if (typeof data[key] === 'object')
                    formData.append(key, value);
            } else
                formData.append(key, value);
        }
        formData.append("course", courseId);
        const method = POST_REQUEST;
        if (id)
            formData.append('_method', 'PATCH');
        const route = id ? `${endpoints.semesters}${id}` : endpoints.semesters;
        makeRequest(method, route, formData, response => {
            const semester = response.data;
            Swal.fire(
                'Success!',
                `Semester was ${id ? "edited" : "saved"} successfully! You can scroll below to edit other info or click finish to exit.`,
                'success'
            ).then();
            setSemester(semester);
        }, (error) => {
            if (error.response.status === 400) {
                showAlert('error', 'Error', 'Check inputs for errors');
                setErrors(error.response.data)
            } else
                showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false);
        }).then()
    }

    return (
        <div className="container">
            <div className="row mx-auto">
                <div className="col-12">
                    <div className="profile-nav-links mb-3">
                        <ul className="nav f12">
                            <li className="nav-item">
                                <a onClick={e => {
                                    e.preventDefault();
                                    setCurrentPath(pages.index);
                                }} href={"/a"}
                                   className={`nav-link ${currentPath === pages.index && 'active'}`}>
                                    Semester
                                </a>
                            </li>
                            {semester && <li className="nav-item">
                                <a
                                    onClick={e => {
                                        e.preventDefault();
                                        setCurrentPath(pages.fee_config);
                                    }} href={"/b"}
                                    className={`nav-link ${currentPath === pages.fee_config && 'active'}`}>
                                    Fee Configuration</a>
                            </li>}
                            {semester && <li className="nav-item">
                                <a
                                    onClick={e => {
                                        e.preventDefault();
                                        setCurrentPath(pages.exam_config);
                                    }} href={"/c"}
                                    className={`nav-link ${currentPath === pages.exam_config && 'active'}`}>
                                    Exam Config
                                </a>
                            </li>}
                            {semester && <li className="nav-item">
                                <a
                                    onClick={e => {
                                        e.preventDefault();
                                        setCurrentPath(pages.exam_deadlines);
                                    }} href={"/c"}
                                    className={`nav-link ${currentPath === pages.exam_deadlines && 'active'}`}>
                                    Exam Deadlines
                                </a>
                            </li>}
                            {semester && <li className="nav-item">
                                <a
                                    onClick={e => {
                                        e.preventDefault();
                                        setCurrentPath(pages.units);
                                    }} href={"/c"}
                                    className={`nav-link ${currentPath === pages.units && 'active'}`}>
                                    Units
                                </a>
                            </li>}
                            <li className="ml-auto d-flex align-items-center">
                                <button onClick={() => {
                                    history.push(`/courses/${course?.id}`)
                                }} type="button" className="btn btn-info btn-sm"
                                        disabled={loading}>{loading ? "Loading..." : "Finish"}
                                </button>
                            </li>
                        </ul>
                    </div>
                    {currentPath === pages.index &&
                    <div className="card">
                        <div className="card-body">
                            <form>
                                {loadingCourse ? <CustomLoader/> :
                                    <div className="form-group">
                                        <label>Course</label>
                                        <p>{course?.name}</p>
                                    </div>}
                                <div className="form-group">
                                    <label>Year</label>
                                    <select onChange={e => setData({...data, year: e.target.value})}
                                            className="form-control">
                                        {data?.year ? <option value={data?.year}>{data?.year}</option> :
                                            <option>Select Year</option>}
                                        {getYears().map(year => <option key={year} value={year}>{year}</option>)}
                                    </select>
                                </div>
                                {course && <h5>Hint: Course starts at:
                                    {` ${toDateStringWordsFromString(
                                        course?.start_date)}`}</h5>
                                }
                                <div className="form-group">
                                    <label className="m-0">Start Day</label>
                                    <p className="small">Specifies the day of week the exam begins within this
                                        semester.</p>
                                    <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                            name="start_day">
                                        {data?.start_day ?
                                            <option value={data?.start_day}>{days[data?.start_day - 1]}</option> :
                                            <option>Select Day</option>}
                                        {days.map((day, index) => <option key={index} value={index + 1}>{day}</option>)}
                                    </select>
                                    {errors?.start_day &&
                                    <p className="small text-danger">{errors?.start_day[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <label className="m-0">Start Week</label>
                                    <p className="small">Specifies the week the exam ends within this semester.</p>
                                    <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                            name="start_week">
                                        {data?.start_week ?
                                            <option value={data?.start_week}>{weeks[data?.start_week - 1]}</option> :
                                            <option>Select Week</option>}
                                        {weeks.map((week, index) =>
                                            <option key={index} value={index + 1}>{week}</option>)}
                                    </select>
                                    {errors?.start_week &&
                                    <p className="small text-danger">{errors?.start_week[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <label className="m-0">Start Month</label>
                                    <p className="small">Specifies the month the exam begins within this semester.</p>
                                    <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                            name="start_month">
                                        {data?.start_month ?
                                            <option value={data?.start_month}>{months[data?.start_month - 1]}</option> :
                                            <option>Select Month</option>}
                                        {months.map((week, index) => <option key={index}
                                                                             value={index + 1}>{week}</option>)}
                                    </select>
                                    {errors?.start_month &&
                                    <p className="small text-danger">{errors?.start_month[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <label>Semester Duration (in weeks)</label>
                                    <input value={data?.weeks || ""} onChange={(e) => handleChangeDataFn(e, setData)}
                                           type="number"
                                           className="form-control" name="weeks"/>
                                    {errors?.weeks && <p className="small text-danger">{errors?.weeks[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <label>Semester Number (i.e. Semester 1, 2 etc.)</label>
                                    <input value={data?.number || ""} onChange={(e) => handleChangeDataFn(e, setData)}
                                           type="number"
                                           className="form-control" name="number"/>
                                    {errors?.number && <p className="small text-danger">{errors?.number[0]}</p>}
                                </div>
                            </form>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => history.goBack()}>Cancel
                                </button>
                                <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                        disabled={loading}>{loading ? "Loading..." : "Save"}
                                </button>
                            </div>
                        </div>
                    </div>}
                </div>
                {currentPath === pages.fee_config && <FeeConfig semester={semester}/>}
                {currentPath === pages.exam_deadlines && <ExamDeadline semester={semester}/>}
                {currentPath === pages.units && <Units semester={semester}/>}
                {currentPath === pages.exam_config && <ExamConfig semester={semester} callback={(examConfig) => {
                    let currentSemester = {...semester};
                    currentSemester.exam = examConfig;
                    setSemester(currentSemester);
                }}/>}
            </div>
        </div>
    );
}

export default SemesterAdd;