/* eslint-disable react-hooks/exhaustive-deps */
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import {useEffect, useState} from "react";
import CustomLoader from "../loaders/custom_loader";
import PropTypes from 'prop-types';

function YearSemesterSelect({user, onYearChange, onSemesterChange}) {
    const [semester, setSemester] = useState();
    const [semesters, setSemesters] = useState([]);
    const [loadingSemester, setLoadingSemester] = useState(false);

    useEffect(() => {
        if (user) {
            getSemesters(user?.semester?.year, user);
            onSemesterChange(user?.semester)
            if (onYearChange)
                onYearChange(user?.semester?.year);
        }
    }, [user])

    const onChangeYear = e => {
        const year = e.target.value;
        getSemesters(year, user);
        if (onYearChange)
            onYearChange(year);
    }

    const onChangeSemester = e => {
        let semester = e.target.value ?? "";
        if (semester.length > 0) {
            semester = JSON.parse(semester);
            setSemester(semester);
            if (onSemesterChange)
                onSemesterChange(semester);
        }
    }

    /**
     *
     * @param year
     * @param user
     */
    const getSemesters = (year, user) => {
        setLoadingSemester(true);
        makeRequest(GET_REQUEST, `${endpoints.semesters}?course=${user?.semester?.course?.id}&year=${year}`, {}, response => {
            setSemesters(response.data);
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error));
        }).then(() => setLoadingSemester(false));
    }

    const getYears = () => {
        const months = semester?.course?.months || user?.semester?.course?.months;
        let years = [];
        if (months != null) {
            let duration = months / 12;

            for (let i = 0; i < duration; i++)
                years.push(i + 1);
        }
        return years;
    }

    return (
        <div className="row">
            <div className="col-md p-3">
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Year</label>
                    <select onChange={onChangeYear} className="form-control"
                            id="exampleFormControlSelect1">
                        <option>Select Year</option>
                        {getYears().map(year => <option key={year} value={year}>{year}</option>)}
                    </select>
                </div>
            </div>
            {loadingSemester ?
                <CustomLoader/> :
                <div className="col-md p-3">
                    <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Semester</label>
                        <select onChange={onChangeSemester} className="form-control"
                                id="exampleFormControlSelect1">
                            <option>Select Semester</option>
                            {semesters.map((semester) =>
                                <option key={semester?.id}
                                        value={JSON.stringify(semester)}>{semester?.number}</option>)}
                        </select>
                    </div>
                </div>}
        </div>
    )
}

YearSemesterSelect.propTypes = {
    user: PropTypes.object.isRequired,
    onYearChange: PropTypes.func,
    onSemesterChange: PropTypes.func,
}

export default YearSemesterSelect;