/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import Loader_fn from "../../../../../../providers/loader_fn";
import endpoints from "../../../../../../routes/endpoints";
import UnitAdd from "./add";
import CustomLoader from "../../../../../../components/loaders/custom_loader";
import {makeRequest} from "../../../../../../helpers/network_utils";
import {DELETE_REQUEST} from "../../../../../../values/globals";
import {parseErrorResponse, showAlert} from "../../../../../../helpers/helper_functions";

const $ = window.$;

function Units({semester}) {
    const [loading, setLoading] = useState();
    const [units, setUnits] = useState([]);

    useEffect(() => {
        return getUnits();
    }, [])


    const getUnits = () => {
        const loader = new Loader_fn(`${endpoints.units}?semester=${semester?.id}`, setLoading, setUnits);
        return loader.onUnMount();
    }

    const handleAddUnit = e => {
        e.preventDefault();
        $('#addUnitModal').modal('show');
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    {loading ?
                        <div className="text-center">
                            <CustomLoader/>
                        </div> :
                        <div className="text-center card">
                            <UnitAdd semester={semester} getUnits={getUnits}/>
                            <div className="card-body">
                                <div className="text-center">
                                    <button onClick={handleAddUnit} className="btn btn-info btn-sm">
                                        Add Unit
                                    </button>
                                </div>
                                {
                                    units?.map(unit => <SingleUnit key={unit?.id} unit={unit} semester={semester}
                                                                   callback={getUnits}/>)
                                }
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    );
}

function SingleUnit({unit, semester, callback}) {
    const [loading, setLoading] = useState();
    const handleEditUnit = e => {
        setLoading(true);

        makeRequest(DELETE_REQUEST, `${endpoints.semester_units}?semester=${semester?.id}&unit=${unit?.id}`,
            {}, () => {
                showAlert('success', 'Success', 'Unit removed successfully!');
                if (callback)
                    callback();
            }, error => {
                showAlert('error', 'Error', parseErrorResponse(error));
            }).then(() => {
            setLoading(false);
        })
    }

    return (
        <div className="card my-3">
            <UnitAdd unit={unit} semester={semester} getUnits={callback}/>
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <h5>{unit?.name}</h5>
                    <button disabled={loading} onClick={handleEditUnit} className="btn btn-sm btn-danger ml-3">
                        {loading ? "Loading" : "Remove"}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default Units;