import React, {Component} from 'react';
import {POST_REQUEST} from "../../../values/globals";
import Swal from "sweetalert2";
import {makeRequest} from "../../../helpers/network_utils";
import {handleChangeData, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import logo from '../../../assets/img/logo.png';
import endpoints from "../../../routes/endpoints";
import {getUserObject, setUserObject} from "../../../helpers/login";

const $ = window.$;

class Login extends Component {
    state = {
        error: null,
        response: null,
        email: '',
        password: '',
        loading: false,
        showPassword: false,
        data: {},
        errors: {}
    };

    componentDidMount() {
        $(".input-main-border").find(":input").focus(function () {
            $(this).parent().css("border-color", "blue");
        }).blur(function () {
            $(this).parent().css("border-color", "var(--primary-color)");
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({
            loading: true,
            errors: {}
        });
        const {data} = this.state;

        makeRequest(POST_REQUEST, endpoints.login, data, () => {
            setUserObject(
                () => ({}),
                () => {
                    this.setState({
                        loading: false
                    });
                    const user = getUserObject();
                    if (user.first_user)
                        showAlert('info', 'Change Password', 'Kindly change your password. Set old password' +
                            ' as the password sent to your email', () => {
                            window.location.href = "/change-password";
                        });
                    else
                        window.location.href = "/";
                });
        }, error => {
            if (error.response.status === 400)
                this.setState({errors: error.response.data})
            else
                this.setState({error: parseErrorResponse(error)})
        }, () => {
            this.setState({loading: false})
        })
    }

    resetPasswordDialog = () => {
        Swal.fire({
            title: 'Enter your email',
            input: 'email',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Reset Password',
            showLoaderOnConfirm: true,
            preConfirm: (email) => {
                makeRequest(POST_REQUEST, endpoints.password_reset, {email: email}, () => {
                    Swal.fire(
                        'Success!',
                        'A password reset link has been sent to your email.',
                        'success'
                    ).then(() => {
                        return true;
                    });
                }, (error) => {
                    Swal.showValidationMessage(
                        `Request failed: ${parseErrorResponse(error)}`
                    );
                    return true;
                })
                return false;
            },
            allowOutsideClick: () => !Swal.loading()
        }).then(() => {
            Swal.showValidationMessage(
                `Request failed: `
            );
        });
    }

    render() {
        const {
            error,
            errors,
            loading,
            showPassword
        } = this.state;

        return (
            <div className="signin-up-bg w-100 h-100">
                <div className="leftHalf" style={{backgroundColor: "var(--primary)"}}>
                    <div className="login-promo-txt">
                        <h2>NPBC Admin</h2>
                        <p>Admin Panel</p>
                    </div>
                </div>

                <div className="rightHalf">
                    <div className="position-relative">
                        <div className="login-form">
                            <h2 className="text-center mb-4">
                                <img style={{
                                    width: "30vw",
                                    height: "25vh"
                                }} src={logo} srcSet={logo} alt="CodeLab"/>
                            </h2>
                            <h4 className="text-uppercase- text-purple text-center mb-5">Login to Admin Dashboard</h4>
                            {error &&
                            <div style={{display: 'block'}}
                                 className="alert alert-danger alert-dismissible fade show" role="alert">
                                <strong>Information: </strong> {error}
                                <button type="button" className="close" data-hide="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            }
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group text-left">
                                    <input onChange={(e) => handleChangeData(e, this)} type="email"
                                           className="form-control"
                                           name="email"
                                           placeholder="Enter email"/>
                                    {errors.email && <p className="text-danger small">
                                        {errors.email[0]}
                                    </p>}
                                </div>
                                <div className="form-group mb-4">
                                    <div className="input-group mb-3 input-main-border">
                                        <input onChange={(e) => handleChangeData(e, this)}
                                               type={showPassword ? "text" : "password"} className="form-control"
                                               name="password"
                                               placeholder="Enter Password"/>
                                        <div className="input-group-append input-main-border-icon">
                                            <span onClick={() => {
                                                this.setState({
                                                    showPassword: !showPassword
                                                })
                                            }} style={{cursor: "pointer"}}
                                                  className="input-group-text bg-white material-icons">
                                                {showPassword ? "visibility" : "visibility_off"}
                                            </span>
                                        </div>
                                    </div>
                                    {errors.password && <p className="text-danger small">
                                        {errors.password[0]}
                                    </p>}
                                </div>

                                <div className="form-group clearfix">
                                    {/* <a onClick={(e) => {
                                        e.preventDefault();

                                        //Show popup that will allow the user to input email and send verification request.
                                        this.resetPasswordDialog();
                                    }} href="#forgot" className="float-left forgot-link my-2">Forgot Password ?</a>*/}
                                    <button type="submit" disabled={loading}
                                            className="btn btn-purple btn-pill float-right">{loading ? "LOADING.." : "LOGIN"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;