/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import endpoints from "../../../../routes/endpoints";
import {useHistory, useParams} from "react-router";
import CustomLoader from "../../../../components/loaders/custom_loader";
import {handleChangeDataFn, parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";
import {GET_REQUEST, POST_REQUEST} from "../../../../values/globals";
import {makeRequest} from "../../../../helpers/network_utils";
import Swal from "sweetalert2";
import Loader_fn from "../../../../providers/loader_fn";
import {days, months, weeks} from "../../../../providers/courses/data";

function CourseAdd() {
    const [loading, setLoading] = useState();
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const [types, setTypes] = useState([]);
    const [loadingTypes, setLoadingTypes] = useState();
    const {id} = useParams();
    const history = useHistory();
    useEffect(() => {
        getCourseDetails();
        getTypes();
    }, [id])

    const getTypes = () => {
        new Loader_fn(endpoints.course_types, setLoadingTypes, setTypes, false, false);
    }

    const getCourseDetails = () => {
        if (id) {
            setLoading(true);
            makeRequest(GET_REQUEST, `${endpoints.courses}${id}`, {}, response => {
                let data = {...response.data};
                const allowedFields = [
                    "name", "cover_image", "months", "start_month", "start_week", "start_day", "type"
                ];
                const filteredData = {};
                for (let i = 0; i < allowedFields.length; i++) {
                    const key = allowedFields[i];
                    if (key === 'type')
                        filteredData[key] = data[key]?.id;
                    else
                        filteredData[key] = data[key];
                }
                setData(filteredData);
            }, () => {
                showAlert('error', 'Error', 'Could not load course', () => history.goBack());
            }, () => {
                setLoading(false);
            }).then()
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        let formData = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = data[key];
            if (key === "cover_image") {
                if (typeof data[key] === 'object')
                    formData.append(key, value);
            } else
                formData.append(key, value);
        }

        const method = POST_REQUEST;
        if (id)
            formData.append('_method', 'PATCH');
        const route = id ? `${endpoints.courses}${id}` : endpoints.courses;
        makeRequest(method, route, formData, response => {
            const course = response.data;
            Swal.fire(
                'Success!',
                `Course was ${id ? "edited" : "saved"} successfully!`,
                'success'
            ).then(() => {
                history.push(`/courses/${course?.id}`);
            });
        }, (error) => {
            if (error.response.status === 400) {
                showAlert('error', 'Error', 'Check inputs for errors');
                setErrors(error.response.data)
            } else
                showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false);
        }).then()
    }

    return (
        <div className="container">
            <div className="row mx-auto">
                <div className="col-12 card px-5 py-5">
                    <form>
                        <div className="form-group">
                            <label>Name</label>
                            <input value={data?.name || ""} onChange={(e) => handleChangeDataFn(e, setData)}
                                   type="text"
                                   className="form-control" name="name"/>
                            {errors?.name && <p className="small text-danger">{errors?.name[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label>Cover Image:</label>
                            <input
                                onChange={e => {
                                    setData({
                                            ...data,
                                            cover_image: e.target.files[0]
                                        }
                                    )
                                }}
                                type="file"
                                accept="image/*"
                                className="form-control"/>
                            {errors?.cover_image && (
                                <p className="mb-0 small text-danger">{errors?.cover_image[0]}</p>
                            )}
                            {(data?.cover_image && typeof data?.cover_image === 'string') &&
                            <div className="col-12">
                                <a rel={"noreferrer"} target={"_blank"} href={data?.cover_image}>
                                    Current
                                </a>
                            </div>}
                        </div>
                        <div className="form-group">
                            <label className="m-0">Start Day</label>
                            <p className="small">Specifies the day of week the course begins every year.</p>
                            <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                    name="start_day">
                                {data?.start_day ?
                                    <option value={data?.start_day}>{days[data?.start_day - 1]}</option> :
                                    <option>Select Day</option>}
                                {days.map((day, index) => <option key={index} value={index + 1}>{day}</option>)}
                            </select>
                            {errors?.start_day &&
                            <p className="small text-danger">{errors?.start_day[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label className="m-0">Start Week</label>
                            <p className="small">Specifies the week the course begins every year.</p>
                            <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                    name="start_week">
                                {data?.start_week ?
                                    <option value={data?.start_week}>{weeks[data?.start_week - 1]}</option> :
                                    <option>Select Week</option>}
                                {weeks.map((week, index) =>
                                    <option key={index} value={index + 1}>{week}</option>)}
                            </select>
                            {errors?.start_week &&
                            <p className="small text-danger">{errors?.start_week[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label className="m-0">Start Month</label>
                            <p className="small">Specifies the month the course begins every year.</p>
                            <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                    name="start_month">
                                {data?.start_month ?
                                    <option value={data?.start_month}>{months[data?.start_month - 1]}</option> :
                                    <option>Select Month</option>}
                                {months.map((week, index) => <option key={index} value={index + 1}>{week}</option>)}
                            </select>
                            {errors?.start_month &&
                            <p className="small text-danger">{errors?.start_month[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label className="m-0">Course Type</label>
                            {loadingTypes ? <CustomLoader/> :
                                <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                        name="course_type">
                                    <option>Select Course Type</option>
                                    {types.map(type => <option key={type?.id}
                                                               value={type?.id}>{type?.name}</option>)}
                                </select>}
                            {errors?.course_type &&
                            <p className="small text-danger">{errors?.course_type[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label>Course Duration (in months)</label>
                            <input value={data?.months || ""} onChange={(e) => handleChangeDataFn(e, setData)}
                                   type="number"
                                   className="form-control" name="months"/>
                            {errors?.months && <p className="small text-danger">{errors?.months[0]}</p>}
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => history.goBack()}>Cancel
                        </button>
                        <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                disabled={loading}>{loading ? "Loading..." : "Save"}
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseAdd;