import React, {Component} from "react";
import Totals from "./totals";
import Inventory from "../inventory";

class Dashboard extends Component {
    render() {
        return (
            <div>
                <Totals/>
                {/* <ExpensesStats/>*/}
                <Inventory/>
            </div>
        );
    }
}

export default Dashboard;