/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import Loader_fn from "../../../../../../providers/loader_fn";
import endpoints from "../../../../../../routes/endpoints";
import BranchAdd from "./add";
import CustomLoader from "../../../../../../components/loaders/custom_loader";
import Swal from "sweetalert2";
import {makeRequest} from "../../../../../../helpers/network_utils";
import {DELETE_REQUEST} from "../../../../../../values/globals";
import {parseErrorResponse, showAlert} from "../../../../../../helpers/helper_functions";

const $ = window.$;

function Branches({course}) {
    const [loading, setLoading] = useState();
    const [branches, setBranches] = useState([]);

    useEffect(() => {
        return getBranches();
    }, [])


    const getBranches = () => {
        const loader = new Loader_fn(`${endpoints.branches}?course=${course?.id}`, setLoading, setBranches, false, false);
        return loader.onUnMount();
    }

    const handleAddBranch = e => {
        e.preventDefault();
        $('#addCourseBranchModal').modal('show');
    }

    return (
        <div className="col-12">
            {loading ?
                <div className="text-center">
                    <CustomLoader/>
                </div> :
                <div className="text-center card">
                    <BranchAdd course={course} getEmBranches={getBranches}/>
                    <div className="card-body">
                        <div className="text-center">
                            <button onClick={handleAddBranch} className="btn btn-info btn-sm">
                                Add Center
                            </button>
                        </div>
                        {
                            branches?.map(branch =>
                                <SingleBranch branch={branch} course={course}
                                              callback={getBranches}/>)
                        }
                    </div>
                </div>}
        </div>
    );
}

function SingleBranch({branch, course, callback}) {

    const [loading, setLoading] = useState();

    const handleDelete = () => {
        if (branch)
            Swal.fire({
                title: "Confirm Delete",
                text: `Are you sure you want to de-assign this branch?`,
                icon: "info",
                showConfirmButton: true,
                showCancelButton: true
            }).then(result => {
                if (result.isConfirmed) {
                    setLoading(true);
                    makeRequest(DELETE_REQUEST,
                        `${endpoints.course_branches}?course=${course?.id}&branch=${branch?.id}`,
                        {}, () => {
                            Swal.fire(
                                'Success!',
                                `Center was de-assigned successfully!`,
                                'success'
                            ).then(() => {
                                if (callback)
                                    callback();
                            });
                        }, (error) => {
                            showAlert('error', 'Error', parseErrorResponse(error));
                        }, () => {
                            setLoading(false);
                        }).then()
                }
            });
    }


    return (
        <div className="card my-3">
            <BranchAdd branch={branch} course={course} getBranches={callback}/>
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <h5>{branch?.name}</h5>
                    <button disabled={loading} onClick={handleDelete} className="btn btn-sm btn-danger ml-3">
                        {loading ? "Loading" : "Delete"}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default Branches;