export const DOMAIN = process.env.NODE_ENV !== 'production' ? "/api" :
    "https://npbc-be.npbc.co.ke/api";

export const USER_OBJECT = "user_object";

export const JWT_TOKEN = "jwt_token";

//TODO: Remove this
export const REFRESH_TOKEN = "refresh_token";

export const GET_REQUEST = "get_request";
export const POST_REQUEST = "post_request";
export const PUT_REQUEST = "put_request";
export const PATCH_REQUEST = "patch_request";
export const DELETE_REQUEST = "delete_request";
export const SUBDOMAIN_NAME = "subdomain_name";
export const ORGANIZATION_DATA = "organization_data";
export const primaryColor = 'rgba(238, 49, 55, 1)';
export const secondaryColor = 'rgba(64, 64, 152, 1)';
export const backgroundColor = 'rgba(75,192,192,1)';
export const roles = {
    super_admin: "super_admin",
    finance: "finance_officer",
    coordinator: "branch_coordinator",
    content_creator: "content_creator",
    student: "student",
    lecturer: "lecturer",
    super_finance: "super_finance",
    academic_dean: "academic_dean",
    extension_administrator: "extension_administrator"
}
