import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {DELETE_REQUEST, POST_REQUEST, roles} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import Swal from "sweetalert2";
import CustomSelectPagination from "../../../../components/inputs/custom_select_pagination";
import {getUserObject} from "../../../../helpers/login";
import {parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";

const $ = window.$;

class UserCourseAdd extends Component {

    state = {
        name: '',
        loading: false,
        errors: {},
        data: {}
    }

    handleDelete = () => {
        this.setState({
            loading: true
        })
        const {student} = this.props;
        makeRequest(DELETE_REQUEST, `${endpoints.course_assignment}?course=${student?.semester?.course?.id}&student=${student?.id}`,
            {}, () => {
                Swal.fire(
                    'Success!',
                    `Course deleted successfully!`,
                    'success'
                ).then(() => {
                    this.props.getStudent();
                    $("#addUserCourseModal").modal('toggle');
                    $('body').removeClass('modal-open');
                    $('.modal-backdrop').remove();
                });
            }, (error) => {
                this.setState({
                    errors: error.response.data
                })
            }, () => {
                this.setState({loading: false})
            }).then()
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {student} = this.props;
        const {data} = this.state;
        makeRequest(POST_REQUEST, endpoints.course_assignment, {
            course: data?.course,
            student: student?.id
        }, () => {
            Swal.fire(
                'Success!',
                `Course was added successfully!`,
                'success'
            ).then(() => {
                this.props.getStudent();
                $("#addUserCourseModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            this.setState({loading: false})
        }).then()
    }

    render() {
        const {errors, data, loading} = this.state;
        const {student} = this.props;
        let course = student?.semester?.course;
        const loggedInUser = getUserObject();
        let endpoint = `${endpoints.courses}?branch=${loggedInUser?.branch?.id}`;

        if (loggedInUser.role === roles.super_admin)
            endpoint = endpoints.courses;
        return (
            <div className="modal fade" id="addUserCourseModal" role="dialog"
                 aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Course type</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">

                                <form>
                                    {course && <h5 className="font-weight-normal">
                                        Current: <b>{course?.name}</b>
                                    </h5>}
                                    {course &&
                                    <button onClick={this.handleDelete}
                                            disabled={loading}
                                            className="btn btn-danger btn-sm my-2">{loading ?
                                        "Loading" : "Delete Course"}</button>}
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Course</label>
                                        <CustomSelectPagination placeholder={"Select Course"} onChange={course => {
                                            this.setState({
                                                data: {...data, course: course?.id}
                                            })
                                        }} endpoint={endpoint}/>
                                        {errors?.course && <p className="text-danger small">{errors?.course}</p>} </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default UserCourseAdd;