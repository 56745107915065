import React from "react";

function NotFound() {
    return (
        <div className="error-position">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center ">
                        <img className="img-fluid" src="/assets/img/404.png" srcSet="/assets/img/404@2x.png 2x" alt=""/>
                        <p
                            className="text-muted f18 mt-4 mb-5">Ooops! Something went wrong .Go back to the Earth.</p>
                        <a href="/" className="btn btn-purple btn-pill">Back to Home</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound;