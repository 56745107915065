import {FETCH_INCOME_TYPES, LOADING_INCOME_TYPES} from "./actions";

const branchesState = {
    income_types: null,
};

const reducer = (state = branchesState, action) => {
    if (action.type === FETCH_INCOME_TYPES) {
        return {...state, income_types: action.payload, loadingIncomeTypes: false};
    }
    if (action.type === LOADING_INCOME_TYPES) {
        return {...state, loadingIncomeTypes: true};
    }
    return state;
};

export default reducer;