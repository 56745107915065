import React from "react";
import NavBar from "./navbar";
import {Route, Switch} from "react-router";
import {
    academicDeanRoutes,
    adminRoutes,
    contentCreatorRoutes,
    coordinatorRoutes, extensionAdministratorRoutes,
    financeRoutes,
    routes,
    superFinanceRoutes
} from '../../routes/routes';
import NotFound from "./404";
import SideBar from "./sidebar";
import {getUserObject, isUserLoggedIn, logout} from "../../helpers/login";
import Login from "../../pages/auth/login";
import {roles} from "../../values/globals";

function Main() {
    const user = getUserObject();
    if (user?.role === roles.student || user?.role === roles.lecturer)
        logout();
    return (
        isUserLoggedIn() ? <div>
            <NavBar/>
            <div className="app-body">
                <SideBar/>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Switch>
                            {routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    children={({location, history, match}) =>
                                        <route.component location={location}
                                                         history={history}
                                                         match={match}/>
                                    }
                                />
                            ))}

                            {
                                user.role === roles.super_admin &&
                                adminRoutes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        children={({location, history, match}) =>
                                            <route.component location={location}
                                                             history={history}
                                                             match={match}/>
                                        }
                                    />
                                ))
                            }

                            {
                                user.role === roles.super_finance &&
                                superFinanceRoutes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        children={({location, history, match}) =>
                                            <route.component location={location}
                                                             history={history}
                                                             match={match}/>
                                        }
                                    />
                                ))
                            }

                            {
                                user.role === roles.academic_dean &&
                                academicDeanRoutes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        children={({location, history, match}) =>
                                            <route.component location={location}
                                                             history={history}
                                                             match={match}/>
                                        }
                                    />
                                ))
                            }

                            {
                                user.role === roles.finance &&
                                financeRoutes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        children={({location, history, match}) =>
                                            <route.component location={location}
                                                             history={history}
                                                             match={match}/>
                                        }
                                    />
                                ))
                            }

                            {
                                user.role === roles.coordinator &&
                                coordinatorRoutes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        children={({location, history, match}) =>
                                            <route.component location={location}
                                                             history={history}
                                                             match={match}/>
                                        }
                                    />
                                ))
                            }

                            {
                                user.role === roles.extension_administrator &&
                                extensionAdministratorRoutes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        children={({location, history, match}) =>
                                            <route.component location={location}
                                                             history={history}
                                                             match={match}/>
                                        }
                                    />
                                ))
                            }

                            {
                                user.role === roles.content_creator &&
                                contentCreatorRoutes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        children={({location, history, match}) =>
                                            <route.component location={location}
                                                             history={history}
                                                             match={match}/>
                                        }
                                    />
                                ))
                            }
                            <NotFound/>
                        </Switch>
                    </div>
                </div>
            </div>
        </div> : <Login/>
    )
}

export default Main;