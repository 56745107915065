/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../../../../../helpers/network_utils";
import {GET_REQUEST} from "../../../../../../values/globals";
import endpoints from "../../../../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../../../../helpers/helper_functions";
import CustomLoader from "../../../../../../components/loaders/custom_loader";
import SingleSemester from "./single_semester";
import {useHistory} from "react-router";

function Semesters({course}) {
    const [loading, setLoading] = useState();
    const [semesters, setSemesters] = useState();
    const [year, setYear] = useState(1);
    const history = useHistory();

    useEffect(() => {
        if (course?.id)
            getSemesters();
    }, [year])

    const getYears = () => {
        const months = course?.months;
        let years = [];
        if (months != null) {
            let duration = months / 12;

            for (let i = 0; i < duration; i++)
                years.push(i + 1);
        }
        return years;
    }

    const onChangeYear = year => {
        setYear(year);
    }

    const getSemesters = () => {
        setLoading(true);
        makeRequest(GET_REQUEST, `${endpoints.semesters}?year=${year}&course=${course?.id}`, {}, response => {
            setSemesters(response.data);
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        }).then(() => {
            setLoading(false);
        })
    }

    return (
        <div className="card my-3">
            <div className="card-body">
                <h3>Selected Year: {year}</h3>
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Year</label>
                    <select onChange={e => onChangeYear(e.target.value)} className="form-control">
                        <option>Select Year</option>
                        {getYears().map(year => <option key={year} value={year}>{year}</option>)}
                    </select>
                </div>
                <div className="text-center">
                    <button onClick={() => {
                        history.push(`/semesters/add/${course?.id}`)
                    }} className="btn btn-primary btn-sm">
                        Add Semester
                    </button>
                </div>
                {
                    loading ? <CustomLoader/> :
                        <div className="row">
                            {semesters?.map(semester => <SingleSemester key={semester?.id} semester={semester}/>)}
                        </div>
                }
            </div>
        </div>
    );
}

export default Semesters;