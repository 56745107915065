import React, {Component} from 'react';
import {logout} from "../../../helpers/login";
import CircularProgress from "@material-ui/core/CircularProgress";

class Logout extends Component {

    componentDidMount() {
        logout();
       /* makeRequest(GET_REQUEST, endpoints.logout, {}, () => {
            //window.location = "/";
            logout();
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        })*/
    }

    render() {
        return (
            <div className="text-center my-5 py-5">
                <CircularProgress/>
                <p> Logging out.</p>
            </div>
        );
    }
}

export default Logout;