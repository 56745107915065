import {Link} from "react-router-dom";
import React from "react";
import {toDateStringWordsFromString} from "../../helpers/helper_functions";

export const courseColumns = [
    {
        dataField: 'name',
        text: 'Name',
    },
    {
        dataField: 'months',
        text: 'Duration',
        formatter: duration => `${duration} Months`
    },
    {
        dataField: 'start_date',
        text: 'Begins On',
        formatter: startsAt => `${toDateStringWordsFromString(startsAt || "")}`
    },
    {
        dataField: 'created_at',
        text: 'Uploaded On',
        formatter: date => `${new Date(date).toLocaleDateString()}`
    },
    {
        dataField: 'id',
        text: 'Action',
        formatter: id =>
            <Link to={`/courses/${id}`}
                  className="btn btn-sm btn-success">
                View
            </Link>

    },
];


export const weeks = [
    "1st Week",
    "2nd Week",
    "3rd Week",
    "4th Week",
];

export const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
];

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]