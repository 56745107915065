import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {PATCH_REQUEST, POST_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChangeData} from "../../../../helpers/helper_functions";

const $ = window.$;

class GradeAdd extends Component {

    state = {
        data: {},
        loading: false,
        errors: {}
    }

    componentDidMount() {
        const {grade} = this.props;
        if (grade)
            this.setState({
                data: grade
            });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {grade} = this.props;
        const {data} = this.state;
        const method = grade ? PATCH_REQUEST : POST_REQUEST;
        const endpoint = grade ? `${endpoints.grades}${grade.id}/` : endpoints.grades;
        makeRequest(method, endpoint, data, () => {
            Swal.fire(
                'Success!',
                `Grade was ${grade ? "Edited" : "Saved"} successfully!`,
                'success'
            ).then(() => {
                this.props.getGrades();
                $("#addGradeModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({loading: false})
        }).then()
    }

    render() {
        const {errors, loading, data} = this.state;
        return (
            <div className="modal fade" id="addGradeModal" role="dialog"
                 aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add TreatmentItem</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">From</label>
                                        <input value={data?.from ?? ""} onChange={(e) => handleChangeData(e, this)}
                                               type="number"
                                               className="form-control" name="from"/>
                                        {errors.from && <p className="small text-danger">{errors.from[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">To</label>
                                        <input value={data?.to ?? ""} onChange={(e) => handleChangeData(e, this)}
                                               type="number"
                                               className="form-control" name="to"/>
                                        {errors.to && <p className="small text-danger">{errors.to[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Grade</label>
                                        <input value={data?.grade ?? ""} onChange={(e) => handleChangeData(e, this)}
                                               type="text"
                                               className="form-control" name="grade"/>
                                        {errors.grade && <p className="small text-danger">{errors.grade[0]}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default GradeAdd;