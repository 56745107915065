import {CircularProgress} from "@material-ui/core";

const CustomLoader = () => {
    return (
        <div className="w-100 text-center my-5">
            <CircularProgress/>
        </div>
    )
}

export default CustomLoader;