import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {PATCH_REQUEST, POST_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChange} from "../../../../helpers/helper_functions";

const $ = window.$;

class ExamTypeAdd extends Component {

    state = {
        name: '',
        loading: false,
        errors: {}
    }

    componentDidMount() {
        const {exam_type} = this.props;
        if (exam_type)
            this.setState({
                name: exam_type.name
            });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {exam_type} = this.props;
        const {name} = this.state;
        const method = exam_type ? PATCH_REQUEST : POST_REQUEST;
        const endpoint = exam_type ? `${endpoints.exam_types}${exam_type.id}/` : endpoints.exam_types;
        makeRequest(method, endpoint, {name: name}, () => {
            Swal.fire(
                'Success!',
                `Exam type was ${exam_type ? "Edited" : "Saved"} successfully!`,
                'success'
            ).then(() => {
                this.props.getExamTypes();
                $("#addExamTypeModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({loading: false})
        }).then()
    }

    render() {
        const {errors, loading, name} = this.state;
        return (
            <div className="modal fade" id="addExamTypeModal" role="dialog"
                 aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Exam type</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Exam type</label>
                                        <input value={name || ""} onChange={(e) => handleChange(e, this)} type="text"
                                               className="form-control" name="name"/>
                                        {errors.name && <p className="small text-danger">{errors.name[0]}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ExamTypeAdd;