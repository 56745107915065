/* eslint-disable react-hooks/exhaustive-deps */
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../components/Table";
import React, {useEffect, useState} from "react";
import {studentColumns, studentColumnsFee} from "../../../providers/users/data";
import Loader_fn from "../../../providers/loader_fn";
import endpoints from "../../../routes/endpoints";
import {roles} from "../../../values/globals";
import SearchFn from "../../../providers/search/loader_fn";
import {onChange, parseUrls} from "../../../helpers/helper_functions";
import {options} from "../../expenses";
import {getUserObject} from "../../../helpers/login";
import {useDispatch, useSelector} from "react-redux";
import CustomSelectPagination from "../../../components/inputs/custom_select_pagination";
import {getBranches} from "../../../redux/branch/actions";
import PrintService from "../../print";

let search;
const $ = window.$;

function Students({isInFee}) {
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [students, setStudents] = useState([]);
    const loggedInUser = getUserObject();
    const {branches} = useSelector(state => state.branchesState);
    const [fields, setFields] = useState({});
    const [courseTypes, setCourseTypes] = useState([]);
    const [loadingCourseTypes, setLoadingCourseTypes] = useState();
    const [currentEndpoint, setCurrentEndpoint] = useState();
    const [printProps, setPrintProps] = useState({});
    const [currentCourseType, setCurrentCourseType] = useState();
    const [currentCourse, setCurrentCourse] = useState();
    const [currentBranch, setCurrentBranch] = useState();
    const dispatch = useDispatch();

    const handlePrintReport = () => {
        let endpoint = `${currentEndpoint}`;
        if (endpoint?.includes("?"))
            endpoint = `${endpoint}&dont_paginate=true`;
        else
            endpoint = `${endpoint}?dont_paginate=true`;
        const printProps = {
            endpoint: endpoint,
            columns: isInFee ? studentColumnsFee : studentColumns,
            title: `Fee Statements`,
            subtitle:
                <div className="d-flex flex-column">
                    {currentCourseType &&
                    <h5>
                        Type: {currentCourseType?.name}
                    </h5>}
                    {currentCourse &&
                    <h5>
                        Course: {currentCourse?.name}
                    </h5>}
                    {currentBranch &&
                    <h5>
                        Center: {currentBranch?.name}
                    </h5>}
                </div>
        };
        setPrintProps(printProps);
        $('#printServiceModal').modal('show');
    }

    /* const getCourseTypes = () => {
         setLoadingCourseTypes(true);
         makeRequest(GET_REQUEST, `${endpoints.course_types}`, {}, response => {
             setCourseTypes(response.data);
         }, (error) => {
             showAlert('error', 'Error', parseErrorResponse(error));
         }, () => {
             setLoadingCourseTypes(false);
         }).then()
     }*/

    const getEmBranches = () => {
        if (loggedInUser?.role === roles.super_admin || loggedInUser?.role === roles.super_finance
            || loggedInUser?.role === roles.extension_administrator)
            if (branches == null)
                dispatch(getBranches());
    }

    useEffect(() => {
        getUsers();
        getCourseTypes();
        getEmBranches();
    }, [])

    //Handle automatic fetching when field urls change.
    useEffect(() => {
        parseUrls([`${endpoints.users}?role=${roles.student}`],
            [
                (urls) => getUsers(urls[0])
            ], fields)
    }, [fields])

    const getUsers = (endpoint = `${endpoints.users}?role=${roles.student}`) => {
        setCurrentEndpoint(endpoint);
        const loaderFn = new Loader_fn(endpoint, setLoading, setStudents);
        return loaderFn.onUnMount();
    }

    const getCourseTypes = (endpoint = `${endpoints.course_types}`) => {
        const loaderFn = new Loader_fn(endpoint, setLoadingCourseTypes, setCourseTypes, false, false);
        return loaderFn.onUnMount();
    }

    const searchAction = (value) => {
        if (value?.length < 1) {
            getUsers();
            return;
        }

        if (!search)
            search = new SearchFn(`${endpoints.users}`, value, setLoadingSearch, setStudents);
        search.search(value);
    }

    return (

        <div className="card">
            <PrintService {...printProps}/>
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Filter By Course</label>
                    <CustomSelectPagination
                        endpoint={endpoints.courses}
                        placeholder={"All Courses"}
                        includeDefaultItem={true}
                        onChange={course => {
                            onChange("course", course?.id ?? "", options.replace, fields, setFields);
                            setCurrentCourse(course);
                        }}/>
                </div>
                {/*<div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Filter By Fee Paid</label>
                    <select onChange={e => {
                        onChange("fee_paid", e.target.value, options.replace, fields, setFields);
                    }} className="form-control">
                        <option>Select Fee Paid</option>
                        <option value={1}>True</option>
                        <option value={0}>False</option>
                    </select>
                </div>*/}
                {(loggedInUser.role === roles.super_admin
                    || loggedInUser?.role === roles.extension_administrator
                    || loggedInUser.role === roles.super_finance) &&
                loadingCourseTypes ?
                    <div className="text-center">
                        <CircularProgress/>
                    </div> : <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Filter By Course Type</label>
                        <select onChange={e => {
                            let courseType = JSON.parse(e.target.value);
                            onChange("type", courseType?.id ?? "", options.replace, fields, setFields);
                            setCurrentCourseType(courseType);
                        }} className="form-control">
                            <option value>All Course Types</option>
                            {courseTypes?.map(course_type =>
                                <option key={course_type?.id}
                                        value={JSON.stringify(course_type)}>{course_type?.name}</option>
                            )}
                        </select>
                    </div>}
                {(loggedInUser.role === roles.super_admin ||
                    loggedInUser.role === roles.extension_administrator ||
                    loggedInUser.role === roles.super_finance ||
                    loggedInUser.role === roles.academic_dean) &&
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Filter Center</label>
                    <select onChange={e => {
                        const branch = JSON.parse(e.target.value);
                        onChange("branch", branch?.id ?? "", options.replace, fields, setFields);
                        setCurrentBranch(branch);
                    }} className="form-control">
                        <option value>All Centers</option>
                        {branches?.map(branch =>
                            <option key={branch?.id} value={JSON.stringify(branch)}>{branch?.name}</option>
                        )}
                    </select>
                </div>}
                {isInFee &&
                <div className="text-center">
                    <button onClick={() => handlePrintReport()} className="btn btn-primary btn-sm">
                        Print
                    </button>
                </div>}
                {loading ?
                    <div className="text-center">
                        <CircularProgress/>
                    </div> :
                    <Table
                        caption={"All Students"}
                        hideAction={true}
                        columns={isInFee ? studentColumnsFee : studentColumns}
                        data={students}
                        loadingSearch={loadingSearch}
                        searchAction={isInFee ? null : searchAction}
                    />}
            </div>
        </div>
    );
}

export default Students;