import {makeRequest} from "../helpers/network_utils";
import {GET_REQUEST} from "../values/globals";
import {getUrl, parseErrorResponse, showAlert, throttle} from "../helpers/helper_functions";

const $ = window.$;

export const onMount = (self, initUrl, key = "books") => {
    self.setState({
        loading: true
    })
    getData(initUrl, self, key);
    detectScroll(self, key);
}

const getData = (url, self, key) => {

    makeRequest(GET_REQUEST, url, {}, response => {
        const res = response.data;
        let myObj = {};
        myObj[key] = res;
        self.setState(myObj);
    }, error => {
        showAlert('error', 'Error', parseErrorResponse(error))
    }, () => {
        self.setState({
            loading: false,
            loadingNext: false,
        })
    })
}

const detectScroll = (self, key) => {
    $(window).scroll(() => {
        if ($(window).scrollTop() >= $(document).height() - $(window).height() - 300) {
            loadMoreContent(self, key);
        }
    });
}

const loadMoreContent = throttle((self, key) => {
    const {next, loadingNext} = self.state;
    if (next && !loadingNext) {
        self.setState({
            loadingNext: true
        })
        getData(getUrl(next), self, key);
    }
}, 1000);