import React, {useState} from 'react';
import CustomSelectPagination from "../../../../components/inputs/custom_select_pagination";
import {makeRequest} from "../../../../helpers/network_utils";
import {POST_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import Swal from "sweetalert2";
import {parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";

const $ = window.$;

function AddLecturerUnit({lecturer, getUnits}) {
    const [loading, setLoading] = useState();
    const [data, setData] = useState();
    //const [course, setCourse] = useState();

    const handleSubmit = () => {
        setLoading(true);

        makeRequest(POST_REQUEST, endpoints.unit_assignment, {
            unit: data?.unit,
            lecturer: lecturer?.id
        }, () => {
            Swal.fire(
                'Success!',
                `Unit was added successfully!`,
                'success'
            ).then(() => {
                getUnits();
                $("#addLecUnitModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false)
        }).then()
    }

    /* let endpoint = `${endpoints.courses}?branch=${loggedInUser?.branch?.id}`;

     if (loggedInUser.role === roles.super_admin)
         endpoint = endpoints.courses;*/

    return (
        <div className="modal fade" id="addLecUnitModal" role="dialog"
             aria-labelledby="exampleModalCenterTitle"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Assign Unit</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Units</label>
                                    <CustomSelectPagination placeholder={"Select Unit"} onChange={unit => {
                                        setData({...data, unit: unit?.id})
                                    }} endpoint={`${endpoints.units}`}/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                disabled={loading}>{loading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddLecturerUnit;