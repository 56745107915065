import React from "react";
import {numberWithCommas} from "../../helpers/helper_functions";

export const statementsColumns = [
    {
        dataField: 'id',
        text: '#',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'student',
        text: 'Reg No',
        formatter: user => user?.reg_no
    },
    {
        dataField: 'nada',
        text: 'Student Name',
        formatter: (user, row) => row?.student?.name || "-"
    },
    {
        dataField: 'approved_by',
        text: 'Approved By',
        formatter: (user) => `${user?.name} - ${user?.phone || ""}`
    },
    {
        dataField: 'amount',
        text: 'Paid',
        formatter: amount => `KES ${numberWithCommas(amount || "-")}`
    },
    {
        dataField: 'created_at',
        text: 'Date',
        formatter: date => `${new Date(date).toLocaleDateString()}`
    },
];

export const transactionColumnsPrint = [
    {
        dataField: 'id',
        text: '#',
    },
    {
        dataField: 'created_at',
        text: 'Date',
        formatter: (created) => `${new Date(created).toLocaleString()}`

    },
    {
        dataField: 'amount',
        text: 'Amount',
        formatter: amount => `KES ${numberWithCommas(amount || "-")}`
    },
];

export const transactionColumnsNoUser = [
    {
        dataField: 'id',
        text: '#',
    },
    {
        dataField: 'created_at',
        text: 'Date',
        formatter: (created) => `${new Date(created).toLocaleString()}`

    },
    {
        dataField: 'amount',
        text: 'Amount',
        formatter: amount => `KES ${numberWithCommas(amount || "-")}`
    },
    {
        dataField: 'approved_by',
        text: 'Approved By',
        formatter: (user) => <div>
            <p>{user.name}</p>
            <p>{user.email}</p>
        </div>
    },
];