import {Link} from "react-router-dom";
import {numberWithCommas} from "../../helpers/helper_functions";

export const userColumns = [
    {
        dataField: 'name',
        text: 'Name',
        //formatter: (cell, row) => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'email',
        text: 'Email',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    /*{
        dataField: 'id_no',
        text: 'ID No',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },*/
    {
        dataField: 'reg_no',
        text: 'Reg No',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'role',
        text: 'Role',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    /*{
        dataField: 'created',
        text: 'Date Added',
        formatter: created => `${replaceHyphensString(new Date(created).toLocaleString())}`
    },*/
    {
        dataField: 'action',
        text: 'Action',
        formatter: (data, row) => <Link to={`/users/${row?.id}`} className="btn btn-sm btn-success">View</Link>
    },
];

export const studentColumns = [
    {
        dataField: 'name',
        text: 'Name',
        //formatter: (cell, row) => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'email',
        text: 'Email',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'id_no',
        text: 'ID No',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'reg_no',
        text: 'Reg No',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'role',
        text: 'Role',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    /*{
        dataField: 'created',
        text: 'Date Added',
        formatter: created => `${replaceHyphensString(new Date(created).toLocaleString())}`
    },*/
    {
        dataField: 'action',
        text: 'Action',
        formatter: (data, row) => <Link to={`/students/${row?.id}`} className="btn btn-sm btn-success">View</Link>
    },
]

export const studentColumnsFee = [
    {
        dataField: 'id_no',
        text: 'ID No',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'name',
        text: 'Name',
        //formatter: (cell, row) => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'reg_no',
        text: 'Reg No',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'total_fee',
        text: 'Total Fee',
        formatter: (fee, row) => `KES ${numberWithCommas(row?.semester?.fee_config?.amount || '-')}`
    },
    {
        dataField: 'fee_paid',
        text: 'Fee Paid',
        formatter: (fee) => `KES ${numberWithCommas(fee)}`
    },
    {
        dataField: 'balance',
        text: 'Balance',
        formatter: (fee, row) => `KES ${numberWithCommas(
            (row?.semester?.fee_config?.amount - row?.fee_paid) ?? "-")}`
    },
    /*{
        dataField: 'created',
        text: 'Date Added',
        formatter: created => `${replaceHyphensString(new Date(created).toLocaleString())}`
    },*/
]

export const lecturerColumns = [
    {
        dataField: 'name',
        text: 'Name',
        //formatter: (cell, row) => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'email',
        text: 'Email',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'id_no',
        text: 'ID No',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'reg_no',
        text: 'Reg No',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    /*{
        dataField: 'created',
        text: 'Date Added',
        formatter: created => `${replaceHyphensString(new Date(created).toLocaleString())}`
    },*/
    {
        dataField: 'action',
        text: 'Action',
        formatter: (data, row) => <Link to={`/lecturers/${row?.id}`} className="btn btn-sm btn-success">View</Link>
    },
]