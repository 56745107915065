import React, {useState} from 'react';
import FeeStatements from "./all_statements";
import Students from "../../admin/students";

const pages = {
    all_statements: "all_statements",
    consolidated_statements: "consolidated_statements",
}

function MainFeeStatements() {
    const [currentPath, setCurrentPath] = useState(pages.all_statements);
    return (
        <div className="row mx-auto">
            <div className="col-12">
                <div className="profile-nav-links mb-3">
                    <ul className="nav f12">
                        <li className="nav-item">
                            <a href={"/"} onClick={e => {
                                e.preventDefault();
                                setCurrentPath(pages.all_statements);
                            }}
                               className={`nav-link ${currentPath === pages.all_statements && 'active'}`}>
                                All Statements</a>
                        </li>
                        <li className="nav-item">
                            <a href={"/"} onClick={e => {
                                e.preventDefault();
                                setCurrentPath(pages.consolidated_statements);
                            }}
                               className={`nav-link ${currentPath === pages.consolidated_statements && 'active'}`}>
                                Consolidated Statements</a>
                        </li>
                    </ul>
                </div>
                {currentPath === pages.all_statements && <FeeStatements/>}
                {currentPath === pages.consolidated_statements && <Students isInFee={true}/>}
            </div>
        </div>

    );
}

export default MainFeeStatements;