import React from "react";
import {Link} from "react-router-dom";
import {getUserObject} from "../../helpers/login";
import {roles} from "../../values/globals";

function SideBar() {
    const user = getUserObject();
    return (
        <div className="left-nav-wrap">
            <div className="left-sidebar">
                <nav className="sidebar-menu">
                    <ul id="nav-accordion">

                        {user.role === roles.super_admin && adminLinks}
                        {user.role === roles.coordinator && coordinatorLinks}
                        {user.role === roles.finance && financeLinks}
                        {user.role === roles.content_creator && contentCreatorLinks}
                        {user.role === roles.super_finance && superFinanceLinks}
                        {user.role === roles.academic_dean && academicDeanLinks}
                        {user.role === roles.extension_administrator && extensionAdministratorLinks}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

const commonLinks = [
    <li key={55}>
        <Link to="/">
            <i className="ti-home"/>
            <span>Dashboard</span>
        </Link>
    </li>,
    <li key={2}>
        <Link to="/inventory">
            <i className="icon-grid"/>
            <span>Inventory</span>
        </Link>
    </li>,
    <li key={3}>
        <Link to="/expenses">
            <i className="icon-wallet"/>
            <span>Expenses</span>
        </Link>
    </li>,
    <li key={4}>
        <Link to="/semester-timetable">
            <i className="icon-docs"/>
            <span>Semester Timetable</span>
        </Link>
    </li>,
    <li key={5}>
        <Link to="/exam-timetable">
            <i className="icon-doc"/>
            <span>Exam Timetable</span>
        </Link>
    </li>,
    <li key={8}>
        <Link to="/students">
            <i className="icon-graduation"/>
            <span>Students</span>
        </Link>
    </li>,
    <li key={18}>
        <Link to="/lecturers">
            <i className="icon-graduation"/>
            <span>Lecturers</span>
        </Link>
    </li>,
    <li key={1}>
        <Link to="/users">
            <i className="icon-people"/>
            <span>User Management</span>
        </Link>
    </li>,
]

const contentCreatorLinks = [
    <li key={7}>
        <Link to="/news">
            <i className="icon-bell"/>
            <span>News</span>
        </Link>
    </li>,
];


const financeLinks = [
    <li key={9}>
        <Link to="/fee">
            <i className="icon-wallet"/>
            <span>Fee</span>
        </Link>
    </li>,
    <li key={66}>
        <Link to="/students">
            <i className="icon-graduation"/>
            <span>Students</span>
        </Link>
    </li>,
];

const adminLinks = [
    ...commonLinks,
    <li key={33}>
        <Link to="/income">
            <i className="icon-wallet"/>
            <span>Income</span>
        </Link>
    </li>,
    <li key={9}>
        <Link to="/fee">
            <i className="icon-wallet"/>
            <span>Fee</span>
        </Link>
    </li>,
    <li key={6}>
        <Link to="/enquiries">
            <i className="icon-info"/>
            <span>Enquiries</span>
        </Link>
    </li>,
    ...contentCreatorLinks,
    <li key={10}>
        <Link to="/settings">
            <i className="icon-settings"/>
            <span>Settings</span>
        </Link>
    </li>,
];

const coordinatorLinks = [
    <li key={55}>
        <Link to="/">
            <i className="ti-home"/>
            <span>Dashboard</span>
        </Link>
    </li>,
    <li key={2}>
        <Link to="/inventory">
            <i className="icon-grid"/>
            <span>Inventory</span>
        </Link>
    </li>,
    <li key={3}>
        <Link to="/expenses">
            <i className="icon-wallet"/>
            <span>Expenses</span>
        </Link>
    </li>,
    <li key={8}>
        <Link to="/students">
            <i className="icon-graduation"/>
            <span>Students</span>
        </Link>
    </li>,
    <li key={18}>
        <Link to="/lecturers">
            <i className="icon-graduation"/>
            <span>Lecturers</span>
        </Link>
    </li>,
    <li key={1}>
        <Link to="/users">
            <i className="icon-people"/>
            <span>User Management</span>
        </Link>
    </li>,
];

const extensionAdministratorLinks = [
    ...commonLinks,
    <li key={66}>
        <Link to="/students">
            <i className="icon-graduation"/>
            <span>Students</span>
        </Link>
    </li>,
    <li key={10}>
        <Link to="/settings">
            <i className="icon-settings"/>
            <span>Settings</span>
        </Link>
    </li>,
];

const academicDeanLinks = [
    <li key={55}>
        <Link to="/">
            <i className="ti-home"/>
            <span>Dashboard</span>
        </Link>
    </li>,
    <li key={66}>
        <Link to="/students">
            <i className="icon-graduation"/>
            <span>Students</span>
        </Link>
    </li>,
    <li key={4}>
        <Link to="/semester-timetable">
            <i className="icon-docs"/>
            <span>Semester Timetable</span>
        </Link>
    </li>,
    <li key={5}>
        <Link to="/exam-timetable">
            <i className="icon-doc"/>
            <span>Exam Timetable</span>
        </Link>
    </li>,
    <li key={18}>
        <Link to="/lecturers">
            <i className="icon-graduation"/>
            <span>Lecturers</span>
        </Link>
    </li>,
    <li key={10}>
        <Link to="/settings">
            <i className="icon-settings"/>
            <span>Settings</span>
        </Link>
    </li>,
];

const superFinanceLinks = [
    <li key={55}>
        <Link to="/">
            <i className="ti-home"/>
            <span>Dashboard</span>
        </Link>
    </li>,
    <li key={2}>
        <Link to="/inventory">
            <i className="icon-grid"/>
            <span>Inventory</span>
        </Link>
    </li>,
    <li key={33}>
        <Link to="/income">
            <i className="icon-wallet"/>
            <span>Income</span>
        </Link>
    </li>,
    <li key={3}>
        <Link to="/expenses">
            <i className="icon-wallet"/>
            <span>Expenses</span>
        </Link>
    </li>,
    <li key={34}>
        <Link to="/income">
            <i className="icon-wallet"/>
            <span>Income</span>
        </Link>
    </li>,
    ...financeLinks
]


export default SideBar;