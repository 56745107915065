import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_BRANCHES = "fetch_churches";
export const LOADING_BRANCHES = "loading_churches";

export const getBranches = () => {
    return dispatch => {
        dispatch({
            type: LOADING_BRANCHES
        })
        makeRequest(GET_REQUEST, `${endpoints.branches}`, {}, response => {
            const payload = response.data;
            return dispatch({
                payload: payload,
                type: FETCH_BRANCHES
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            return dispatch({
                payload: [],
                type: FETCH_BRANCHES
            })
        }).then();
    }
}