import React, {useState} from 'react';
import CustomSelectPagination from "../../../components/inputs/custom_select_pagination";
import endpoints from "../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import TimePicker from 'react-time-picker';

const $ = window.$;
const AddUnit = ({semester, callback, selectedDate, selectedDay}) => {

    const [data, setData] = useState({});
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState();

    const handleSubmit = () => {
        setLoading(true);
        setErrors({});
        if (selectedDate) {
            data.date = selectedDate.getDate();
            data.month = selectedDate.getMonth() + 1;
            data.exam = semester?.exam?.id;
            delete data.day;
        } else {
            data.day = selectedDay;
            data.semester = semester?.id;
        }

        makeRequest(POST_REQUEST, endpoints?.timetable, data, () => {
            showAlert('success', 'Success', 'Unit added to timetable successfully!');
            $("#addUnitModal").modal('toggle');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            if (callback)
                callback();
        }, error => {
            if (error.response.status === 400)
                setErrors(error.response.data)
            else
                showAlert('error', 'Error', parseErrorResponse(error))
        }).then(() => {
            setLoading(false);
        })

    }

    return (
        <div className="modal fade" id="addUnitModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Unit</h5>
                        <button onClick={e => {
                            e.preventDefault();
                            $("#addUnitModal").modal('toggle');
                        }} type="button" className="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Unit</label>
                                    <CustomSelectPagination
                                        endpoint={semester?.id ? `${endpoints.units}?semester=${semester?.id}` : ""}
                                        onChange={unit => {
                                            setData({...data, unit: unit?.id})
                                        }}/>
                                    {errors?.unit && <p className="text-danger small">{errors?.unit[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Starts at</label>
                                    <TimePicker
                                        className="w-100"
                                        onChange={time => {
                                            setData({...data, start_time: time})
                                        }}
                                    />
                                    {errors?.start_time && <p className="text-danger small">{errors?.start_time[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Ends at</label>
                                    <TimePicker
                                        className="w-100"
                                        onChange={time => {
                                            setData({...data, end_time: time})
                                        }}
                                    />
                                    {errors?.end_time && <p className="text-danger small">{errors?.end_time[0]}</p>}
                                </div>
                                {errors?.semester && <p className="text-danger small">{errors?.semester[0]}</p>}
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={e => {
                            e.preventDefault();
                            $("#addUnitModal").modal('toggle');
                        }} type="button" className="btn btn-secondary">Close
                        </button>
                        <button onClick={handleSubmit} disabled={loading}
                                type="button" className="btn btn-primary">
                            {loading ? "Loading" : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUnit;