import React, {useEffect, useState} from 'react';
import {transactionColumnsNoUser, transactionColumnsPrint} from "../../../../providers/fee_statements/data";
import Table from "../../../../components/Table";
import AddFeeStatement from "../fee/add";
import Loader_fn from "../../../../providers/loader_fn";
import endpoints from "../../../../routes/endpoints";
import {numberWithCommas} from "../../../../helpers/helper_functions";
import CustomLoader from "../../../../components/loaders/custom_loader";
import YearSemesterSelect from "../../../../components/inputs/year_semester_select";
import PrintService from "../../../print";

const $ = window.$;
const FeeStatements = ({student}) => {
        const [loading, setLoading] = useState();
        const [loadingFee, setLoadingFee] = useState();
        const [statements, setStatements] = useState([]);
        const [fee, setFee] = useState();
        const [semester, setSemester] = useState(student?.semester);
        const [year, setYear] = useState(student?.year);
        const [currentEndpoint, setCurrentEndpoint] = useState();
        const [printProps, setPrintProps] = useState({});


        //get statements
        useEffect(() => {
            return init(student, semester);
        }, [student, semester]);


        const init = (student, semester) => {
            let loaderFnLoadMore;
            if (student?.id && semester?.id) {
                const endpoint = `${endpoints.fee_statements}?semester=${semester?.id}&student=${student?.id}`;
                setCurrentEndpoint(endpoint);
                loaderFnLoadMore = new Loader_fn(endpoint, setLoading, setStatements);
            }
            if (student?.id && semester?.id) {
                new Loader_fn(`${endpoints.fee}?semester=${semester?.id}&student=${student?.id}`, setLoadingFee, setFee, false, false);
            }
            return loaderFnLoadMore?.onUnMount();
        }

        //reload
        const refresh = () => {
            setSemester(student?.semester);
            init(student, student?.semester);
        }

        const handleAddFeeStatement = e => {
            e.preventDefault();
            $('#addFeeStatementModal').modal('show');
        }

        const handlePrintReport = () => {
            if (currentEndpoint) {
                let endpoint = `${currentEndpoint}`;
                if (endpoint?.includes("?"))
                    endpoint = `${endpoint}&dont_paginate=true`;
                else
                    endpoint = `${endpoint}?dont_paginate=true`;
                const printProps = {
                    endpoint: endpoint,
                    columns: transactionColumnsPrint,
                    title: `Fee Statements for Year ${semester?.year}, Semester ${semester?.number}`,
                    subtitle:
                        <h5>
                            Student: {student?.name} <br/>
                            Reg No: {student?.reg_no} <br/>
                            Total : <b>{numberWithCommas(fee?.total ?? "-")}</b> <br/>
                            Paid : <b>{numberWithCommas(fee?.paid ?? "-")}</b> <br/>
                            Balance : <b>{numberWithCommas(fee?.unpaid ?? "-")}</b> <br/>
                        </h5>
                };
                setPrintProps(printProps);
                $('#printServiceModal').modal('show');
            }
        }


        return (
            <div>
                <AddFeeStatement student={student} callback={refresh}/>
                <PrintService {...printProps}/>
                <div className="w-100">
                    {
                        loadingFee ? <CustomLoader/> : <div>
                            <h4 className="mb-3">Student Fee Transactions</h4>
                            <h3 className="text-info">Total: <b>KES {numberWithCommas(fee?.total ?? '-')}</b></h3>
                            <h3 className="text-success">Paid: <b>KES {numberWithCommas(fee?.paid ?? '-')}</b></h3>
                            <h3 className="text-danger">Balance: <b>KES {numberWithCommas(fee?.unpaid ?? '-')}</b></h3>
                        </div>}
                    <hr className="my-3"/>
                    <div className="w-100">
                        <YearSemesterSelect user={student} onYearChange={(year) => setYear(year)}
                                            onSemesterChange={(semester) => setSemester(semester)}/>
                    </div>

                </div>
                <button onClick={handlePrintReport} className="btn btn-primary btn-sm">
                    Print
                </button>
                {loading ? <CustomLoader/> :
                    <Table
                        caption={<h3>Fee Statements: <br/> Year {year}, Semester {semester?.number}</h3>}
                        actionText={"Add Fee Statement"}
                        actionFunc={handleAddFeeStatement}
                        columns={transactionColumnsNoUser}
                        data={statements}
                    />}
            </div>
        );
    }
;

export default FeeStatements;