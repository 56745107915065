/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import AddUnit from "../exam_timetable/add";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, roles} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {getUserObject} from "../../../helpers/login";
import {parseDate, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import CustomLoader from "../../../components/loaders/custom_loader";
import CustomSelectPagination from "../../../components/inputs/custom_select_pagination";
import ExamCalendar from "./calendar/examCalendar";
import {SingleUnit} from "../semester_timetable";
import {useDispatch, useSelector} from "react-redux";
import {getBranches} from "../../../redux/branch/actions";

const $ = window.$;
const SemesterTimetable = () => {

    const dispatch = useDispatch();

    const [loadingSemesters, setLoadingSemesters] = useState();
    const [loadingUnits, setLoadingUnits] = useState();
    const [semesters, setSemesters] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedSemester, setSelectedSemester] = useState(null);
    const [units, setUnits] = useState();
    const [selectedDate, setSelectedDay] = useState();
    const [selectedBranch, setSelectedBranch] = useState();
    const {branches} = useSelector(state => state.branchesState);

    const user = getUserObject();

    const getEmBranches = () => {
        if (user?.role === roles.super_admin || user?.role === roles.super_finance
            || user?.role === roles.extension_administrator)
            if (branches == null)
                dispatch(getBranches());
    }

    useEffect(() => {
        getEmBranches();
    }, [])

    const getSemesters = (course, year) => {
        setLoadingSemesters(true);
        makeRequest(GET_REQUEST, `${endpoints.semesters}?course=${course?.id}&year=${year}`, {}, response => {
            setSemesters(response.data);
        }, error => showAlert('error', 'Error', parseErrorResponse(error)),).then(() => {
            setLoadingSemesters(false);
        })
    }


    const handleAddUnit = e => {
        e.preventDefault();
        $('#addUnitModal').modal('show');
    }
    let years = [];
    if (selectedCourse?.months) {
        let totalYears = Math.ceil(selectedCourse?.months / 12);

        //populate years
        for (let i = 0; i < totalYears; i++)
            years.push(i + 1);
    }

    const getSelectedDayUnits = (day) => {
        setLoadingUnits(true);
        makeRequest(GET_REQUEST, `${endpoints.timetable}?exam=${selectedSemester?.exam?.id}&date=${day?.getDate()}&month=${day?.getMonth() + 1}`,
            {}, response => {
                setUnits(response.data);
            }).then(() => {
            setLoadingUnits(false)
        })
    }

    const onDateSelect = day => {
        setSelectedDay(day);
        getSelectedDayUnits(day);
    }


    const onYearSelect = (year, index) => {
        setSelectedDay(null);
        getSemesters(selectedCourse, year);
    }

    const resetYear = () => {
        document.getElementById('year_select').selectedIndex = 0;
    }

    const resetSemester = () => {
        const semesterSelect = document.getElementById('semester_select');
        if (semesterSelect)
            semesterSelect.selectedIndex = 0;
    }
    const onSemesterSelect = semester => {
        setSelectedDay(null);
        setSelectedSemester(semester);
    }

    const onBranchSelect = branch => {
        setSelectedBranch(branch)
    }

    return (
        <div className="col-12">
            <h2 className="text-center mb-3">Exam Timetable</h2>
            <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Select Center</label>
                <select onChange={e => {
                    try {
                        onBranchSelect(JSON.parse(e.target.value))
                    } catch (e) {

                    }
                }} className="form-control">
                    <option>Select Center</option>
                    {branches?.map(branch =>
                        <option key={branch?.id}
                                value={JSON.stringify(branch)}>{branch?.name}</option>)}
                </select>
            </div>

            <p className="mb-1">Select Course</p>
            <CustomSelectPagination onChange={course => {
                setSelectedCourse(course);
                setSelectedDay(null);
                setSelectedSemester(null);
                resetYear();
                resetSemester();
            }}
                                    endpoint={`${endpoints.courses}?branch=${selectedBranch?.id}`}/>
            <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Select Year</label>
                <select id={"year_select"} onChange={e => onYearSelect(e.target.value)} className="form-control">
                    <option>Select Year</option>
                    {years?.map(year => <option key={year} value={year}>{year}</option>)}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Select Semester</label>
                {loadingSemesters ? <CustomLoader/> :
                    <select id={"semester_select"} onChange={e => {
                        if (e.target.value)
                            onSemesterSelect(JSON.parse(e.target.value));
                    }} className="form-control">
                        <option>Select Semester</option>
                        {semesters?.map(semester =>
                            <option key={semester?.id} value={JSON.stringify(semester)}>{semester?.number}</option>)}
                    </select>}
            </div>
            <div className="row mx-auto">
                <AddUnit semester={selectedSemester}
                         callback={() => getSelectedDayUnits(selectedDate)} selectedDate={selectedDate}/>
                <div className="col-md-8">
                    {selectedSemester &&
                    <ExamCalendar startDate={parseDate(selectedSemester?.exam?.start_date_with_year)}
                                  endDate={parseDate(selectedSemester?.exam?.end_date_with_year)}
                                  onClickDate={onDateSelect} selectedDate={selectedDate}/>}
                </div>
                <div className="col-md-4">
                    <div className="card">
                        {loadingUnits ? <CustomLoader/> :
                            <div className="card-body">
                                <h3>Units</h3>
                                <button onClick={handleAddUnit} className="btn btn-sm btn-info">
                                    Add Unit
                                </button>
                                <hr className="my-3"/>
                                {
                                    !units ? <p>Select a day to view units</p> :
                                        units?.length < 1 ?
                                            <p>Click on add unit to add unit</p> :
                                            units?.map(unit =>
                                                <SingleUnit
                                                    callback={() => getSelectedDayUnits(selectedDate)} key={unit?.id}
                                                    unit={unit}/>
                                            )
                                }
                            </div>}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SemesterTimetable;