/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router";
import {makeRequest} from "../../../../../../helpers/network_utils";
import {POST_REQUEST} from "../../../../../../values/globals";
import endpoints from "../../../../../../routes/endpoints";
import {handleChangeDataFn, parseErrorResponse, showAlert} from "../../../../../../helpers/helper_functions";
import Swal from "sweetalert2";

function FeeConfig({semester}) {
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({});
    const [feeConfig, setFeeConfig] = useState();
    const [loading, setLoading] = useState();

    const getFeeDetails = () => {
        if (semester?.fee_config) {
            setFeeConfig(semester?.fee_config);
            setData(semester?.fee_config);
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        setErrors({})
        let formData = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = data[key];
            formData.append(key, value);
        }
        formData.append("semester", semester?.id);
        const method = POST_REQUEST;
        if (feeConfig)
            formData.append('_method', 'PATCH');
        const route = feeConfig ? `${endpoints.fee_config}${feeConfig?.id}` : endpoints.fee_config;
        makeRequest(method, route, formData, response => {
            Swal.fire(
                'Success!',
                `Fee config was ${feeConfig ? "edited" : "saved"} successfully!`,
                'success'
            ).then(() => {
                setFeeConfig(response.data);
                setData(response.data);
            });
        }, (error) => {
            if (error.response.status === 400) {
                setErrors(error.response.data)
            } else
                showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false);
        }).then()
    }

    useEffect(() => {
        getFeeDetails();
    }, [semester])
    return (
        <div className="container">
            <div className="row mx-auto">
                <div className="col-12 card px-5 py-5">
                    <h3>Fee Configuration</h3>
                    <form>
                        <div className="form-group">
                            <label>Amount</label>
                            <input value={data.amount || ""} onChange={(e) => handleChangeDataFn(e, setData)}
                                   type="number"
                                   className="form-control" name="amount"/>
                            {errors.amount && <p className="small text-danger">{errors.amount[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label>Threshold (between 1 and 100)</label>
                            <p className="m-0 small">(This specifies the percentage a student should pay fee in order
                                to view unit notes)</p>
                            <input value={data.threshold || ""} onChange={(e) => handleChangeDataFn(e, setData)}
                                   type="number"
                                   className="form-control" name="threshold"/>
                            {errors.threshold && <p className="small text-danger">{errors.threshold[0]}</p>}
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => history.goBack()}>Cancel
                        </button>
                        <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                disabled={loading}>{loading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeeConfig;