import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import endpoints from "../../../routes/endpoints";
import {GET_REQUEST} from "../../../values/globals";
import {makeRequest} from "../../../helpers/network_utils";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";

class Totals extends React.Component {
    state = {
        loading: false,
        statistics: []
    }

    componentDidMount() {
        this.getStatistics();
    }

    getStatistics = () => {
        this.setState({
            loading: true
        })
        makeRequest(GET_REQUEST, endpoints.stats, {}, response => {
            this.setState({
                statistics: response.data
            })
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        }, () => {
            this.setState({
                loading: false
            })
        }).then();
    }

    render() {
        const {loading, statistics} = this.state;
        return (
            loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div className="col-12">
                    <div className="row">
                        <div className="col-xl-4 col-md-6">
                            <div className="card mb-4 bg-purple">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center ">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-purple">
                                            <span style={{fontSize: "2rem"}} className="icon-people"/>
                                        </div>
                                        <div className="media-body text-light">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.total_students ?? "-"}</h4>
                                            <span>Total Students</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="card mb-4 bg-primary">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white  sr-icon-box text-primary">
                                            <span style={{fontSize: "1.5rem"}} className="icon-people"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.total_lecs ?? "-"}</h4>
                                            <span>Total Lecturers</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="card mb-4 bg-primary">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white  sr-icon-box text-primary">
                                            <span style={{fontSize: "1.5rem"}} className="icon-people"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.total_branch_coordinators ?? "-"}</h4>
                                            <span>Total Center Coordinators</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="card mb-4 bg-danger">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-danger">
                                            <span style={{fontSize: "1.5rem"}} className="icon-people"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.total_finance_officers ?? "-"}</h4>
                                            <span>Total Finance Officers</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="card mb-4 bg-danger">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-danger">
                                            <span style={{fontSize: "1.5rem"}} className="icon-people"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.total_content_creators ?? "-"}</h4>
                                            <span>Total Content Creators</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="card mb-4 bg-success">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-success">
                                            <i className="icon-people"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.total_super_admins ?? "-"}</h4>
                                            <span>Total Admins</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Totals;