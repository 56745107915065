import {FETCH_EXPENSE_TYPE, LOADING_EXPENSE_TYPES} from "./actions";

const branchesState = {
    expense_types: null,
};

const reducer = (state = branchesState, action) => {
    if (action.type === FETCH_EXPENSE_TYPE) {
        return {...state, expense_types: action.payload, loadingExpenseTypes: false};
    }
    if (action.type === LOADING_EXPENSE_TYPES) {
        return {...state, loadingExpenseTypes: true};
    }
    return state;
};

export default reducer;