import React, {Component} from 'react';
import {getUserObject, setUserObject} from "../../../helpers/login";
import EditProfile from "./edit";
import Swal from "sweetalert2";
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {parseErrorResponse} from "../../../helpers/helper_functions";

const $ = window.$;

class Profile extends Component {

    getUser = () => {
        setUserObject(null, () => this.setState({}));
    }

    handleEditProfile = e => {
        e.preventDefault();
        $('#editProfileModal').modal('show');
    }

    resetPasswordDialog = () => {
        const user = getUserObject();
        makeRequest(POST_REQUEST, endpoints.password_reset, {email: user.email}, () => {
            Swal.fire(
                'Success!',
                'A password reset link has been sent to your email.',
                'success'
            ).then(() => {
                return true;
            });
        }, (error) => {
            Swal.fire(
                `Error`,
                `Request failed: ${parseErrorResponse(error)}`,
                'error'
            ).then();
        }).then()
        Swal.fire({
            title: 'Sending Password Reset Link',
            didOpen: () => {
                Swal.showLoading();
            }
        }).then();
    }

    render() {
        const user = getUserObject();
        return (
            <div>
                <EditProfile getUser={this.getUser}/>
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-md-6 profile-info-position">
                        <div className="card card-shadow mb-4 h-100 d-flex flex-column">
                            <div className="card-body d-flex flex-column justify-content-center">
                                <div className="text-center d-flex flex-column">
                                    <div className="mt-4 mb-3">
                                        {/*<img className="rounded-circle" src={"/assets/img/login-bg.jpg"}
                                             width="100" alt=""/>*/}
                                    </div>
                                    <h5 className="text-uppercase mb-0">{user.first_name} {user.last_name}</h5>
                                    <p className="text-muted text-capitalize mb-0">{user?.role?.replaceAll("_", " ")}</p>
                                    <button onClick={this.resetPasswordDialog} className="btn btn-sm btn-info mt-3">
                                        Change Password
                                    </button>
                                    <button onClick={this.getUser} className="btn btn-sm btn-primary mt-3">
                                        Refresh
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-8 col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="media text-muted mb-3">
                                    <p className="media-body pb-3 mb-0">
                                        <span className="d-block text-secondary"><strong className="">Registration No</strong></span>
                                        {user?.reg_no}
                                    </p>
                                </div>
                                <div className="media text-muted mb-3">
                                    <p className="media-body pb-3 mb-0">
                                        <span className="d-block text-secondary"><strong className="">Name</strong></span>
                                        {user?.name}
                                    </p>
                                </div>
                                <div className="media text-muted mb-3">
                                    <p className="media-body pb-3 mb-0">
                                        <span className="d-block text-secondary"><strong className="">Last
                                            Name</strong></span>
                                        {user?.last_name}
                                    </p>
                                </div>
                                <div className="media text-muted mb-3">
                                    <p className="media-body pb-3 mb-0">
                                        <span className="d-block text-secondary"><strong
                                            className="">Email</strong></span>
                                        {user?.email}
                                    </p>
                                </div>
                                <div className="media text-muted mb-3">
                                    <p className="media-body pb-3 mb-0">
                                        <span className="d-block text-secondary"><strong
                                            className="">Phone</strong></span>
                                        {user?.phone_number ?? "-"}
                                    </p>
                                </div>
                                {user?.branch && <div className="media text-muted mb-3">
                                    <p className="media-body pb-3 mb-0">
                                        <span className="d-block text-secondary"><strong
                                            className="">Branch</strong></span>
                                        {user.branch?.name ?? "-"}
                                    </p>
                                </div>}
                                <button onClick={this.handleEditProfile}
                                        className="btn btn-info w-100 btn-sm mt-3">Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button onClick={() => populateMakes()} className="btn btn-sm btn-info mt-3 mr-2">
                    Populate Makes
                </button>*/}
                {/* <button onClick={() => populateModels()} className="btn btn-sm btn-info mt-3">
                    Populate Models
                </button>*/}
            </div>
        );
    }
}

export default Profile;