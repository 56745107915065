const endpoints = {
    statistics: "super_admin/statistics/",
    login: "login/",
    me: "me/",
    logout: "logout/",
    password_reset: "forgot/",
    stats: "stats/",
    fee_stats: "fee-stats/",
    inventory: "inventory/",
    inventory_stats: "inventory-stats/",
    expenses: "expenses/",
    income: "incomes/",
    expenses_stats: "expense-stats/",
    income_stats: "income-stats/",
    users: "users/",
    reset_password: "reset_password/",
    branches: "branches/",
    expense_types: "expense-types/",
    income_types: "income-types/",
    exam_types: "exam-types/",
    courses: "courses/",
    semesters: "semesters/",
    timetable: "timetable/",
    units: "units/",
    semester_units: "unit-semester/",
    enquiries: "enquiries/",
    news: "news/",
    fee_statements: "fee-statements/",
    fee: "fee/",
    exam_results: "exam-results/",
    fee_config: "fee-config/",
    course_types: "course-types/",
    exam_grades: "exam-grades/",
    grades: "exam-grades/",
    exam_config: "exams/",
    exam_deadlines: "exam-deadlines",
    course_assignment: "course-assignment/",
    unit_assignment: "unit-assignment/",
    course_branches: "course-branches/",
    zoom_links: "zoom-links/",
    change_password: "password/change/",

};

export default endpoints;