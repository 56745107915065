/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, POST_REQUEST, roles} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {handleChangeDataFn, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import Swal from "sweetalert2";
import {useDispatch, useSelector} from "react-redux";
import {getUserObject} from "../../../helpers/login";
import {getBranches} from "../../../redux/branch/actions";
import CustomLoader from "../../../components/loaders/custom_loader";

function InventoryAdd() {
    const {id} = useParams();
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({});
    const [loading, setLoading] = useState();
    const loggedInUser = getUserObject();
    const {loadingBranches, branches} = useSelector(state => state.branchesState)
    const dispatch = useDispatch();

    const getInventoryDetails = () => {
        if (id) {
            setLoading(true);
            makeRequest(GET_REQUEST, `${endpoints.inventory}${id}`, {}, response => {
                let data = {...response.data};
                let filteredData = {};
                //delete unnecessary keys.
                const allowedKeys = ["count", "name"];
                for (let i = 0; i < allowedKeys.length; i++) {
                    const key = allowedKeys[i];
                    filteredData[key] = data[key];
                }
                filteredData.branch = data?.branch?.id;


                setData(filteredData);
            }, () => {
                showAlert('error', 'Error', 'Could not load Inventory', () => history.goBack());
            }, () => {
                setLoading(false);
            }).then()
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        let formData = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = data[key];
            formData.append(key, value);
        }

        const method = POST_REQUEST;
        if (id)
            formData.append('_method', 'PATCH');
        const route = id ? `${endpoints.inventory}${id}` : endpoints.inventory;
        makeRequest(method, route, formData, () => {
            Swal.fire(
                'Success!',
                `Inventory was ${id ? "edited" : "saved"} successfully!`,
                'success'
            ).then(() => {
                history.replace("/inventory");
            });
        }, (error) => {
            if (error.response.status === 400) {
                setErrors(error.response.data)
            } else
                showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false);
        }).then()
    }

    useEffect(() => {
        //fetch branches
        if (loggedInUser?.role === roles.super_admin
            || loggedInUser?.role === roles.super_finance
            || loggedInUser?.role === roles.extension_administrator)
            if (branches == null)
                dispatch(getBranches())
        getInventoryDetails();
    }, [id])
    return (
        <div className="container">
            <div className="row mx-auto">
                <div className="col-12 card px-5 py-5">
                    <h3 className="mb-5">Add Inventory</h3>
                    <form>
                        <div className="form-group">
                            <label>Name</label>
                            <input value={data?.name || ""} onChange={(e) => handleChangeDataFn(e, setData)}
                                   type="text"
                                   className="form-control" name="name"/>
                            {errors.name && <p className="small text-danger">{errors.name[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label>Count</label>
                            <input value={data?.count || ""} onChange={(e) => handleChangeDataFn(e, setData)}
                                   type="text"
                                   className="form-control" name="count"/>
                            {errors.count && <p className="small text-danger">{errors.count[0]}</p>}
                        </div>
                        {(loggedInUser?.role === roles.super_admin
                            || loggedInUser?.role === roles.extension_administrator
                            || loggedInUser?.role === roles.super_finance) && <div className="form-group">
                            <label className="m-0">Center</label>
                            {loadingBranches ? <CustomLoader/> :
                                <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                        name="branch">
                                    <option value="">Select Center</option>
                                    {
                                        branches?.map(branch => <option
                                            value={branch?.id}>{branch?.name}</option>)
                                    }
                                </select>}
                            {errors.branch &&
                            <p className="small text-danger">{errors.branch[0]}</p>}
                        </div>}
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => history.goBack()}>Cancel
                        </button>
                        <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                disabled={loading}>{loading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InventoryAdd;