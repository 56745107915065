import React, {useEffect, useState} from 'react';
import {DELETE_REQUEST, PATCH_REQUEST, POST_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {makeRequest} from "../../../../helpers/network_utils";
import Swal from "sweetalert2";
import {handleChangeDataFn, parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";

const $ = window.$;

function UnitAdd({unit, getUnits}) {
    const [loading, setLoading] = useState();
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (unit)
            setData(unit);
    }, [unit])
    const handleDelete = () => {
        if (unit)
            Swal.fire({
                title: "Confirm Delete",
                text: `Are you sure you want to delete this unit?`,
                icon: "info",
                showConfirmButton: true,
                showCancelButton: true
            }).then(result => {
                if (result.isConfirmed) {
                    makeRequest(DELETE_REQUEST, `${endpoints.units}${unit.id}/`, {}, () => {
                        Swal.fire(
                            'Success!',
                            `Unit was deleted successfully!`,
                            'success'
                        ).then(() => {
                            getUnits();
                            $("#addUnitModal").modal('toggle');
                            $('body').removeClass('modal-open');
                            $('.modal-backdrop').remove();
                        });
                    }, (error) => {
                        if (error.response.status === 400)
                            setErrors(error.response.data);
                        else
                            showAlert('error', 'Error', parseErrorResponse(error));
                    }, () => {
                        setLoading(false);
                    }).then()
                }
            });
    }

    const handleSubmit = () => {
        setLoading(true);
        const method = unit ? PATCH_REQUEST : POST_REQUEST;
        const endpoint = unit ? `${endpoints.units}${unit.id}/` : endpoints.units;
        makeRequest(method, endpoint, data, () => {
            Swal.fire(
                'Success!',
                `Unit was ${unit ? "Edited" : "Saved"} successfully!`,
                'success'
            ).then(() => {
                getUnits();
                $("#addUnitModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            if (error.response.status === 400)
                setErrors(error.response.data);
            else
                showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false);
        }).then()
    }

    return (
        <div className="modal fade" id="addUnitModal" role="dialog"
             aria-labelledby="exampleModalCenterTitle"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalCenterTitle">Add Unit</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 text-left">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Name</label>
                                    <input value={data?.name || ""} onChange={(e) => handleChangeDataFn(e, setData)}
                                           type="text"
                                           className="form-control" name="name"/>
                                    {errors.name && <p className="small text-danger">{errors.name[0]}</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button onClick={handleDelete} type="button" className="btn btn-danger"
                                data-dismiss="modal">Delete
                        </button>
                        <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                disabled={loading}>{loading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UnitAdd;