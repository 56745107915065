import Dashboard from "../pages/finance/dashboard";
import FeeStatements from "../pages/finance/fee-statements";

const financeUrls = {
    index: {
        path: '/',
        exact: true,
        component: Dashboard,
    },

    fee: {
        path: '/fee',
        exact: true,
        component: FeeStatements
    },

}

export default financeUrls;