import React, {useState} from 'react';
import YearSemesterSelect from "../../../../components/inputs/year_semester_select";
import {makeRequest} from "../../../../helpers/network_utils";
import {POST_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {handleChangeDataFn, parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";

const $ = window.$;

const AddFeeStatement = ({student, callback, feeStatement}) => {
    const [data, setData] = useState(feeStatement || {});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState();


    const handleAddFeeStatement = () => {
        setLoading(true);
        if (typeof data?.semester === 'object')
            data.semester = data?.semester?.id;

        data.student = student?.id;
        makeRequest(POST_REQUEST, endpoints.fee_statements, data, () => {
            showAlert('success', 'Success', 'Fee statement added successfully!');
            $("#addFeeStatementModal").modal('toggle');
            if (callback)
                callback();
        }, error => {
            if (error.response.status === 400)
                setErrors(error.response.data)
            else
                showAlert('error', 'Error', parseErrorResponse(error));
        }).then(() => {
            setLoading(false);
        })

    }
    return (
        <div className="modal fade" id="addFeeStatementModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalCenterTitle">Add Fee Statement</h5>
                        <button onClick={e => {
                            e.preventDefault();
                            $("#addFeeStatementModal").modal('toggle');
                        }} type="button" className="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12">
                            <form>
                                <div className="form-group">
                                    <label>Student</label>
                                    <h5>{student?.name}</h5>
                                    {errors?.name && <p className="text-danger small"> {errors?.name[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <YearSemesterSelect user={student} onSemesterChange={semester => {
                                        setData({...data, semester: semester?.id})
                                    }}/>
                                </div>
                                <div className="form-group">
                                    <label>Amount</label>
                                    <input
                                        type="number"
                                        value={data?.amount || ""}
                                        onChange={e => handleChangeDataFn(e, setData)}
                                        className="form-control" name="amount"/>
                                    {errors?.amount && <p className="text-danger small"> {errors?.amount[0]}</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={e => {
                            e.preventDefault();
                            $("#addFeeStatementModal").modal('toggle');
                        }} type="button" className="btn btn-secondary">Close
                        </button>
                        <button onClick={handleAddFeeStatement} disabled={loading} type="button" className="btn btn-primary">
                            {loading ? "Loading" : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFeeStatement;