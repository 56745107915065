import React from "react";
import {Link} from "react-router-dom";

export const enquiryColumns = () => [
    {
        dataField: 'name',
        text: 'Name',
    },
    {
        dataField: 'phone_number',
        text: 'Phone Number',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'email',
        text: 'Email',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'id',
        text: 'Action',
        formatter: id => <Link to={`/enquiries/${id}`} className="btn btn-sm btn-success">View</Link>
    },

];