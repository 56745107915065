import {Link} from "react-router-dom";
import React from "react";

export const inventoryColumns = [
    {
        dataField: 'name',
        text: 'Name',
        //formatter: link => <a rel="noreferrer" href={link} target="_blank">{link}</a>
    },
    {
        dataField: 'count',
        text: 'Count',
        //formatter: description => <p>{description}</p>
    },
    {
        dataField: 'created_at',
        text: 'Uploaded On',
        formatter: date => `${new Date(date).toLocaleDateString()}`
    },
    {
        dataField: 'branch',
        text: 'Center',
        formatter: branch => `${branch?.name}`
    },
    {
        dataField: 'uploaded_by',
        text: 'Uploaded By',
        formatter: head =>
            <div className="d-flex flex-column">
                <p>{head.name}</p>
                <p>{head.email}</p>
            </div>
    },
    {
        dataField: 'id',
        text: 'Action',
        formatter: id =>
            <Link to={`/inventory/${id}`}
                  className="btn btn-sm btn-success">
                View
            </Link>

    },
]

export const inventoryColumnsNoAction = [
    {
        dataField: 'name',
        text: 'Name',
        //formatter: link => <a rel="noreferrer" href={link} target="_blank">{link}</a>
    },
    {
        dataField: 'count',
        text: 'Count',
        //formatter: description => <p>{description}</p>
    },
    {
        dataField: 'created_at',
        text: 'Uploaded On',
        formatter: date => `${new Date(date).toLocaleDateString()}`
    },
    {
        dataField: 'uploaded_by',
        text: 'Uploaded By',
        formatter: head =>
            <div className="d-flex flex-column">
                <p>{head.name}</p>
                <p>{head.email}</p>
            </div>
    },
]