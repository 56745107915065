import React, {Component} from 'react';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../../components/Table";
import BranchAdd from "./add";
import Swal from "sweetalert2";
import {makeRequest} from "../../../../helpers/network_utils";
import {DELETE_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";
import {getExpenseTypes} from "../../../../redux/expense_type/actions";

const $ = window.$;

const mapStateToProps = state => (state.expenseTypesState);

const mapDispatchToProps = dispatch => ({
    getExpenseTypes: () => dispatch(getExpenseTypes()),
});
let self;
const columns = [
    {
        dataField: 'id',
        text: 'ID',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'name',
        text: 'Name',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'iddd',
        text: 'Action',
        formatter: (id, row) => <button onClick={() => self.handleEditBranch(row)}
                                        className="btn btn-sm btn-primary">
            Edit
        </button>
    },
    {
        dataField: 'idd',
        text: 'Action',
        formatter: (id, row) => <button disabled={self.state.loadingDelete}
                                        onClick={() => self.handleDeleteBranch(row.id)}
                                        className="btn btn-sm btn-danger">
            {self.state.loadingDelete ? "Loading" : "Delete"}
        </button>

    },
]


class ExpenseTypes extends Component {
    state = {};

    componentDidMount() {
        self = this;
        const {getExpenseTypes, expense_types} = this.props;
        if (expense_types == null) getExpenseTypes();
    }

    handleAddBranch = e => {
        e.preventDefault();
        this.setState({
            expense_type: null
        }, () => $('#addExpenseTypeModal').modal('show'));
    }

    handleEditBranch = (expense_type) => {
        this.setState({
            expense_type: expense_type
        }, () => $('#addExpenseTypeModal').modal('show'));

    }

    handleDeleteBranch = (id) => {
        Swal.fire({
            title: "Confirm Delete",
            text: `Are you sure you want to delete this Expense Type?`,
            icon: "info",
            showConfirmButton: true,
            showCancelButton: true
        }).then(result => {
            if (result.isConfirmed) {

                this.setState({
                    loadingDelete: true
                })
                makeRequest(DELETE_REQUEST, `${endpoints.expense_types}${id}/`, {}, () => {
                    Swal.fire(
                        'Success!',
                        `Expense Type was deleted successfully!`,
                        'success'
                    ).then(() => {
                        this.props.getBranches();
                    });
                }, (error) => {
                    showAlert('error', 'Error', parseErrorResponse(error));
                }, () => {
                    this.setState({loadingDelete: false})
                }).then()
            }
        })
    }

    render() {
        const {loadingExpenseTypes, expense_types,} = this.props;
        const {expense_type} = this.state;
        return (loadingExpenseTypes ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div>
                    <BranchAdd expense_type={expense_type} key={Math.random()}
                               getExpenseTypes={this.props.getExpenseTypes}/>
                    <Table
                        caption={"Expense Types"}
                        actionText={"Add Expense Type"}
                        columns={columns}
                        actionFunc={this.handleAddBranch}
                        data={expense_types}
                    />
                </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseTypes);