/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST, POST_REQUEST, roles} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChangeDataFn, parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import {getUserObject} from "../../helpers/login";
import {useHistory, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import CustomLoader from "../../components/loaders/custom_loader";
import {getBranches} from "../../redux/branch/actions";
import DatePicker from "react-date-picker";

function UserAdd() {
    const [loading, setLoading] = useState();
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({})
    const loggedInUser = getUserObject();
    const history = useHistory();
    const {id} = useParams();
    const {
        loadingBranches,
        branches
    } = useSelector(state => state.branchesState)
    const dispatch = useDispatch();

    const getUserDetails = () => {
        if (id) {
            setLoading(true);
            makeRequest(GET_REQUEST, `${endpoints.users}${id}`, {}, response => {
                let data = {...response.data};
                data.created_at = new Date(data.created_at);
                delete data.updated_at;

                setData(data);
            }, () => {
                showAlert('error', 'Error', 'Could not load user', () => history.goBack());
            }, () => {
                setLoading(false);
            }).then()
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        setErrors({});
        let formData = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = data[key];
            if (value) {
                if (key === "profile_picture") {
                    if (typeof data[key] === 'object')
                        formData.append(key, value);
                } else if (key === "created_at") {
                    value.setHours(value.getHours() + 3);
                    formData.append('created_at', value.toISOString());
                } else if (key === "branch") {
                    if (typeof value === 'object')
                        formData.append('branch', value?.id);
                    else
                        formData.append('branch', value);
                } else
                    formData.append(key, value);
            }
        }
        let endpoint = endpoints.users;
        if (id) {
            endpoint = `${endpoints.users}${id}`
            formData.append('_method', 'PATCH');
        }
        makeRequest(POST_REQUEST, endpoint, formData, response => {
            const message = id ? 'User was edited successfully!' : 'User was saved successfully! ' +
                'The created user will receive an email with their credentials.';
            const user = response.data;
            Swal.fire(
                'Success!',
                message,
                'success'
            ).then(() => {
                if (user.role === roles.lecturer)
                    history.push(`/lecturers/${user?.id}`)
                else if (user.role === roles.student)
                    history.push(`/students/${user?.id}`)
                else
                    history.push(`/users/${user?.id}`);
            });


        }, (error) => {
            if (error.response.status === 400)
                setErrors(error.response.data)
            else
                showAlert('error', 'Error!', parseErrorResponse(error));
        }, () => {
            setLoading(false);
        }).then()
    }


    useEffect(() => {
        getUserDetails();

        //fetch branches
        if (loggedInUser?.role === roles.super_admin)
            if (branches == null)
                dispatch(getBranches())
    }, [id])

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="text-center">
                                Add User
                            </h2>
                            <form>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input onChange={(e) => handleChangeDataFn(e, setData)} type="text"
                                           value={data?.name || ""}
                                           className="form-control" name="name"/>
                                    {errors.name && <p className="small text-danger">{errors.name[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input onChange={(e) => handleChangeDataFn(e, setData)} type="text"
                                           value={data?.email || ""}
                                           className="form-control" name="email"/>
                                    {errors.email && <p className="small text-danger">{errors.email[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input onChange={(e) => handleChangeDataFn(e, setData)} type="number"
                                           value={data?.phone || ""}
                                           className="form-control" name="phone"/>
                                    {errors.phone && <p className="small text-danger">{errors.phone[0]}</p>}
                                </div>
                                <div className="form-group">
                                    <label>Reg No</label>
                                    <p className="small">
                                        (Leave blank to automatically generate registration number)
                                    </p>
                                    <input onChange={(e) => handleChangeDataFn(e, setData)} type="text"
                                           value={data?.reg_no || ""}
                                           className="form-control" name="reg_no"/>
                                    {errors.reg_no && <p className="small text-danger">{errors.reg_no[0]}</p>}
                                </div>
                                {/* <div className="form-group">
                                    <label>ID No</label>
                                    <input onChange={(e) => handleChangeDataFn(e, setData)} type="text"
                                           value={data?.id_no || ""}
                                           className="form-control" name="id_no"/>
                                    {errors.id_no && <p className="small text-danger">{errors.id_no[0]}</p>}
                                </div>*/}
                                <div className="form-group">
                                    <label className="m-0">Role</label>
                                    {loggedInUser.role === roles.super_admin ?
                                        <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                                name="role">

                                            {data?.role ? <option value={`${data?.role}`}>{data?.role}</option> :
                                                <option>Select Role</option>}
                                            <option value="super_admin">Super Admin</option>
                                            <option value="branch_coordinator">Center Coordinator</option>
                                            <option value="content_creator">Content Creator</option>
                                            <option value="student">Student</option>
                                            <option value="lecturer">Lecturer</option>
                                            <option value="finance_officer">Finance Officer</option>
                                            <option value="super_finance">Accounting Officer</option>
                                            <option value="academic_dean">Academic Dean</option>
                                            <option value="extension_administrator">Extension Administrator</option>
                                        </select> : loggedInUser.role === roles.extension_administrator ?
                                            <select onChange={e => handleChangeDataFn(e, setData)}
                                                    className="form-control"
                                                    name="role">
                                                {data?.role ? <option value={`${data?.role}`}>{data?.role}</option> :
                                                    <option>Select Role</option>}
                                                <option value="student">Student</option>
                                                <option value="lecturer">Lecturer</option>
                                                <option value="branch_coordinator">Center Coordinator</option>
                                            </select>
                                            :
                                            <select onChange={e => handleChangeDataFn(e, setData)}
                                                    className="form-control"
                                                    name="role">
                                                {data?.role ? <option value={`${data?.role}`}>{data?.role}</option> :
                                                    <option>Select Role</option>}
                                                <option value="student">Student</option>
                                            </select>
                                    }
                                    {errors.role &&
                                    <p className="small text-danger">{errors.role[0]}</p>}
                                </div>
                                {/*<div className="form-group">
                                    <label>Profile Picture</label>
                                    <input
                                        onChange={e => {
                                            setData({
                                                    ...data,
                                                    profile_picture: e.target.files[0]
                                                }
                                            )
                                        }}
                                        type="file"
                                        accept="image/*"
                                        className="form-control"
                                        required/>
                                    {errors.profile_picture && (
                                        <p className="mb-0 small text-danger">{errors.profile_pic[0]}</p>
                                    )}
                                    {(data?.profile_picture && typeof data?.profile_picture === 'string') &&
                                    <div className="col-12">
                                        <a rel={"noreferrer"} target={"_blank"} href={data?.profile_picture}>
                                            Current
                                        </a>
                                    </div>}
                                </div>*/}
                                <div className="form-group">
                                    <label className="m-0">Status</label>
                                    <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                            name="active">
                                        {data?.active != null ?
                                            <option
                                                value={`${data?.active ? "1" : "0"}`}>{data?.active ? "Active" : "In-Active"}</option> : null}
                                        <option value="1">Active</option>
                                        <option value="0">In-Active</option>
                                    </select>
                                    {errors.active &&
                                    <p className="small text-danger">{errors.active[0]}</p>}
                                </div>

                                {loggedInUser?.role === roles.super_admin && <div className="form-group">
                                    <label className="m-0">Center</label>
                                    {loadingBranches ? <CustomLoader/> :
                                        <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                                name="branch">
                                            {data?.branch ?
                                                <option value={`${data?.branch?.id}`}>{data?.branch?.name}</option> :
                                                <option value="">Select Center</option>
                                            }

                                            {
                                                branches?.map(branch => <option key={branch?.id}
                                                                                value={branch?.id}>{branch?.name}</option>)
                                            }
                                        </select>}
                                    {errors.branch &&
                                    <p className="small text-danger">{errors.branch[0]}</p>}
                                </div>}
                                <div className="col-md">
                                    <p>Date Joined (Leave blank to automatically select current date)</p>
                                    <DatePicker
                                        onChange={date => {
                                            setData({
                                                ...data,
                                                created_at: date
                                            })
                                        }}
                                        value={data?.created_at}
                                    />
                                    {errors?.created_at && <p className="text-danger small">{errors?.created_at}</p>}
                                </div>
                            </form>
                            <div className="modal-footer">
                                <button onClick={() => history.goBack()} type="button" className="btn btn-secondary"
                                        data-dismiss="modal">Close
                                </button>
                                <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                        disabled={loading}>{loading ? "Loading..." : "Save"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default UserAdd;