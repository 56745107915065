import {makeRequest} from "../helpers/network_utils";
import {GET_REQUEST} from "../values/globals";
import {getUrl, parseErrorResponse, showAlert, throttle} from "../helpers/helper_functions";

const $ = window.$;

class Loader_fn {
    next;
    glSetData;
    glSetLoading;
    glIsPaginated;
//previous;
//first;
//last;
//count;

//Used for functional components.

    /**
     *
     * @param {string} initUrl
     * @param {function} setLoading - set loading status
     * @param {function} setData - set data on state
     * @param {boolean} [shouldDetectScroll]
     * @param {boolean} [isPaginated]
     * @param {boolean} [skipInitialLoad] - whether to show loader when initially loading.
     * @returns {function(*): void}
     */
    constructor(initUrl, setLoading, setData, shouldDetectScroll = true, isPaginated = true, skipInitialLoad = false) {
        // We assume that at any point, only one instance of this function will be called.
        // This function will at most be called by one component at a time.

        this.glSetData = setData;
        this.glSetLoading = setLoading;
        this.glIsPaginated = isPaginated;
        if (!skipInitialLoad)
            this.glSetLoading(true);
        this.getData(initUrl);
        if (shouldDetectScroll)
            this.detectScroll();
    };

    onUnMount = () => {
        //$(window).off('scroll');
    }

    getData = (url, loadingNext = false) => {
        makeRequest(
            GET_REQUEST,
            url,
            {},
            response => {
                const res = response.data;

                if (this.glIsPaginated) {
                    //paginated data.
                    //Attach the next and previous links and attach loader on scroll.
                    this.next = res.links?.next;
                    //previous = res.previous;
                    //first = res.first;
                    //last = res.last;
                    //count = res.count;
                    if (loadingNext)
                        this.glSetData(oldData => {
                            return [...oldData, ...res.data]
                        });
                    else
                        this.glSetData(res.data);
                } else
                    this.glSetData(res);
            },
            error => {
                showAlert("error", 'Error', parseErrorResponse(error));
            },
            () => {
                this.glSetLoading(false);
            }
        ).then();
    }

    detectScroll = () => {
        $(window).scroll(() => {
            if ($(window).scrollTop() >= $(document).height() - $(window).height() - 300) {
                this.loadMoreContent();
            }
        });
    }

    loadMoreContent = throttle(() => {
        if (this.next) {
            this.getData(getUrl(this.next), true);
        }
    }, 1000);
}

export default Loader_fn;
