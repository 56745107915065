/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router";
import {makeRequest} from "../../../../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST} from "../../../../../values/globals";
import endpoints from "../../../../../routes/endpoints";
import {parseErrorResponse, showAlert, toDateStringWordsFromString} from "../../../../../helpers/helper_functions";
import Swal from "sweetalert2";
import CircularProgress from "@material-ui/core/CircularProgress";
import Semesters from "./semesters/semesters";
import Branches from "./branches";

const pages = {
    index: "semesters/",
    branches: "branches/"
}

function CourseDetails(props) {
    const [loading, setLoading] = useState();
    const [course, setCourse] = useState();
    const [loadingDelete, setLoadingDelete] = useState();
    const [currentPath, setCurrentPath] = useState(pages.index);
    const history = useHistory();

    const getCourse = () => {
        const {id} = props.match.params;
        setLoading(true);
        makeRequest(
            GET_REQUEST,
            `${endpoints.courses}${id}`,
            {},
            response => {
                setCourse(response.data);
            },
            error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }, () => {
                setLoading(false);
            }).then();
    }

    const handleDelete = () => {
        Swal.fire({
                title: 'Information!',
                text: 'Are you sure you want delete this?',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancel'
            }
        ).then(result => {
            if (result.isConfirmed) {
                setLoadingDelete(true);
                makeRequest(DELETE_REQUEST, `${endpoints.course}${course?.id}`, {}, () => {
                    Swal.fire(
                        'Success!',
                        'Course deleted successfully!',
                        'success',
                    ).then(() => history.push('/courses'));
                }, error => {
                    showAlert('error', 'Error', parseErrorResponse(error))
                }, () => {
                    setLoadingDelete(false);
                }).then()
            }

        });
    }

    useEffect(() => {
        getCourse();
    }, []);
    return (
        <div className="mb-5">
            {
                loading ?
                    <div className="text-center">
                        <CircularProgress/>
                    </div> :
                    course ?
                        <div className="col-12 py-5">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <div style={{overflow: "hidden", width: "10rem", height: "10rem",}}
                                                 className="rounded-circle bg-primary mr-5">
                                                {course?.cover_image &&
                                                <img src={course?.cover_image}
                                                     style={{width: "10rem", height: "10rem", objectFit: "contain"}}
                                                     alt={course?.name}/>}
                                            </div>
                                        </div>
                                        <div>
                                            <h3 className="m-0 mb-2"> {course.name}</h3>
                                            <h5 className="font-weight-normal">Type: <b>{course?.type?.name}</b></h5>
                                            <h5 className="font-weight-normal">Duration: <b>{course?.months} Months</b>
                                            </h5>
                                            <h5 className="font-weight-normal">Starts: <b>{toDateStringWordsFromString(course?.start_date)}</b>
                                            </h5>
                                            <h5 className="font-weight-normal">Created: <b>{new Date(course?.created_at).toLocaleDateString()}</b>
                                            </h5>
                                            <div>
                                                <button onClick={() => {
                                                    history.push(`/courses/add/${course.id}`)
                                                }}
                                                        className="btn btn-sm btn-info">Edit
                                                </button>
                                                <button
                                                    disabled={loadingDelete}
                                                    onClick={handleDelete}
                                                    className="btn btn-sm btn-danger ml-3">{loadingDelete ? "Loading.." : "Delete"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-nav-links mb-3">
                                <ul className="nav f12">
                                    <li className="nav-item">
                                        <a onClick={e => {
                                            e.preventDefault();
                                            setCurrentPath(pages.index);
                                        }} href={"/a"}
                                           className={`nav-link ${currentPath === pages.index && 'active'}`}>
                                            Semester
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={e => {
                                            e.preventDefault();
                                            setCurrentPath(pages.branches);
                                        }} href={"/b"}
                                           className={`nav-link ${currentPath === pages.branches && 'active'}`}>
                                            Branches
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {currentPath === pages.index && <Semesters course={course}/>}
                            {currentPath === pages.branches && <Branches course={course}/>}
                        </div> :
                        <div className="text-center">
                            <p>Course could not be loaded.</p>
                        </div>
            }
        </div>
    );
}

export default CourseDetails;