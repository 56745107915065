import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Branches from "./branches";
import Grades from "./grading";
import Courses from "./courses";
import CourseTypes from "./course_types";
import Units from "./units";
import {getUserObject} from "../../../helpers/login";
import {roles} from "../../../values/globals";
import ExpenseTypes from "./expense_types";
import ExamTypes from "./exam_types";
import ZoomLink from "./zoom_links";
import IncomeTypes from "./income_types";

const pages = {
    branches: "/settings/branches",
    expense_types: "/settings/expense-types",
    income_types: "/settings/income-types",
    grades: "/settings/grades",
    exam_types: "/settings/exam-types",
    course_types: "/settings/course-types",
    courses: "/settings/courses",
    units: "/settings/units",
    zoom_links: "/settings/zoom-links"
    //  suppliers: "/settings/suppliers",
    //  features: "/settings/features",
}

class Settings extends Component {

    render() {
        const {location} = this.props;
        const loggedInUser = getUserObject();
        let currentPath = location.pathname;
        if (currentPath === "/settings")
            currentPath = pages.branches;
        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col-md-10 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="profile-nav-links">
                                    <ul className="nav f12">
                                        {loggedInUser?.role === roles.super_admin && <li className="nav-item">
                                            <Link
                                                className={`nav-link ${currentPath === pages.branches && 'active'}`}
                                                to="/settings/branches">Centers</Link>
                                        </li>}
                                        {loggedInUser?.role === roles.super_admin && <li className="nav-item">
                                            <Link
                                                className={`nav-link ${currentPath === pages.expense_types && 'active'}`}
                                                to="/settings/expense-types">Expense Types</Link>
                                        </li>}
                                        {loggedInUser?.role === roles.super_admin && <li className="nav-item">
                                            <Link
                                                className={`nav-link ${currentPath === pages.income_types && 'active'}`}
                                                to="/settings/income-types">Income Types</Link>
                                        </li>}
                                        {(loggedInUser?.role === roles.super_admin ||
                                            loggedInUser.role === roles.academic_dean
                                        ) && <li className="nav-item">
                                            <Link
                                                className={`nav-link ${currentPath === pages.grades && 'active'}`}
                                                to="/settings/grades">Grades</Link>
                                        </li>}
                                        {(loggedInUser?.role === roles.super_admin ||
                                            loggedInUser.role === roles.academic_dean
                                        ) && <li className="nav-item">
                                            <Link
                                                className={`nav-link ${currentPath === pages.exam_types && 'active'}`}
                                                to="/settings/exam-types">Exam Types</Link>
                                        </li>}
                                        {(loggedInUser?.role === roles.super_admin ||
                                            loggedInUser.role === roles.academic_dean
                                        ) && <li className="nav-item">
                                            <Link
                                                className={`nav-link ${currentPath === pages.course_types && 'active'}`}
                                                to="/settings/course-types">Course Types</Link>
                                        </li>}

                                        {(loggedInUser?.role === roles.super_admin ||
                                            loggedInUser.role === roles.extension_administrator ||
                                            loggedInUser.role === roles.academic_dean
                                        ) && <li className="nav-item">
                                            <Link
                                                className={`nav-link ${currentPath === pages.courses && 'active'}`}
                                                to="/settings/courses">Courses</Link>
                                        </li>}
                                        {loggedInUser?.role === roles.super_admin && <li className="nav-item">
                                            <Link
                                                className={`nav-link ${currentPath === pages.zoom_links && 'active'}`}
                                                to={pages.zoom_links}>Zoom Link</Link>
                                        </li>}
                                        <li className="nav-item">
                                            {(loggedInUser?.role === roles.super_admin ||
                                                loggedInUser.role === roles.academic_dean
                                            ) && <Link
                                                className={`nav-link ${currentPath === pages.units && 'active'}`}
                                                to="/settings/units">Units</Link>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-body">
                                {(loggedInUser?.role === roles.super_admin && currentPath === pages.expense_types) &&
                                <ExpenseTypes/>}
                                {(loggedInUser?.role === roles.super_admin && currentPath === pages.income_types) &&
                                <IncomeTypes/>}
                                {(loggedInUser?.role === roles.super_admin && currentPath === pages.branches) &&
                                <Branches/>}
                                {((loggedInUser?.role === roles.super_admin ||
                                    loggedInUser.role === roles.academic_dean
                                ) && currentPath === pages.grades) &&
                                <Grades/>}
                                {((loggedInUser?.role === roles.super_admin ||
                                    loggedInUser.role === roles.academic_dean

                                ) && currentPath === pages.exam_types) &&
                                <ExamTypes/>}
                                {((loggedInUser?.role === roles.super_admin ||
                                    loggedInUser.role === roles.academic_dean
                                ) && currentPath === pages.course_types) &&
                                <CourseTypes/>}
                                {((loggedInUser?.role === roles.super_admin ||
                                    loggedInUser.role === roles.extension_administrator ||
                                    loggedInUser.role === roles.academic_dean
                                ) && currentPath === pages.courses) &&
                                <Courses/>}
                                {currentPath === pages.units && <Units/>}
                                {(loggedInUser?.role === roles.super_admin && currentPath === pages.zoom_links) &&
                                <ZoomLink/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings;