import React from 'react';
import {numberWithCommas, toDateStringWordsFromString} from "../../../../../../helpers/helper_functions";
import {useHistory} from "react-router";

function SingleSemester({semester}) {
    const history = useHistory();
    return (
        <div className="col-lg-6">
            <div className="card bg-primary text-white h-100 mx-2 my-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h3>Semester {semester?.number}, Year {semester?.year}</h3>
                            <h5>Semester Duration (Weeks):</h5>
                            <p>{semester?.weeks}</p>
                            <h5>Begins At (Weeks):</h5>
                            <p>{toDateStringWordsFromString(semester?.start_date)}</p>
                            <h5>Ends At (Weeks):</h5>
                            <p>{toDateStringWordsFromString(semester?.end_date)}</p>
                            <h5>Semester Fee</h5>
                            <p>KES {numberWithCommas(semester?.total_fee ?? "Not Set")}</p>
                            <h5 className="mb-0">Semester Exam</h5>
                            <p className="m-0 small">(The day the semester exam begins and ends)</p>
                            <p className="m-0">Starts: <b>{semester?.exam?.start_date ?
                                toDateStringWordsFromString(semester?.exam?.start_date) : "Not Set"}</b>
                            </p>
                            <p>Ends: <b>{semester?.exam?.end_date ?
                                toDateStringWordsFromString(semester?.exam?.end_date) : "Not Set"}</b>
                            </p>
                            <h5 className="mb-0">Exam Results Deadline</h5>
                            <p className="m-0 small">(The deadline to which lecturers are required to upload exam
                                results)</p>
                            <h6>{semester?.exam_deadline?.deadline ?
                                new Date(semester?.exam_deadline?.deadline).toLocaleDateString() : "Not Set"}</h6>
                            <button onClick={() => {
                                history.push(`/semesters/add/${semester?.course?.id}/${semester?.id}`)
                            }} className="btn btn-light mt-5">Edit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleSemester;