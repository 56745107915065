/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import Loader_fn from "../../../../providers/loader_fn";
import endpoints from "../../../../routes/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../../components/Table";
import {courseColumns} from "../../../../providers/courses/data";
import {useHistory} from "react-router";
import {onChange, parseErrorResponse, parseUrls, showAlert} from "../../../../helpers/helper_functions";
import {options} from "../../../expenses";
import {useDispatch, useSelector} from "react-redux";
import {GET_REQUEST, roles} from "../../../../values/globals";
import {getBranches} from "../../../../redux/branch/actions";
import {getUserObject} from "../../../../helpers/login";
import {makeRequest} from "../../../../helpers/network_utils";

function Courses() {
    const [loading, setLoading] = useState();
    const [courses, setCourses] = useState([]);
    const [loadingCourseTypes, setLoadingCourseTypes] = useState();
    const [courseTypes, setCourseTypes] = useState([]);
    const history = useHistory();
    const [fields, setFields] = useState({});
    const {branches} = useSelector(state => state.branchesState);
    const loggedInUser = getUserObject();
    const dispatch = useDispatch();

    useEffect(() => {
        if (loggedInUser?.role === roles.super_admin)
            if (branches == null)
                dispatch(getBranches());

        getCourseTypes();
        return getCourses();
    }, [])

    const getCourseTypes = () => {
        setLoadingCourseTypes(true);
        makeRequest(GET_REQUEST, `${endpoints.course_types}`, {}, response => {
            setCourseTypes(response.data);
        }, (error) => {
            showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoadingCourseTypes(false);
        }).then()
    }

    const getCourses = (endpoint = endpoints.courses) => {
        const loader = new Loader_fn(endpoint, setLoading, setCourses);
        return loader.onUnMount();
    }

    //Handle automatic fetching when field urls change.
    useEffect(() => {
        parseUrls([endpoints.courses],
            [
                (urls) => getCourses(urls[0])
            ], fields)
    }, [fields])


    const handleAddCourse = () => {
        history.push("/courses/add");
    }
    return (

        <div>
            {loadingCourseTypes ?
                <div className="text-center">
                    <CircularProgress/>
                </div> : <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Filter By Type</label>
                    <select onChange={e => {
                        onChange("type", e.target.value, options.replace, fields, setFields);
                    }} className="form-control">
                        <option>Select Course Type</option>
                        {courseTypes?.map(course_type =>
                            <option key={course_type?.id}
                                    value={course_type?.id}>{course_type?.name}</option>
                        )}
                    </select>
                </div>}
            {loggedInUser.role === roles.super_admin &&
            <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Filter By Center</label>
                <select onChange={e => {
                    onChange("branch", e.target.value, options.replace, fields, setFields);
                }} className="form-control">
                    <option>Select Center</option>
                    {branches?.map(branch =>
                        <option key={branch?.id} value={branch?.id}>{branch?.name}</option>
                    )}
                </select>
            </div>}
            {loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <Table
                    caption={"Courses"}
                    actionText={"Add Course"}
                    columns={courseColumns}
                    actionFunc={handleAddCourse}
                    data={courses}
                />}
        </div>
    );
}

export default Courses;