import Dashboard from "../pages/admin/dashboard";
import Users from "../pages/users";
import Students from "../pages/admin/students";
import StudentDetails from "../pages/admin/students/details";
import UserAdd from "../pages/users/add";
import UserDetails from "../pages/users/details";
import Enquiries from "../pages/admin/enquiries";
import EnquiryDetails from "../pages/admin/enquiries/details";
import Settings from "../pages/admin/settings";
import CourseAdd from "../pages/admin/settings/courses/add";
import CourseDetails from "../pages/admin/settings/courses/details";
import SemesterAdd from "../pages/admin/settings/courses/details/semesters/add";
import Lecturers from "../pages/admin/lecturers";
import LecturerDetails from "../pages/admin/lecturers/details";

const adminUrls = {
    index: {
        path: '/',
        exact: true,
        component: Dashboard,
    },

    users: {
        path: '/users',
        exact: true,
        component: Users,
    },
    user_add: {
        path: '/users/add',
        exact: true,
        component: UserAdd,
    },
    user_edit: {
        path: '/users/add/:id',
        exact: true,
        component: UserAdd,
    },
    user_details: {
        path: '/users/:id',
        exact: true,
        component: UserDetails,
    },

    enquiries: {
        path: '/enquiries',
        exact: true,
        component: Enquiries,
    },
    enquiry_details: {
        path: '/enquiries/:id',
        exact: true,
        component: EnquiryDetails
    },

    students: {
        path: '/students',
        exact: true,
        component: Students,
    },

    student_details: {
        path: '/students/:id',
        exact: true,
        component: StudentDetails,
    },

    lecturers: {
        path: '/lecturers',
        exact: true,
        component: Lecturers,
    },

    lecturer_details: {
        path: '/lecturers/:id',
        exact: true,
        component: LecturerDetails,
    },

    settings: {
        path: "/settings",
        exact: false,
        component: Settings
    },

    course_add: {
        path: '/courses/add',
        exact: true,
        component: CourseAdd,
    },
    course_edit: {
        path: '/courses/add/:id',
        exact: true,
        component: CourseAdd,
    },
    course_details: {
        path: '/courses/:id',
        exact: true,
        component: CourseDetails,
    },

    semester_add: {
        path: '/semesters/add/:courseId',
        exact: true,
        component: SemesterAdd,
    },
    semester_edit: {
        path: '/semesters/add/:courseId/:id',
        exact: true,
        component: SemesterAdd,
    },
}

export default adminUrls;