export const days = [
    "",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
]

function CalendarDate({value, color, onClick}) {
    return (
        <div onClick={() => onClick(value)} style={{width: "100%", height: "3.5rem"}}
             className={`d-flex align-items-center clickable justify-content-center
              ${color ? color : "bg-light"} ${color ? "text-white" : ""}
                 rounded mx-2 my-2`}>
            {days[value]}
        </div>

    )
}


export default CalendarDate;