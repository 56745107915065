import React, {useEffect, useState} from 'react';
import CustomLoader from "../loaders/custom_loader";
import Loader_fn from "../../providers/loader_fn";
import PropTypes from 'prop-types';

let courseLoader;

function CustomSelectPagination({
                                    endpoint,
                                    onChange,
                                    includeDefaultItem,
                                    key = "id",
                                    value = "name",
                                    placeholder = "Select Item",
                                }) {
    const [dropDownActive, setDropDownActive] = useState();
    const [items, setItems] = useState();
    const [loadingItems, setLoadingItems] = useState();
    const [selectedItem, setSelectedItem] = useState(null);
    useEffect(() => {
        if (endpoint)
            courseLoader = new Loader_fn(endpoint,
                setLoadingItems, setItems, false);
    }, [endpoint])
    return (
        <div style={{position: "relative",}} className="form-group">
            <div style={{cursor: "pointer"}}
                 onClick={() => setDropDownActive(!dropDownActive)}
                 className="d-flex justify-content-between align-items-center px-3 bg-white py-1 border">
                <label className="mb-0">{selectedItem ?
                    selectedItem?.name : placeholder}</label>
                <span className="icon-arrow-down"/>
            </div>

            <div style={{zIndex: "9", display: dropDownActive ? "block" : "none"}}
                 className="position-absolute w-100">
                <div className="card">
                    <div className="card-body">
                        {includeDefaultItem &&
                        <div style={{cursor: "pointer"}} onClick={() => {
                            setDropDownActive(false);
                            onChange(null);
                            setSelectedItem(null);
                        }}
                             className="custom-select">
                            <p>{placeholder}</p>
                        </div>
                        }
                        {
                            items?.map(item =>
                                <div style={{cursor: "pointer"}} onClick={() => {
                                    setDropDownActive(false);
                                    onChange(item);
                                    setSelectedItem(item);
                                }}
                                     className="custom-select"
                                     key={item[key]}>
                                    <p>{item[value]}</p>
                                </div>)
                        }
                        {loadingItems && <CustomLoader/>}
                        {courseLoader?.next &&
                        <div className="text-center">
                            <button onClick={() => courseLoader.loadMoreContent()}
                                    className="btn btn-primary">More
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

CustomSelectPagination.propTypes = {
    onChange: PropTypes.func.isRequired,
    endpoint: PropTypes.string.isRequired
}

export default CustomSelectPagination;