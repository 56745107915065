import React from "react";

import {Provider} from "react-redux";
import store from "./redux/store";
import {BrowserRouter as Router} from "react-router-dom";
import ScrollToTop from "./components/main/scroll_to_top";
import Main from "./components/main";
import './assets/scss/main.scss';
import PrintHeader from "./components/containers/print_header";

function App() {
    return (
        <Provider store={store}>
            <Router>
                <ScrollToTop>
                    <PrintHeader/>
                    <Main/>
                </ScrollToTop>
            </Router>
        </Provider>
    );
}

export default App;
