import {numberWithCommas} from "../../helpers/helper_functions";
import {Link} from "react-router-dom";
import React from "react";

export const expenseColumns = [
    {
        dataField: 'name',
        text: 'Name',
    },
    {
        dataField: 'amount',
        text: 'Amount',
        formatter: amount => `KES ${numberWithCommas(amount ?? "-")}`
    },
    {
        dataField: 'type',
        text: 'Type',
        formatter: type => `${type?.name || "-"}`
    },
    {
        dataField: 'uploaded_by',
        text: 'Added By',
        formatter: (user) => <div>
            <p>{user.name}</p>
            <p>{user.email}</p>
        </div>
    },
    {
        dataField: 'created_at',
        text: 'Uploaded On',
        formatter: date => `${new Date(date).toLocaleDateString()}`
    },
    {
        dataField: 'id',
        text: 'Action',
        formatter: id =>
            <Link to={`/expenses/${id}`}
                  className="btn btn-sm btn-success">
                View
            </Link>

    },
]

export const expenseColumnsNoAction = [
    {
        dataField: 'name',
        text: 'Name',
    },
    {
        dataField: 'amount',
        text: 'Amount',
        formatter: amount => `KES ${numberWithCommas(amount ?? "-")}`
    },
    {
        dataField: 'type',
        text: 'Type',
        formatter: type => `${type?.name || "-"}`
    },
    {
        dataField: 'created_at',
        text: 'Uploaded On',
        formatter: date => `${new Date(date).toLocaleDateString()}`
    },

]