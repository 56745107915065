import React, {useEffect, useState} from 'react';
import Results from "./results";
import YearSemesterSelect from "../../../../../components/inputs/year_semester_select";
import endpoints from "../../../../../routes/endpoints";
import Loader_fn from "../../../../../providers/loader_fn";
import CustomLoader from "../../../../../components/loaders/custom_loader";

const Exams = ({student}) => {
    const [semester, setSemester] = useState(student?.semester);
    const [year, setYear] = useState(student?.year);
    const [loadingExamTypes, setLoadingExamTypes] = useState();
    const [selectedExamType, setSelectedExamType] = useState();
    const [examTypes, setExamTypes] = useState([]);

    useEffect(() => {
        getExamTypes().then();
    }, [])
    const onSemesterChange = semester => {
        setSemester(semester);
    }

    const onYearChange = year => {
        setYear(year);
    }

    const semesterText = `Semester ${semester?.number || "-"}, Year ${year || "-"}, ${new Date().getFullYear()}`;

    const getExamTypes = async () => {
        new Loader_fn(
            `${endpoints.exam_types}`,
            setLoadingExamTypes, setExamTypes, false, false);
    }


    return (
        <div className="row mx-auto justify-content-center">
            <div className="col-12 text-center mt-3">
                <h1 className="cs-border-bottom-primary pb-2 mb-4">
                    Exam Results
                </h1>
                <YearSemesterSelect user={student}
                                    onYearChange={onYearChange}
                                    onSemesterChange={onSemesterChange}/>
                {loadingExamTypes ? <CustomLoader/> :
                    <div className="form-group">
                        <select onChange={e => {
                            setSelectedExamType(JSON.parse(e.target.value));
                        }} className="form-control mt-4">
                            <option>Select Exam Type</option>
                            {examTypes?.map(examType =>
                                <option key={examType?.id} value={JSON.stringify(examType)}>
                                    {examType?.name}</option>
                            )}
                        </select>
                    </div>}
                <h5 className="font-weight-bold mb-3 text-center"> {semesterText}</h5>
                <div className="text-center">
                    <Results selectedExamType={selectedExamType} semester={semester} user={student}/>
                </div>
            </div>
        </div>
    );
};

export default Exams;