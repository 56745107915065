import Login from "../pages/auth/login";
import Logout from "../pages/auth/logout/logout";
import Profile from "../pages/auth/profile";
import ChangePassword from "../pages/auth/change_password";

const urls = {
    profile: {
        path: '/profile',
        exact: true,
        component: Profile,
    },

    logout: {
        path: '/logout',
        exact: true,
        component: Logout,
    },

    login: {
        path: "/login",
        exact: true,
        component: Login
    },

    change_password: {
        path: "/change-password",
        exact: true,
        component: ChangePassword
    },
}

export default urls;