/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import CustomLoader from "../../components/loaders/custom_loader";
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import {parseErrorResponse, printElement, showAlert} from "../../helpers/helper_functions";
import Table from "../../components/Table";

function PrintService({endpoint, columns, title, subtitle, hideCaption}) {
    const [loading, setLoading] = useState();
    const [data, setData] = useState([]);

    const fetchData = () => {
        //check if there is a url
        if (endpoint) {
            setLoading(true);
            makeRequest(GET_REQUEST, endpoint, {}, response => {
                const data = response.data;
                setData(data);
            }, error => {
                showAlert('error', 'Error', parseErrorResponse(error));
            }, () => {
                setLoading(false);
            }).then();
        }
    }

    const handlePrintReport = () => {
        printElement("tabulated_results", title || "NPBC Printing Service");
    }

    useEffect(() => {
        fetchData();
        return () => {
        }
    }, [endpoint, columns, title, subtitle])

    return (
        <div className="modal fade" id="printServiceModal" tabIndex="-1" role="dialog"
             aria-labelledby="printServiceModal" aria-hidden="true">
            <div style={{margin: "18px 0 0 18px", maxWidth: "100%"}}
                 className="modal-dialog modal-dialog-centered modal-lg"
                 role="document">
                <div style={{
                    height: "auto",
                    minHeight: "100%"
                }} className="modal-content">
                    <div className="modal-header d-flex justify-content-between">
                        <h5 className="modal-title" id="exampleModalLongTitle">{title}</h5>
                        <button onClick={handlePrintReport} className="btn btn-sm btn-primary">Print Report</button>
                        <button type="button" className="close ml-0" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 h-100 text-center">
                            {
                                loading ?
                                    <CustomLoader/> :
                                    data?.length < 1 ? <h5>Could not fetch data. Please try again</h5> :
                                        <div id={"tabulated_results"} className="text-left mt-4">
                                            <h5 className="font-weight-bold">
                                                {title || "NPBC Portal Printing Services"}
                                            </h5>
                                            <h4 className="font-weight-bold">{subtitle || "-"}</h4>
                                            <div className="card">
                                                <div className="card-body">
                                                    <Table
                                                        caption={title || "-"}
                                                        hideCaption={hideCaption}
                                                        hideAction={true}
                                                        columns={columns ?? []}
                                                        data={data}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrintService;