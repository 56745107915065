import CalendarDate from "./calendar_config/calendar_date";

function Calendar({onClickDay,selectedDay}) {
    let myArr = [];

    for (let i = 1; i < 8; i++) {
        myArr.push(<CalendarDate onClick={day => {
            onClickDay(day);
        }} key={i} value={i}
                                 color={i === selectedDay ? "bg-info" : ""}/>)
    }
    return (
        <div className="col-12">
            <div className="card shadow-sm rounded">
                <div className="card-body">
                    <div className="row mx-auto no-gutters">
                        {myArr}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Calendar;