import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import endpoints from "../../../routes/endpoints";
import {POST_REQUEST} from "../../../values/globals";
import {handleChangeDataFn, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";

function ChangePassword() {
    const [loading, setLoading] = useState();
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const getUser = async () => {
            const login = (await import("../../../helpers/login.js"));
            const user = login.getUserObject();
            setData(user);
            return () => {
            }
        }

        getUser().then();
    }, []);

    const handleSubmit = e => {
        e.preventDefault();

        setLoading(true);
        setErrors({});

        makeRequest(POST_REQUEST, endpoints.change_password, data, () => {
            showAlert('success', 'Success!', 'Password changed successfully!');
            window.location.href = "/";
        }, error => {
            if (error.response.status === 400)
                setErrors(error.response.data)
            else
                showAlert('error', 'Error', parseErrorResponse(error))
        }).then(() => {
            setLoading(false);
        })
    }

    return (
        <div className="row mx-auto justify-content-center my-5 py-5">
            <div className="col-lg-6 col-md-8">
                <h3 className="text-center mb-3">Reset Password</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Old Password</label>
                        <input onChange={e => handleChangeDataFn(e, setData)} type="password" name="old_password"
                               className="form-control"/>
                        {errors.old_password && <p className="text-danger small">{errors.old_password}</p>}
                    </div>
                    <div className="form-group">
                        <label>New Password</label>
                        <input onChange={e => handleChangeDataFn(e, setData)} type="password" name="password"
                               className="form-control"/>
                        {errors.password && <p className="text-danger small">{errors.password}</p>}
                    </div>
                    <div className="form-group">
                        <label>Confirm New Password</label>
                        <input onChange={e => handleChangeDataFn(e, setData)} type="password"
                               name="password_confirmation" className="form-control"/>
                        {errors.password_confirmation &&
                        <p className="text-danger small">{errors.password_confirmation}</p>}
                    </div>
                    <button
                        disabled={loading}
                        className="btn btn-dark w-100">
                        {loading ? "Loading..." : "Change"}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ChangePassword;