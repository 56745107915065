/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router";
import Loader_fn from "../../../providers/loader_fn";
import endpoints from "../../../routes/endpoints";
import CustomLoader from "../../../components/loaders/custom_loader";
import Table from "../../../components/Table";
import {inventoryColumns, inventoryColumnsNoAction} from "../../../providers/inventory/data";
import {useDispatch, useSelector} from "react-redux";
import {getBranches} from "../../../redux/branch/actions";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, roles} from "../../../values/globals";
import {numberWithCommas, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import {getUserObject} from "../../../helpers/login";
import PrintService from "../../print";

const $ = window.$;

function Inventory() {
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [loadingStats, setLoadingStats] = useState();
    const [stats, setStats] = useState();
    const [inventory, setInventory] = useState([]);
    const {branches} = useSelector(state => state.branchesState);
    const dispatch = useDispatch();
    const loggedInUser = getUserObject();
    const [currentEndpoint, setCurrentEndpoint] = useState();
    const [printProps, setPrintProps] = useState({});
    const [label, setLabel] = useState();

    const handleAddInventory = e => {
        e.preventDefault();
        history.push(`/inventory/add`)
    }

    useEffect(() => {
        getEmBranches();
        getStats();
        return getInventory();
    }, []);

    const getInventory = (endpoint = endpoints.inventory) => {
        setCurrentEndpoint(endpoint);
        const loaderFn = new Loader_fn(
            endpoint, setLoading, setInventory);
        return loaderFn.onUnMount();
    }

    const getStats = (endpoint = endpoints.inventory_stats) => {
        setLoadingStats(true);
        makeRequest(GET_REQUEST, endpoint, {}, response => {
            setStats(response.data);
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        }).then(() => setLoadingStats(false))
    }

    const getEmBranches = () => {
        if (loggedInUser?.role === roles.super_admin || loggedInUser?.role === roles.super_finance)
            if (branches == null)
                dispatch(getBranches());
    }
    const columns = branches?.map(branch => ({label: branch?.name, value: branch?.id})) || [];

    const handlePrintReport = () => {
        let endpoint = `${currentEndpoint}`;
        if (endpoint?.includes("?"))
            endpoint = `${endpoint}&dont_paginate=true`;
        else
            endpoint = `${endpoint}?dont_paginate=true`;
        const printProps = {
            endpoint: endpoint,
            columns: inventoryColumnsNoAction,
            title: `All Inventory${label ? ` - ${label}` : ""}`,
            subtitle:
                <h5>
                    Total Inventory: <b>{numberWithCommas(stats?.total_inventory ?? "-")}</b> <br/>
                </h5>
        };
        setPrintProps(printProps);
        $('#printServiceModal').modal('show');
    }

    return (
        <div className="row">
            <PrintService {...printProps}/>
            <div className="col-12">
                {loading &&
                <CustomLoader/>
                }
                <div className="card">
                    <div className="card-body">
                        {loadingStats ? <CustomLoader/> :
                            <div className="row">
                                <div className="col-md">
                                    <div className="card mb-4 bg-purple">
                                        <div className="card-body">
                                            <div className="media d-flex align-items-center ">
                                                <div className="mr-4 rounded-circle bg-white sr-icon-box text-purple">
                                                    <span style={{fontSize: "2rem"}} className="icon-people"/>
                                                </div>
                                                <div className="media-body text-light">
                                                    <h4 className="text-uppercase mb-0 weight500">{numberWithCommas(stats?.total_inventory ?? "-")}</h4>
                                                    <span>Total Inventory</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        <div className="text-center">
                            <button onClick={handlePrintReport} className="btn btn-primary btn-sm">
                                Print
                            </button>
                        </div>
                        <Table
                            caption={"All Inventory"}
                            actionText={"Add Inventory"}
                            actionFunc={handleAddInventory}
                            columns={inventoryColumns}
                            filterColumnText={"All Centers"}
                            filterColumns={(loggedInUser.role === roles.super_admin ||
                                loggedInUser?.role === roles.super_finance
                                || loggedInUser?.role === roles.extension_administrator
                            ) ? columns : []}
                            filterAction={(value, label) => {
                                setLabel(label);
                                getInventory(`${endpoints.inventory}?branch=${value}`);
                                getStats(`${endpoints.inventory_stats}?branch=${value}`);
                            }}
                            data={inventory}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Inventory;