import urls from "./urls";
import adminUrls from "./admin_urls";
import admin_urls from "./admin_urls";
import coordinatorUrls from "./coordinator_urls";
import financeUrls from "./finance_urls";
import contentCreatorUrls from "./content_creator_urls";
import {academicDeanUrls} from "./academic_dean_urls";

export const routes = [
    urls.profile,
    urls.login,
    urls.logout,
    urls.change_password
];

export const adminRoutes = [
    admin_urls.index,
    coordinatorUrls.inventory,
    coordinatorUrls.inventory_add,
    coordinatorUrls.inventory_edit,
    coordinatorUrls.inventory_details,
    coordinatorUrls.expenses,
    coordinatorUrls.expenses_add,
    coordinatorUrls.expenses_edit,
    coordinatorUrls.expenses_details,
    coordinatorUrls.income,
    coordinatorUrls.income_add,
    coordinatorUrls.income_edit,
    coordinatorUrls.income_details,
    coordinatorUrls.exam_timetable,
    coordinatorUrls.semester_timetable,
    adminUrls.users,
    adminUrls.user_add,
    adminUrls.user_edit,
    admin_urls.user_details,
    admin_urls.students,
    admin_urls.student_details,
    admin_urls.lecturers,
    admin_urls.lecturer_details,
    adminUrls.enquiries,
    adminUrls.enquiry_details,
    contentCreatorUrls.news,
    contentCreatorUrls.news_add,
    contentCreatorUrls.news_edit,
    contentCreatorUrls.news_details,
    financeUrls.fee,
    admin_urls.settings,
    admin_urls.course_add,
    admin_urls.course_edit,
    admin_urls.course_details,
    admin_urls.semester_add,
    admin_urls.semester_edit
];

export const coordinatorRoutes = [
    coordinatorUrls.index,
    coordinatorUrls.inventory,
    coordinatorUrls.inventory_add,
    coordinatorUrls.inventory_edit,
    coordinatorUrls.inventory_details,
    coordinatorUrls.expenses,
    coordinatorUrls.expenses_add,
    coordinatorUrls.expenses_edit,
    coordinatorUrls.expenses_details,
    coordinatorUrls.income,
    coordinatorUrls.income_add,
    coordinatorUrls.income_edit,
    coordinatorUrls.income_details,
    coordinatorUrls.exam_timetable,
    coordinatorUrls.semester_timetable,
    adminUrls.users,
    adminUrls.user_add,
    adminUrls.user_edit,
    admin_urls.user_details,
    admin_urls.students,
    admin_urls.student_details,
    admin_urls.lecturers,
    admin_urls.lecturer_details,
    admin_urls.settings,
];

export const extensionAdministratorRoutes = [...coordinatorRoutes, admin_urls.course_add,
    admin_urls.course_edit,
    admin_urls.course_details,];

export const financeRoutes = [
    financeUrls.index,
    financeUrls.fee,
    admin_urls.students,
    admin_urls.student_details
];


export const superFinanceRoutes = [
    financeUrls.index,
    financeUrls.fee,
    admin_urls.students,
    admin_urls.student_details,
    coordinatorUrls.inventory,
    coordinatorUrls.inventory_add,
    coordinatorUrls.inventory_edit,
    coordinatorUrls.inventory_details,
    coordinatorUrls.expenses,
    coordinatorUrls.expenses_add,
    coordinatorUrls.expenses_edit,
    coordinatorUrls.expenses_details,
    coordinatorUrls.income,
    coordinatorUrls.income_add,
    coordinatorUrls.income_edit,
    coordinatorUrls.income_details,
    admin_urls.students,
    admin_urls.student_details,

];

export const contentCreatorRoutes = [
    contentCreatorUrls.index,
    contentCreatorUrls.news,
    contentCreatorUrls.news_add,
    contentCreatorUrls.news_edit,
    contentCreatorUrls.news_details
]

export const academicDeanRoutes = [
    academicDeanUrls.index,
    admin_urls.students,
    admin_urls.student_details,
    coordinatorUrls.exam_timetable,
    coordinatorUrls.semester_timetable,
    admin_urls.lecturers,
    admin_urls.lecturer_details,
    admin_urls.settings,
]