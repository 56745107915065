import React, {Component} from "react";
import Totals from "./totals";
import FeeStatements from "../../finance/fee-statements";

class Dashboard extends Component {
    render() {
        return (
            <div>
                <Totals/>
                {/* <ExpensesStats/>*/}
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="ml-2">Fee Statistics</h2>
                            <FeeStatements/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;