const PrintHeader = () => {
    return (
        <div style={{display: "none"}}>
            <div id={"print_header"}>
                <div className="d-flex justify-content-center align-items-center flex-column mb-5">
                    <img src={"/assets/img/logo.png"}
                         alt={"logo"}
                         style={{width: '100px', height: "auto", objectFit: "contain"}}/>
                    <p className="m-0">P.O Box 30267, 00100</p>
                    <p className="m-0">Tel: 0754569687</p>
                    <p className="m-0"><b>Email:</b> info@npbc.co.ke, <b>Website:</b> www.npbc.co.ke</p>
                    <p className="m-0">Nairobi Pentecostal Bible College,
                        Garden Estate Rd Off Thika Road, Nairobi</p>
                </div>
            </div>
        </div>
    )
}

export default PrintHeader;