/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import FeeStatements from "./fee_statements";
import Exams from "./exams";
import Table from "../../../../components/Table";
import {studentColumns} from "../../../../providers/users/data";
import Loader_fn from "../../../../providers/loader_fn";
import endpoints from "../../../../routes/endpoints";
import {useParams} from "react-router";
import CustomLoader from "../../../../components/loaders/custom_loader";
import {roles} from "../../../../values/globals";
import {getUserObject} from "../../../../helpers/login";
import UserCourseAdd from "./add_course";

const $ = window.$;

const pages = {
    fee_statements: "/fee",
    /*attendance_records: "/attendance",*/
    exam_records: "/exam",
}

const StudentDetails = () => {
    const [currentPath, setCurrentPath] = useState(pages.fee_statements);
    const [loading, setLoading] = useState();
    const [student, setStudent] = useState();
    const {id} = useParams();
    const loggedInUser = getUserObject();

    useEffect(() => {
        return getStudent();
    }, [id])

    const getStudent = () => {
        const loaderFn = new Loader_fn(`${endpoints.users}${id}`, setLoading, setStudent, false, false);
        return loaderFn.onUnMount();
    }
    const handleAddEditCourse = e => {
        e.preventDefault();
        $('#addUserCourseModal').modal('show');
    }

    return (
        <div className="col-12">
            {student && <UserCourseAdd getStudent={getStudent} student={student}/>}
            <div className="card mb-2">
                <div className="card-body">
                    {loading ? <CustomLoader/> :
                        <div className="d-flex">
                            <div style={{overflow: "hidden", width: "10rem", height: "10rem",}}
                                 className="rounded-circle bg-primary mr-5">
                                {student?.profile_picture &&
                                <img src={student?.profile_picture}
                                     style={{width: "10rem", height: "10rem", objectFit: "contain"}}
                                     alt={student?.name}/>}
                            </div>
                            <div>
                                <h3>Name: <b>{student?.name}</b></h3>
                                <h3>Reg No: <b>{student?.reg_no}</b></h3>
                                <h3>Course: <b>{student?.semester?.course?.name}</b></h3>
                                <h3>Year: <b>{student?.year}</b></h3>
                                <h3>Semester: <b>{student?.semester?.number}</b></h3>
                                <button onClick={handleAddEditCourse} className="btn btn-sm btn-info mt-3">
                                    Add/Edit Course
                                </button>
                            </div>
                        </div>}
                </div>
            </div>
            <div className="card my-3">
                <div className="card-body">
                    <Table
                        caption={<h3>Student Details</h3>}
                        hideAction={true}
                        columns={studentColumns}
                        data={[student || {}]}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="profile-nav-links">
                                <ul className="nav f12">
                                    <li className="nav-item">
                                        <span onClick={() => setCurrentPath(pages.fee_statements)}
                                              className={`clickable nav-link ${currentPath === pages.fee_statements && 'active'}`}>Fee
                                            Statements</span>
                                    </li>
                                    {/*<li className="nav-item">
                                        <span onClick={() => setCurrentPath(pages.attendance_records)}
                                              className={`clickable nav-link ${currentPath === pages.attendance_records && 'active'}`}
                                        >Attendance Records</span>
                                    </li>*/}
                                    {(loggedInUser?.role === roles.super_admin || loggedInUser?.role === roles.academic_dean) &&
                                    <li className="nav-item">
                                        <span onClick={() => setCurrentPath(pages.exam_records)}
                                              className={`clickable nav-link ${currentPath === pages.exam_records && 'active'}`}
                                        >Exam Records</span>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {currentPath === pages.fee_statements && <FeeStatements student={student}/>}
                            {/* {currentPath === pages.attendance_records && <Attendance student={student}/>}*/}
                            {currentPath === pages.exam_records && <Exams student={student}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentDetails;