import React, {useState} from 'react';
import {POST_REQUEST} from "../../../../../../values/globals";
import endpoints from "../../../../../../routes/endpoints";
import {makeRequest} from "../../../../../../helpers/network_utils";
import Swal from "sweetalert2";
import {parseErrorResponse, showAlert} from "../../../../../../helpers/helper_functions";
import CustomSelectPagination from "../../../../../../components/inputs/custom_select_pagination";

const $ = window.$;

function UnitAdd({semester, getUnits}) {
    const [loading, setLoading] = useState();
    const [data, setData] = useState();

    const handleSubmit = () => {
        setLoading(true);
        data.semester = semester?.id;
        makeRequest(POST_REQUEST, endpoints.semester_units, data, () => {
            Swal.fire(
                'Success!',
                `Unit was added successfully!`,
                'success'
            ).then(() => {
                getUnits();
                $("#addLecUnitModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false)
        }).then()
    }

    /* let endpoint = `${endpoints.courses}?branch=${loggedInUser?.branch?.id}`;

     if (loggedInUser.role === roles.super_admin)
         endpoint = endpoints.courses;*/

    return (
        <div className="modal fade" id="addUnitModal" role="dialog"
             aria-labelledby="exampleModalCenterTitle"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Semester Unit</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Units</label>
                                    <CustomSelectPagination placeholder={"Select Unit"} onChange={unit => {
                                        setData({...data, unit: unit?.id})
                                    }} endpoint={`${endpoints.units}`}/>
                                </div>
                                <a href={"/settings/units"} style={{textDecoration: "underline"}}
                                   className="text-dark small">
                                    (No units? Add more here)
                                </a>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                disabled={loading}>{loading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UnitAdd;