import React, {useEffect, useState} from 'react';
import Loader_fn from "../../../providers/loader_fn";
import endpoints from "../../../routes/endpoints";
import {roles} from "../../../values/globals";
import SearchFn from "../../../providers/search/loader_fn";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../components/Table";
import {lecturerColumns} from "../../../providers/users/data";

let search;

function Lecturers() {
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [lecturers, setLecturers] = useState([]);

    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        const loaderFn = new Loader_fn(`${endpoints.users}?role=${roles.lecturer}`, setLoading, setLecturers);
        return loaderFn.onUnMount();
    }

    const searchAction = (value) => {
        if (value?.length < 1) {
            getUsers();
            return;
        }

        if (!search)
            search = new SearchFn(`${endpoints.users}`, value, setLoadingSearch, setLecturers);
        search.search(value);
    }

    return (
        loading ?
            <div className="text-center">
                <CircularProgress/>
            </div> :
            <div className="card">
                <div className="card-body">
                    <Table
                        caption={"All Lecturers"}
                        hideAction={true}
                        columns={lecturerColumns}
                        data={lecturers}
                        loadingSearch={loadingSearch}
                        searchAction={searchAction}
                    />
                </div>
            </div>
    );
}

export default Lecturers;