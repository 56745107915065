/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {handleChangeDataFn, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import Swal from "sweetalert2";
import {useHistory, useParams} from "react-router";


const $ = window.$;

function NewsAdd() {
    const {id} = useParams();
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({});
    const [loading, setLoading] = useState();


    const getNewsDetails = () => {
        if (id) {
            setLoading(true);
            makeRequest(GET_REQUEST, `${endpoints.news}${id}`, {}, response => {
                let data = {...response.data};
                setData(data);
                $("#body").summernote('code', data.body);
            }, () => {
                showAlert('error', 'Error', 'Could not load news', () => history.goBack());
            }, () => {
                setLoading(false);
            }).then()
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        let formData = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = data[key];
            if (key === "cover_image") {
                if (typeof data[key] === 'object')
                    formData.append(key, value);
            } else
                formData.append(key, value);
        }

        const method = POST_REQUEST;
        if (id)
            formData.append('_method', 'PATCH');
        const route = id ? `${endpoints.news}${data.id}` : endpoints.news;
        makeRequest(method, route, formData, response => {
            const news = response.data;
            Swal.fire(
                'Success!',
                `News was ${id ? "edited" : "saved"} successfully!`,
                'success'
            ).then(() => {
                history.push(`/news/${news?.slug}`);
            });
        }, (error) => {
            if (error.response.status === 400) {
                setErrors(error.response.data)
            } else
                showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false);
        }).then()
    }

    useEffect(() => {
        getNewsDetails();
        $("#body").summernote({
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['fullscreen', 'codeview', 'help']]
            ],
            height: 500,
            callbacks: {
                onChange: body => {
                    setData(data => ({...data, body: body}));
                }
            }
        });

    }, [id])

    return (
        <div className="container">
            <div className="row mx-auto">
                <div className="col-12 card px-5 py-5">
                    <form>
                        <div className="form-group">
                            <label>Title</label>
                            <input value={data.title || ""} onChange={(e) => handleChangeDataFn(e, setData)}
                                   type="text"
                                   className="form-control" name="title"/>
                            {errors.title && <p className="small text-danger">{errors.title[0]}</p>}
                        </div>

                        <div className="form-group">
                            <label>Cover Image:</label>
                            <input
                                onChange={e => {
                                    setData({
                                            ...data,
                                            cover_image: e.target.files[0]
                                        }
                                    )
                                }}
                                type="file"
                                accept="image/*"
                                className="form-control"/>
                            {errors.cover_image && (
                                <p className="mb-0 small text-danger">{errors.cover_image[0]}</p>
                            )}
                            {(data?.cover_image && typeof data?.cover_image === 'string') && <div className="col-12">
                                <a rel={"noreferrer"} target={"_blank"} href={data?.cover_image}>
                                    Current
                                </a>
                            </div>}
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <input
                                value={data.description || ""}
                                onChange={(e) => handleChangeDataFn(e, setData)} type="text"
                                className="form-control" name="description"/>
                            {errors.description &&
                            <p className="small text-danger">{errors.description[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label>Body</label>
                            <textarea id="body" required/>
                            {errors.body && (
                                <p className="mb-0 small text-danger">{errors.body[0]}</p>
                            )}
                        </div>
                        <div className="form-group">
                            <label className="m-0">Featured</label>
                            <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                    name="featured">
                                {data?.featured != null ? <option value={data?.featured ? "1" : "0"}>
                                        {data?.featured ? "Yes" : "No"}
                                    </option> :
                                    <option>Select Status</option>}
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            {errors.featured &&
                            <p className="small text-danger">{errors.featured[0]}</p>}
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => history.goBack()}>Cancel
                        </button>
                        <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                disabled={loading}>{loading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsAdd;