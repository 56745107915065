import React, {useEffect, useState} from 'react';
import Table from "../../components/Table";
import CircularProgress from "@material-ui/core/CircularProgress";
import endpoints from "../../routes/endpoints";
import {userColumns} from "../../providers/users/data";
import Loader_fn from "../../providers/loader_fn";
import {useHistory} from "react-router";
import {roles} from "../../values/globals";
import {getUserObject} from "../../helpers/login";


const publicRoles = [
    {
        id: roles.student,
        label: "Students"
    },
    {
        id: roles.lecturer,
        label: "Lecturers"
    }
];

const adminRoles = [
    {
        id: roles.coordinator,
        label: "Center Coordinators"
    },
    {
        id: roles.finance,
        label: "Finance"
    },
    {
        id: roles.super_finance,
        label: "Accounting Officers"
    },
    {
        id: roles.academic_dean,
        label: "Academic Deans"
    },
    {
        id: roles.extension_administrator,
        label: "Extension Administrators"
    },
    {
        id: roles.super_admin,
        label: "Super Admins"
    }
];

function UsersList() {
    const [loading, setLoading] = useState();
    const [users, setUsers] = useState([]);
    const [role, setRole] = useState();
    const history = useHistory();
    const user = getUserObject();
    const handleAddUser = () => {
        history.push("/users/add");
    }

    const getUsers = (endpoint = endpoints.users) => {
        const loaderFn = new Loader_fn(endpoint, setLoading, setUsers);
        return loaderFn.onUnMount();
    }

    useEffect(() => {
        if (role)
            return getUsers(`${endpoints.users}?role=${role}`);
        else
            return getUsers();
    }, [role])

    return (
        <div className="card">
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Filter By Role</label>
                    <select onChange={e => {
                        setRole(e.target.value);
                    }} className="form-control">
                        <option value={""}>All</option>
                        {publicRoles.map(role =>
                            <option value={role.id}>{role.label}</option>
                        )}
                        {user?.role !== roles.coordinator &&
                        adminRoles.map(role =>
                            <option value={role.id}>{role.label}</option>
                        )}
                    </select>
                </div>

                {loading ?
                    <div className="text-center">
                        <CircularProgress/>
                    </div> :
                    <Table
                        caption={"Users"}
                        actionText={"Add User"}
                        columns={userColumns}
                        actionFunc={handleAddUser}
                        data={users}
                    />}
            </div>
        </div>
    );
}

export default UsersList;