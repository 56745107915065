/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import CustomLoader from "../../../components/loaders/custom_loader";
import Table from "../../../components/Table";
import Loader_fn from "../../../providers/loader_fn";
import endpoints from "../../../routes/endpoints";
import {useDispatch, useSelector} from "react-redux";
import {GET_REQUEST, roles} from "../../../values/globals";
import {getBranches} from "../../../redux/branch/actions";
import {getUserObject} from "../../../helpers/login";
import CustomSelectPagination from "../../../components/inputs/custom_select_pagination";
import DatePicker from "react-date-picker";
import {
    formatDate,
    numberWithCommas,
    parseErrorResponse,
    showAlert,
    toDateStringWords
} from "../../../helpers/helper_functions";
import {statementsColumns} from "../../../providers/fee_statements/data";
import PrintService from "../../print";
import {makeRequest} from "../../../helpers/network_utils";

let loaderFn;
const $ = window.$;

function FeeStatements() {
    const loggedInUser = getUserObject();
    const [loading, setLoading] = useState();
    const [statements, setStatements] = useState([]);
    const [course, setCourse] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedBranch, setSelectedBranch] = useState(loggedInUser?.branch?.id);
    const [selectedCourseType, setSelectedCourseType] = useState(loggedInUser?.branch?.id);
    const [currentEndpoint, setCurrentEndPoint] = useState();
    const {loadingBranches, branches} = useSelector(state => state.branchesState)
    const dispatch = useDispatch();
    const [printProps, setPrintProps] = useState({});
    const [loadingCourseTypes, setLoadingCourseTypes] = useState();
    const [courseTypes, setCourseTypes] = useState();
    const [stats, setStats] = useState();
    const [loadingStats, setLoadingStats] = useState();

    const getCourseTypes = () => {
        new Loader_fn(endpoints.course_types, setLoadingCourseTypes, setCourseTypes, false, false);
    }

    const getStatements = (url = loggedInUser?.role === roles.super_admin ? endpoints.fee_statements : `${endpoints.fee_statements}?branch=${loggedInUser?.branch?.id}`) => {
        setCurrentEndPoint(url);
        loaderFn = new Loader_fn(`${url}`, setLoading, setStatements);
    }

    const getFilteredStatements = () => {
        //make api endpoint.
        let endpoint = `${endpoints.fee_statements}?ignored=true`;
        let endpointStats = `${endpoints.fee_stats}?`;
        if (selectedBranch) {
            endpoint = `${endpoint}&branch=${selectedBranch}`;
            endpointStats = `${endpointStats}&branch=${selectedBranch}`;
        }
        if (course) {
            endpoint = `${endpoint}&course=${course?.id}`;
            endpointStats = `${endpointStats}&course=${course?.id}`;
        }
        if (startDate) {
            endpoint = `${endpoint}&start_date=${formatDate(startDate)}`;
            endpointStats = `${endpointStats}&start_date=${formatDate(startDate)}`;
        }
        if (endDate) {
            endpoint = `${endpoint}&end_date=${formatDate(endDate)}`;
            endpointStats = `${endpointStats}&end_date=${formatDate(endDate)}`;
        }
        if (selectedCourseType) {
            endpoint = `${endpoint}&course_type=${selectedCourseType?.id}`;
            endpointStats = `${endpointStats}&course_type=${selectedCourseType?.id}`;
        }

        getStats(endpointStats);
        getStatements(endpoint);
    }

    const getStats = (endpoint = endpoints.fee_stats) => {
        setLoadingStats(true);
        makeRequest(GET_REQUEST,
            `${endpoint}`,
            {}, response => {
                setStats(response.data);
            }, error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }).then(() => setLoadingStats(false));
    }


    const handlePrintReport = () => {
        let endpoint = `${currentEndpoint}`;
        if (endpoint?.includes("?"))
            endpoint = `${endpoint}&dont_paginate=true`;
        else
            endpoint = `${endpoint}?dont_paginate=true`;
        const printProps = {
            endpoint: endpoint,
            columns: statementsColumns,
            title: course ? `Fee Statements - ${course?.name}` : 'All Fee Statements',
            subtitle:
                <div className="d-flex flex-column">
                    {selectedCourseType &&
                    <h5>
                        {selectedCourseType?.name}
                    </h5>
                    }
                    {startDate && endDate ?
                        <h5>
                            From: <b>{toDateStringWords(startDate)}</b> <br/>
                            To: <b>{toDateStringWords(endDate)}</b>
                        </h5> : 'Entire Period'
                    }
                </div>

        };
        setPrintProps(printProps);
        $('#printServiceModal').modal('show');
    }

    useEffect(() => {
        getStatements();
        getCourseTypes();
        getStats();
        //fetch branches
        if ((loggedInUser?.role === roles.super_admin
            || loggedInUser?.role === roles.extension_administrator
            || loggedInUser?.role === roles.super_finance))
            if (branches == null)
                dispatch(getBranches())
        return loaderFn.onUnMount;
    }, [])

    const branchEndpoint = (loggedInUser?.role === roles.super_admin
        || loggedInUser?.role === roles.extension_administrator
        || loggedInUser?.role === roles.super_finance) ? selectedBranch : loggedInUser?.branch?.id;
    return (
        <div className="container">
            <PrintService {...printProps}/>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-2">
                        <div className="card-body">
                            {(loggedInUser?.role === roles.super_admin
                                || loggedInUser?.role === roles.extension_administrator
                                || loggedInUser?.role === roles.super_finance) &&
                            <div className="form-group">
                                <label className="m-0">Center</label>
                                {loadingBranches ? <CustomLoader/> :
                                    <select onChange={e => setSelectedBranch(e.target.value)} className="form-control"
                                            name="branch">
                                        <option value="">All Centers</option>
                                        {
                                            branches?.map(branch => <option key={branch?.id}
                                                                            value={branch?.id}>{branch?.name}</option>)
                                        }
                                    </select>}
                            </div>}
                            <div className="form-group">
                                <label className="m-0">Course Type</label>
                                {loadingCourseTypes ? <CustomLoader/> :
                                    <select onChange={e => setSelectedCourseType(JSON.parse(e.target.value))}
                                            className="form-control"
                                            name="branch">
                                        <option value="">All Course Types</option>
                                        {
                                            courseTypes?.map(courseType =>
                                                <option
                                                    key={courseType?.id}
                                                    value={JSON.stringify(courseType)}>{courseType?.name}</option>)
                                        }
                                    </select>}
                            </div>
                            <CustomSelectPagination placeholder={"All Courses"}
                                                    includeDefaultItem={true}
                                                    onChange={course => {
                                                        setCourse(course);
                                                    }} endpoint={`${endpoints.courses}?` +
                            `branch=${branchEndpoint ? branchEndpoint : ""}`}/>
                            <div className="row">
                                <div className="col-md">
                                    <p>Start Date</p>
                                    <DatePicker
                                        onChange={date => setStartDate(date)}
                                        value={startDate}
                                    />
                                </div>
                                <div className="col-md">
                                    <p>End Date</p>
                                    <DatePicker
                                        onChange={date => {
                                            setEndDate(date)
                                        }}
                                        value={endDate}
                                    />
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <button onClick={getFilteredStatements} className="btn btn-primary btn-sm w-100">
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-2">
                        {loadingStats ? <CustomLoader/> :
                            <div className="card-body">
                                <div className="row mx-auto justify-content-centers">
                                    <FeeStats stats={numberWithCommas(stats?.total_fee ?? "-")}
                                              color="bg-purple"
                                              textColor={"text-purple"} title={"Total Fee"}/>
                                    <FeeStats stats={numberWithCommas(stats?.total_paid ?? "-")}
                                              color="bg-success"
                                              textColor={"text-success"} title={"Total Paid"}/>
                                    <FeeStats stats={numberWithCommas(stats?.total_remaining ?? "-")}
                                              color="bg-danger"
                                              textColor={"text-danger"} title={"Total Remaining"}/>
                                </div>
                            </div>}
                    </div>
                    {loading ?
                        <div className="text-center my-5 py-5">
                            <CustomLoader/>
                        </div> :
                        <div className="card">
                            <div className="card-body">
                                <Table
                                    caption={"All Statements"}
                                    hideAction={false}
                                    actionText={"Print Report"}
                                    actionFunc={handlePrintReport}
                                    columns={statementsColumns}
                                    data={statements}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

function FeeStats(
    {
        stats, title, color = "bg-success", textColor = "text-success",
    }
) {
    return (
        <div className="col-lg-6 col-md-8">
            <div className={`card mb-4 ${color}`}>
                <div className="card-body">
                    <div className="media d-flex align-items-center ">
                        <div className={`mr-4 rounded-circle bg-white sr-icon-box ${textColor}`}>
                            <span style={{fontSize: "2rem"}} className="icon-wallet"/>
                        </div>
                        <div className="media-body text-light">
                            <h4 className="text-uppercase mb-0 weight500">KES {stats ?? "-"}</h4>
                            <span>{title}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeeStatements;