import Swal from "sweetalert2";
import {options} from "../pages/expenses";

export const parseErrorResponse = function (error) {
    let message;
    if (error.response !== null && error.response !== undefined) {
        if (error.response.data.detail) {
            message = error.response.data.detail;
        }
        if (error.response.data.message) {
            message = error.response.data.message;
        } else {
            let response = error.response.data;
            if (typeof response === "string") {
                try {
                    message = getStringFromObject(JSON.parse(response));
                } catch (e) {
                    message = "An error occurred. Please try again or contact support";
                }
            } else if (typeof response === 'object') {
                message = getStringFromObject(response);
            } else {
                message = response;
            }
        }


        if (message.length === 0) {
            switch (error.response.status) {
                case 401:
                    message = "Unauthorised. Please log in";
                    break;
                case 404:
                    message = "The requested resource could not be found";
                    break;
                default:
                    message = "An error occurred. Please try again, or contact support";
                    break;
            }
        }
    } else {
        if (typeof error === "string") {
            message = error;
        } else {
            message = "An error occurred. Please try again, or contact support";
        }

    }

    return message;
};

const getStringFromObject = function (obj) {
    let message = "";
    const map = new Map(Object.entries(obj));
    for (const [key, value] of map.entries()) {
        message = `${message} ${key}: ${value}`;
    }
    return message;
};

export const showAlert = (type, title, body, callback) => {
    Swal.fire({
        title: title,
        timer: 5000,
        icon: type,
        text: body,
        timerProgressBar: true,
    }).then(() => {
        if (callback)
            callback();
    });
}

export const handleChange = (e, self) => {
    const name = e.target.name;
    const value = e.target.value;
    self.setState({[name]: value});
}

export const handleChangeData = (e, self, number) => {
    const name = e.target.name;
    let value = e.target.value;
    if (number) {
        value = e.target.value ? parseInt(e.target.value.replace(/,/g, "")) : 0;
        if (isNaN(value))
            return;
    }

    let data = {...self.state.data};
    data[name] = value;
    self.setState({data: data});
}

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


export const throttle = // Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
    function throttle(func, wait, options) {
        let context, args, result;
        let timeout = null;
        let previous = 0;
        if (!options) options = {};
        let later = function () {
            previous = options.leading === false ? 0 : Date.now();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        };
        return function () {
            let now = Date.now();
            if (!previous && options.leading === false) previous = now;
            let remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    };

export const getUrl = (link) => {
    if (link.startsWith("http")) {
        link = link.substring(link.lastIndexOf("api") + 4);
    }
    return link;
}


export const getQueryParamsFromString = (search) => {
    if (search) {
        if (search.substring(0, 1) === "?") search = search.substring(1);
        return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        })
    }

    return search;
}


export const handleChangeDataFn = (e, setData, number, time) => {
    const name = e.target.name;
    let value = e.target.value;
    if (number) {
        value = e.target.value ? parseInt(e.target.value.replace(/,/g, "")) : 0;
        if (isNaN(value))
            return;
    }

    if (setData)
        setData(data => {
            let dataCopy = {...data};
            dataCopy[name] = value;
            setData(dataCopy);
            return dataCopy;
        })
}

export function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const printElement = (elementId, title) => {
    const myWindow = window.open('', 'PRINT', /*'height=400,width=600'*/);

    myWindow.document.write('<html lang=""><head><title></title>');
    myWindow.document.write(`<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
          integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">`);
    myWindow.document.write('</head><body>');

    myWindow.document.write('<div class="border rounded-lg p-5">');

    //Our header for all reports being printed.
    const printHeader = document.getElementById('print_header').innerHTML;
    myWindow.document.write(printHeader);

    myWindow.document.write(document.getElementById(elementId).innerHTML);

    myWindow.document.write('</div>')
    myWindow.document.write('</body></html>');

    myWindow.document.close(); // necessary for IE >= 10
    myWindow.focus(); // necessary for IE >= 10*/

    setTimeout(() => {
        myWindow.print();
    }, 1000)

}

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

export const toDateStringWords = (date) => {
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

export const toDateStringWordsFromString = (date, delimiter = "/", subtractMonthBy = 1) => {
    const dates = date.split(delimiter);
    let day;
    let month;
    let year;
    //assumed starts with day, month then year.
    if (dates.length > 0)
        day = parseInt(dates[0]);
    if (dates.length > 1)
        month = months[parseInt(dates[1]) - subtractMonthBy];
    if (dates.length > 2)
        year = parseInt(dates[2]);

    return `${month ? month : ""}${day ? ` ${day}` : ""}${year ? `, ${year}` : ""}`;
}

export const parseDate = input => {
    let parts = input.match(/(\d+)/g);
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
}


let fields = {};
let setFields = () => {
};
export const onChange = (key, value, option, fieldss, setFieldss) => {
    fields = fieldss;
    setFields = setFieldss;
    if (option === options.replace)
        replaceField(key, value);
    else if (option === options.add || option === options.add_and_ignore)
        addField(key, value);
    else if (option === options.delete)
        removeField(key, value);
    else if (option === options.delete_all)
        deleteAllFields(key);
}

const addField = (key, field) => {
    if (exists(key, field)) return;

    let newFields = {...fields};
    let newArr = newFields[key];
    if (newArr == null)
        newArr = [];
    newArr.push(field);
    newFields[key] = newArr;
    setFields(newFields);
}

const removeField = (key, field) => {
    let newFields = [];
    let fieldsArr = fields[key];
    if (fieldsArr == null) return;
    for (let i = 0; i < fieldsArr.length; i++) {
        if (fieldsArr[i] !== field) newFields.push(fieldsArr[i]);
    }

    let newField = {...fields};
    newField[key] = newFields;
    setFields(newField)
}

const replaceField = (key, field) => {
    let newField = {...fields};
    newField[key] = [field];
    setFields(newField);
}

const deleteAllFields = (key) => {
    let newField = {...fields};
    newField[key] = [];
    setFields(newField);
}

const exists = (key, field) => {
    let fieldsArr = fields[key];
    if (fieldsArr == null) return false;
    for (let i = 0; i < fieldsArr.length; i++) {
        if (fieldsArr === field) return true;
    }
    return false;
}

export const parseUrls = (endpoints, fetchFuncs, fields) => {
    const keys = Object.keys(fields);

    //let mainUrl = `${endpoints.expenses}?`;
    //let statsUrl = `${endpoints.expenses_stats}?`;
    for (let i = 0; i < endpoints.length; i++) {
        if (!endpoints[i]?.includes("?"))
            endpoints[i] = `${endpoints[i]}?`;
        else
            endpoints[i] = `${endpoints[i]}&`;
    }
    for (let i = 0; i < keys.length; i++) {
        let miniUrl = "";
        let key = keys[i];
        let values = fields[key] || [];
        for (let f = 0; f < values.length; f++) {
            let value = values[f];
            //if (value?.length > 0)
            miniUrl += `${value}`;
            if (f < values.length - 1)
                miniUrl += ",";
        }
        if (miniUrl) {
            for (let i = 0; i < endpoints.length; i++) {
                endpoints[i] += `${key}=${miniUrl}`;
            }
        }

        if (i < keys.length - 1) {
            for (let i = 0; i < endpoints.length; i++) {
                endpoints[i] += '&';
            }
        }

    }

    if (fetchFuncs?.length > 0) {
        for (let i = 0; i < fetchFuncs?.length; i++)
            if (fetchFuncs[i])
                fetchFuncs[i](endpoints);
    }
}