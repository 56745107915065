/* eslint-disable react-hooks/exhaustive-deps */
import {Bar} from "react-chartjs-2";
import {backgroundColor, GET_REQUEST, primaryColor} from "../../../../../../values/globals";
import PropTypes from 'prop-types';
import {useEffect, useState} from "react";
import endpoints from "../../../../../../routes/endpoints";
import CustomLoader from "../../../../../../components/loaders/custom_loader";
import {makeRequest} from "../../../../../../helpers/network_utils";
import {parseErrorResponse, printElement, showAlert} from "../../../../../../helpers/helper_functions";


const datasetBluePrint = {
    label: 'results',
    fill: false,
    lineTension: 0.3,
    backgroundColor: backgroundColor,
    borderColor: primaryColor,
    borderWidth: 2,
    data: []
}


let labelsObject = [];
let examResultsObject = [];

function Results({semester, user, selectedExamType}) {
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [datasets, setDatasets] = useState([]);

    //console.log(semester);

    //let examResultsObject = [];
    const formatLabels = labels => {
        labelsObject = labels;
        const stringLabels = [];
        for (let i = 0; i < labels.length; i++)
            stringLabels.push(labels[i].name);
        setLabels(stringLabels);
    }

    const formatDatasets = datasets => {
        examResultsObject = datasets;
        const dataset = {...datasetBluePrint};
        const datasetValues = [];

        for (let i = 0; i < labelsObject.length; i++) {
            const result = getExamResult(labelsObject[i], datasets);
            datasetValues.push(result?.total);
        }
        dataset["data"] = datasetValues
        setDatasets([dataset]);
    }

    const getExamResult = (unit, results) => {
        for (let i = 0; i < results.length; i++)
            if (results[i].unit.id === unit.id) return results[i];

        return null;
    }

    useEffect(async () => {
        await getUnits();
        getResults();
        return () => {
        }
    }, [semester, user, selectedExamType]);

    const getUnits = async () => {
        if (semester) {
            setLoading(true);
            await makeRequest(GET_REQUEST, `${endpoints.units}?semester=${semester?.id}`, {}, response => {
                formatLabels(response.data.data);
            }, error => {
                showAlert('error', 'Error', parseErrorResponse(error));
            }).then(() => setLoading(false))
        }
    }

    const getResults = () => {
        if (semester && user && selectedExamType) {
            setLoading(true);
            makeRequest(GET_REQUEST, `${endpoints.exam_results}?semester=${semester?.id}&user=${user?.id}&type=${selectedExamType?.id}`, {}, response => {
                formatDatasets(response.data.data);
            }, error => {
                showAlert('error', 'Error', parseErrorResponse(error));
            }).then(() => setLoading(false))
        }
    }

    const handleResultsPrinting = () => {
        printElement("tabulated_results", "Exam Results");
    }

    return (
        loading ? <CustomLoader/> :
            <div>
                <div>
                    <div className="d-flex justify-content-center align-items-center mt-5 pt-5 mb-5">
                        <h5 className="font-weight-bold text-center mr-3 mb-0"> Tabulated Results</h5>
                        <button className="btn-primary" onClick={handleResultsPrinting}>
                            Print
                        </button>
                    </div>
                    <div id={"tabulated_results"} className="text-left mt-5">
                        <div className="row mb-4">
                            <div className="col-6">
                                <p className="m-0">
                                    Student Name: <b> {user?.name || "-"} </b>
                                </p>
                                <p className="m-0">
                                    Student No: <b> {user?.reg_no || "-"} </b>
                                </p>
                                <p className="m-0">
                                    Email: <b> {user?.email || "-"} </b>
                                </p>
                                <p className="m-0">
                                    Center: <b> {user?.branch?.name || "-"} </b>
                                </p>
                                <p className="m-0">
                                    Program: <b> {user?.semester?.course?.name || "-"} ({user?.semester?.course?.type?.name}) </b>
                                </p>
                            </div>
                            <div className="col-6">
                                <p className="m-0">
                                    Current: <b> Year {semester?.year || "-"}, Semester {semester?.number} </b>
                                </p>
                                <p className="m-0">
                                    Joined: <b> {new Date(user?.created_at || "-").toDateString()} </b>
                                </p>
                                <p className="m-0">
                                    Exam: <b> {selectedExamType?.name || "-"} </b>
                                </p>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Unit</th>
                                <th scope="col">Marks</th>
                                <th scope="col">Grade</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                examResultsObject.map(result =>
                                    <tr key={result?.id}>
                                        <td>{result?.unit?.name}</td>
                                        <td>{result?.total}/100</td>
                                        <td>{result?.grade}</td>
                                    </tr>)
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div className="d-flex justify-content-center align-items-center pt-5 mb-5">
                        <h5 className="font-weight-bold text-center mr-3 mb-0"> Graph Results</h5>
                    </div>
                    <Bar
                        key={semester?.id}
                        data={{labels: labels, datasets: datasets}}
                        options={{
                            title: {
                                display: false,
                                text: '',
                                fontSize: 20
                            },
                            legend: {
                                display: true,
                                position: 'top'
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        min: 0,
                                        max: 100
                                    }
                                }]
                            }
                        }}
                    />
                </div>
            </div>
    )
}

Results.propTypes = {
    semester: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
}

export default Results;