/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import endpoints from "../../../routes/endpoints";
import Table from "../../../components/Table";
import {newsColumns} from "../../../providers/news/data";
import {useHistory} from "react-router";
import CustomLoader from "../../../components/loaders/custom_loader";
import Loader_fn from "../../../providers/loader_fn";

function News() {
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [news, setNews] = useState([]);

    const handleAddNews = e => {
        e.preventDefault();
        history.push(`/news/add`)
    }

    useEffect(() => {
        const loaderFn = new Loader_fn(
            endpoints.news, setLoading, setNews);
        return loaderFn.onUnMount();
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    {loading ?
                        <div className="text-center my-5 py-5">
                            <CustomLoader/>
                        </div> :
                        <div className="card">
                            <div className="card-body">
                                <Table
                                    caption={"All News"}
                                    hideAction={false}
                                    actionText={"Add News"}
                                    actionFunc={handleAddNews}
                                    columns={newsColumns}
                                    data={news}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>


    );
}

export default News;