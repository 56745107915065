import CalendarDate from "./calendar_config/calendar_date";

function ExamCalendar({startDate, endDate, onClickDate, selectedDate}) {
    let myArr = [];
    const diffTime = Math.abs(endDate?.getTime() - startDate?.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let addedDate = new Date(startDate);
    for (let i = 0; i < diffDays; i++) {
        let newDate = new Date(addedDate);
        myArr.push(
            <CalendarDate onClick={date => {
                onClickDate(date);
            }} key={i} date={newDate}
                          color={newDate?.getTime() === selectedDate?.getTime() ? "bg-info" : ""}/>)
addedDate.setDate(addedDate.getDate() + 1);
    }
    return (
        <div className="col-12">
            <div className="card shadow-sm rounded">
                <div className="card-body">
                    <div className="row mx-auto no-gutters">
                        {myArr.length < 1 ?
                            <h5>
                                Can't see dates? No exam configuration may have been uploaded. Contact your super admin
                                to resolve this.
                            </h5> :
                            <div className="row align-items-center">
                                {myArr}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExamCalendar;