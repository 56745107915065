/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {
    handleChangeDataFn,
    parseErrorResponse,
    showAlert,
    toDateStringWordsFromString
} from "../../../../../../helpers/helper_functions";
import {days, months, weeks} from "../../../../../../providers/courses/data";
import {useHistory} from "react-router";
import {POST_REQUEST} from "../../../../../../values/globals";
import endpoints from "../../../../../../routes/endpoints";
import {makeRequest} from "../../../../../../helpers/network_utils";
import Swal from "sweetalert2";

function ExamDeadline({semester}) {
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({});
    const [examDeadline, setExamDeadline] = useState();
    const [loading, setLoading] = useState();

    const getExamDeadline = () => {
        if (semester?.exam_deadline) {
            setExamDeadline(semester?.exam_deadline);
            setData(semester?.exam_deadline);
        }
    }

    const handleSubmit = () => {
        setErrors({})
        setLoading(true);
        let formData = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = data[key];
            formData.append(key, value);
        }
        formData.append("semester", semester?.id);
        const method = POST_REQUEST;
        if (examDeadline)
            formData.append('_method', 'PATCH');
        const route = examDeadline ? `${endpoints.exam_deadlines}${examDeadline?.id}` : endpoints.exam_deadlines;
        makeRequest(method, route, formData, response => {
            Swal.fire(
                'Success!',
                `Exam Deadline was ${examDeadline ? "edited" : "saved"} successfully!`,
                'success'
            ).then(() => {
                setExamDeadline(response.data);
                setData(response.data);
            });
        }, (error) => {
            if (error.response.status === 400) {
                setErrors(error.response.data)
            } else
                showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false);
        }).then()
    }

    useEffect(() => {
        getExamDeadline();
    }, [semester])
    return (
        <div className="container">
            <div className="row mx-auto">
                <div className="col-12 card px-5 py-5">
                    <form>
                        <h3>Exam Deadline</h3>
                        <h5>Hint: Semester exams end at:
                            {` ${semester?.exam ?
                                toDateStringWordsFromString(
                                    semester?.exam?.end_date) : "No Exam Configured"}`}</h5>
                        <div className="form-group">
                            <label className="m-0">Start Day</label>
                            <p className="small">Specifies the day of week the exam results are due after this semester
                                ends.</p>
                            <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                    name="start_day">
                                {data?.start_day ?
                                    <option value={data?.start_day}>{days[data?.start_day - 1]}</option> :
                                    <option>Select Day</option>}
                                {days.map((day, index) => <option key={index} value={index + 1}>{day}</option>)}
                            </select>
                            {errors?.start_day &&
                            <p className="small text-danger">{errors?.start_day[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label className="m-0">Start Week</label>
                            <p className="small">Specifies the week the exam results are due after this semester
                                ends.</p>
                            <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                    name="start_week">
                                {data?.start_week ?
                                    <option value={data?.start_week}>{weeks[data?.start_week - 1]}</option> :
                                    <option>Select Week</option>}
                                {weeks.map((week, index) =>
                                    <option key={index} value={index + 1}>{week}</option>)}
                            </select>
                            {errors?.start_week &&
                            <p className="small text-danger">{errors?.start_week[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label className="m-0">Start Month</label>
                            <p className="small">Specifies the month the exam results are due after this semester
                                ends.</p>
                            <select onChange={e => handleChangeDataFn(e, setData)} className="form-control"
                                    name="start_month">
                                {data?.start_month ?
                                    <option value={data?.start_month}>{months[data?.start_month - 1]}</option> :
                                    <option>Select Month</option>}
                                {months.map((week, index) => <option key={index}
                                                                     value={index + 1}>{week}</option>)}
                            </select>
                            {errors?.start_month &&
                            <p className="small text-danger">{errors?.start_month[0]}</p>}
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => history.goBack()}>Cancel
                        </button>
                        <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                disabled={loading}>{loading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExamDeadline;