/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react';
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory, useRouteMatch} from "react-router";

function UserDetails() {
    const {id} = useRouteMatch().params;
    const [loading, setLoading] = useState();
    const [user, setUser] = useState();
    const history = useHistory();

    const getUser = useCallback(() => {
        setLoading(true);
        makeRequest(GET_REQUEST, `${endpoints.users}${id}`, {}, response => {
            const user = response.data;
            setUser(user);
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false);
        }).then();
    }, [id])

    useEffect(() => {
        getUser();
    }, [id, getUser])


    return (

        <div>
            {loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex align-items-center mb-4">
                                    <div style={{overflow: "hidden", width: "10rem", height: "10rem",}}
                                         className="rounded-circle bg-primary mr-5">
                                        {user?.profile_picture &&
                                        <img src={user?.profile_picture}
                                             style={{width: "10rem", height: "10rem", objectFit: "contain"}}
                                             alt={user?.name}/>}
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3>{user?.name}</h3>
                                        <h5>Status</h5>
                                        <span
                                            className={`badge ${user?.active ? "badge-success" : "badge-danger"}`}>
                                            {user?.active ? "Active" : "In-Active"}</span>
                                        <div className="d-flex my-3">
                                            <button onClick={() => {
                                                history.goBack()
                                            }}
                                                    className="btn btn-sm btn-success">Go Back
                                            </button>
                                            <button onClick={() => {
                                                history.push(`/users/add/${user.id}`)
                                            }}
                                                    className="btn btn-sm btn-info ml-3">Edit
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <h5>Email</h5>
                                <p>{user?.email}</p>
                                <h5>Reg No</h5>
                                <p>{user?.reg_no}</p>
                                {/*<h5>ID No</h5>
                                <p>{user?.id_no}</p>*/}
                                <h5>Center</h5>
                                <p>{user?.branch?.name}</p>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default UserDetails;