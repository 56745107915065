/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../../../../../helpers/network_utils";
import {POST_REQUEST, roles} from "../../../../../../values/globals";
import endpoints from "../../../../../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChangeDataFn, parseErrorResponse, showAlert} from "../../../../../../helpers/helper_functions";
import {useDispatch, useSelector} from "react-redux";
import {getBranches} from "../../../../../../redux/branch/actions";
import CustomLoader from "../../../../../../components/loaders/custom_loader";
import {getUserObject} from "../../../../../../helpers/login";

const $ = window.$;

function BranchAdd({course, getEmBranches}) {
    const [loading, setLoading] = useState();
    const [data, setData] = useState();
    const {branches, loadingBranches} = useSelector(state => state.branchesState);
    const dispatch = useDispatch();
    const loggedInUser = getUserObject();

    useEffect(() => {
        if (loggedInUser?.role === roles.super_admin)
            if (branches == null)
                dispatch(getBranches());
    }, [])

    const handleSubmit = () => {
        setLoading(true);

        makeRequest(POST_REQUEST, endpoints.course_branches, {
            branch: data?.branch,
            course: course?.id
        }, () => {
            Swal.fire(
                'Success!',
                `Center was added successfully!`,
                'success'
            ).then(() => {
                getEmBranches();
                $("#addCourseBranchModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            setLoading(false)
        }).then()
    }

    return (
        <div className="modal fade" id="addCourseBranchModal" role="dialog"
             aria-labelledby="exampleModalCenterTitle"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Course type</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Centers</label>
                                    {loadingBranches ? <CustomLoader/> :
                                        <select onChange={e =>
                                            handleChangeDataFn(e, setData)} className="form-control"
                                                name="branch">
                                            <option value="">Select Center</option>
                                            {
                                                branches?.map(branch => <option key={branch?.id}
                                                                                value={branch?.id}>{branch?.name}</option>)
                                            }
                                        </select>}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button onClick={handleSubmit} type="button" className="btn btn-primary"
                                disabled={loading}>{loading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BranchAdd;