import React, {Component} from 'react';
import {onMount} from "../../../providers/loader_plain";
import endpoints from "../../../routes/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";

class EnquiryDetails extends Component {
    state = {
        enquiry: null
    }

    componentDidMount() {
        this.getTradeInRequests();
    }

    getTradeInRequests = () => {
        const {id} = this.props.match.params;
        onMount(this, `${endpoints.enquiries}${id}`, "enquiry");
    }

    render() {
        let {loading, enquiry} = this.state;
        const {history} = this.props;
        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                enquiry ?
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-8 col-md-10 px-5">
                                    <div className="d-flex justify-content-end">
                                        <button onClick={() => {
                                            history.goBack()
                                        }}
                                                className="btn btn-sm btn-primary ml-3">Go Back
                                        </button>
                                    </div>
                                    <div className="card my-3">
                                        <div className="card-body">
                                            <h5>Name</h5>
                                            <p>{enquiry?.name}</p>
                                            <h5>Phone Number</h5>
                                            <p>{enquiry?.phone_number}</p>
                                            <h5>Email</h5>
                                            <p>{enquiry?.email}</p>
                                            <h5>Message</h5>
                                            <p>{enquiry?.message}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="text-center py-5 my-5">
                        <p className="font-weight-bold">Could not load enquiry</p>
                    </div>
        );
    }
}

export default EnquiryDetails;