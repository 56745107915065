import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {PATCH_REQUEST, POST_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChange, parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";

const $ = window.$;

class CourseTypeAdd extends Component {

    state = {
        name: '',
        code: '',
        loading: false,
        errors: {}
    }

    componentDidMount() {
        const {course_type} = this.props;
        if (course_type)
            this.setState({
                name: course_type.name,
                code: course_type.code
            });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {course_type} = this.props;
        const {
            name,
            code
        } = this.state;
        const method = course_type ? PATCH_REQUEST : POST_REQUEST;
        const endpoint = course_type ? `${endpoints.course_types}${course_type.id}/` : endpoints.course_types;
        makeRequest(method, endpoint, {
            name: name,
            code: code
        }, () => {
            Swal.fire(
                'Success!',
                `Course type was ${course_type ? "Edited" : "Saved"} successfully!`,
                'success'
            ).then(() => {
                this.props.getCourseTypes();
                $("#addCourseTypeModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            if (error.response.status === 400)
                this.setState({
                    errors: error.response.data
                })
            showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            this.setState({loading: false})
        }).then()
    }

    render() {
        const {
            errors,
            loading,
            name,
            code
        } = this.state;
        return (
            <div className="modal fade" id="addCourseTypeModal" role="dialog"
                 aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Course type</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Course Type</label>
                                        <input value={name || ""} onChange={(e) => handleChange(e, this)} type="text"
                                               className="form-control" name="name"/>
                                        {errors.name && <p className="small text-danger">{errors.name[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Course Type Code</label>
                                        <input value={code || ""} onChange={(e) => handleChange(e, this)} type="text"
                                               className="form-control" name="code"/>
                                        {errors.code && <p className="small text-danger">{errors.code[0]}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default CourseTypeAdd;