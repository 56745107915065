/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import Loader_fn from "../../../../providers/loader_fn";
import endpoints from "../../../../routes/endpoints";
import CustomLoader from "../../../../components/loaders/custom_loader";
import Swal from "sweetalert2";
import {makeRequest} from "../../../../helpers/network_utils";
import {DELETE_REQUEST} from "../../../../values/globals";
import {parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";
import AddLecturerUnit from "./add_unit";

const $ = window.$;

function LecturerDetails() {
    const [loading, setLoading] = useState();
    const [loadingUnits, setLoadingUnits] = useState();
    const [lecturer, setLecturer] = useState();
    const [units, setUnits] = useState();
    const {id} = useParams();

    useEffect(() => {
        return getLecturer();
    }, [id])

    useEffect(() => {
        getUnits();
    }, [lecturer])

    const getLecturer = () => {
        const loaderFn = new Loader_fn(`${endpoints.users}${id}`, setLoading, setLecturer, false, false);
        return loaderFn.onUnMount();
    }
    const handleAddLecUnit = e => {
        e.preventDefault();
        $('#addLecUnitModal').modal('show');
    }

    const getUnits = () => {
        if (lecturer) {
            const loaderFn = new Loader_fn(`${endpoints.units}?user=${id}`, setLoadingUnits, setUnits, false);
            return loaderFn.onUnMount();
        }
    }

    return (
        <div className="col-12">
            {lecturer && <AddLecturerUnit lecturer={lecturer} getUnits={getUnits}/>}
            <div className="card mb-2">
                <div className="card-body">
                    {loading ? <CustomLoader/> :
                        <div className="d-flex align-items-center">
                            <div style={{overflow: "hidden", width: "10rem", height: "10rem",}}
                                 className="rounded-circle bg-primary mr-5">
                                {lecturer?.profile_picture &&
                                <img src={lecturer?.profile_picture}
                                     style={{width: "10rem", height: "10rem", objectFit: "contain"}}
                                     alt={lecturer?.name}/>}
                            </div>
                            <div>
                                <h3>Name: <b>{lecturer?.name}</b></h3>
                                <h3>Reg No: <b>{lecturer?.reg_no}</b></h3>
                                <h3>Email: <b>{lecturer?.email}</b></h3>
                                <h3>Phone Number: <b>{lecturer?.phone || "-"}</b></h3>
                            </div>
                        </div>}
                </div>
            </div>
            <div className="card my-3">
                <div className="card-body">
                    <div className="text-center">
                        <button onClick={handleAddLecUnit} className="btn btn-sm btn-info mt-3">
                            Add/Edit Units
                        </button>
                    </div>
                    {
                        loadingUnits ? <CustomLoader/> :
                            units?.map(unit => <SingleUnit unit={unit}
                                                           lecturer={lecturer}
                                                           callback={getUnits}/>)
                    }
                </div>
            </div>
        </div>
    );
}

function SingleUnit({lecturer, unit, callback}) {
    const [loading, setLoading] = useState();
    const handleDeleteUnit = () => {
        if (unit)
            Swal.fire({
                title: "Confirm Delete",
                text: `Are you sure you want to de-assign this unit?`,
                icon: "info",
                showConfirmButton: true,
                showCancelButton: true
            }).then(result => {
                if (result.isConfirmed) {
                    setLoading(true);
                    makeRequest(DELETE_REQUEST,
                        `${endpoints.unit_assignment}?lecturer=${lecturer?.id}&unit=${unit?.id}`,
                        {}, () => {
                            Swal.fire(
                                'Success!',
                                `Unit was de-assigned successfully!`,
                                'success'
                            ).then(() => {
                                if (callback)
                                    callback();
                            });
                        }, (error) => {
                            showAlert('error', 'Error', parseErrorResponse(error));
                        }, () => {
                            setLoading(false);
                        }).then()
                }
            });
    }

    return (
        <div className="card my-3">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <h5>{unit?.name}</h5>
                    <button disabled={loading} onClick={handleDeleteUnit} className="btn btn-sm btn-danger ml-3">
                        Delete
                    </button>
                </div>

            </div>
        </div>
    )
}

export default LecturerDetails;