import React from "react";

export const zoomLinksColumns = (self)=>[
    {
        dataField: 'link',
        text: 'Link',
        formatter: link => <a rel="noreferrer" href={link} target="_blank">{link}</a>
    },
    {
        dataField: 'created_at',
        text: 'Posted On',
        formatter: date => `${new Date(date).toLocaleDateString()}`
    },
    {
        dataField: 'iddd',
        text: 'Action',
        formatter: (id, row) => <button onClick={() => self.handleEditBranch(row)}
                                        className="btn btn-sm btn-primary">
            Edit
        </button>

    },
    {
        dataField: 'idd',
        text: 'Action',
        formatter: (id, row) => <button disabled={self.state.loadingDelete}
                                        onClick={() => self.handleDeleteBranch(row.id)}
                                        className="btn btn-sm btn-danger">
            {self.state.loadingDelete ? "Loading" : "Delete"}
        </button>

    },
]