import React, {Component} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../../components/Table";
import ZoomLinkAdd from "./add";
import Swal from "sweetalert2";
import {makeRequest} from "../../../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";
import {zoomLinksColumns} from "../../../../providers/zoom_links/data";

const $ = window.$;

let self;

class ZoomLink extends Component {
    state = {};

    componentDidMount() {
        self = this;
        this.getZoomLinks();
    }

    handleAddZoomLink = e => {
        e.preventDefault();
        $('#addZoomLinkModal').modal('show');
    }

    handleEditBranch = (zoom_link) => {
        this.setState({
            zoom_link: zoom_link
        }, () => $('#addZoomLinkModal').modal('show'));

    }

    handleDeleteBranch = (id) => {
        Swal.fire({
            title: "Confirm Delete",
            text: `Are you sure you want to delete this zoom link?`,
            icon: "info",
            showConfirmButton: true,
            showCancelButton: true
        }).then(result => {
            if (result.isConfirmed) {

                this.setState({
                    loadingDelete: true
                })
                makeRequest(DELETE_REQUEST, `${endpoints.zoom_links}${id}/`, {}, () => {
                    Swal.fire(
                        'Success!',
                        `Zoom Link was deleted successfully!`,
                        'success'
                    ).then(() => {
                        this.props.getBranches();
                    });
                }, (error) => {
                    showAlert('error', 'Error', parseErrorResponse(error));
                }, () => {
                    this.setState({loadingDelete: false})
                }).then()
            }
        })
    }

    getZoomLinks = () => {
        this.setState({loading: true});
        makeRequest(GET_REQUEST, `${endpoints.zoom_links}`, {}, response => {
            this.setState({zoom_link: response.data});
        }, (error) => {
            showAlert('error', 'Error', parseErrorResponse(error));
        }, () => {
            this.setState({loading: false})
        }).then()
    }

    render() {
        const {loading, zoom_link} = this.state;
        return (loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div>
                    <ZoomLinkAdd zoom_link={zoom_link} key={Math.random()}
                                 getZoomLinks={this.getZoomLinks}/>
                    <Table
                        caption={"Zoom Link"}
                        actionText={"Add Zoom Link"}
                        columns={zoomLinksColumns(self)}
                        actionFunc={this.handleAddZoomLink}
                        data={zoom_link?.id ? [zoom_link] : []}
                    />
                </div>
        );
    }
}

export default ZoomLink;