import News from "../pages/content-creator/news/index";
import NewsAdd from "../pages/content-creator/news/add";
import NewsDetails from "../pages/content-creator/news/details";

const contentCreatorUrls = {
    index: {
        path: '/',
        exact: true,
        component: News,
    },
    news: {
        path: '/news',
        exact: true,
        component: News,
    },
    news_add: {
        path: '/news/add',
        exact: true,
        component: NewsAdd,
    },
    news_edit: {
        path: '/news/add/:id',
        exact: true,
        component: NewsAdd,
    },
    news_details: {
        path: '/news/:id',
        exact: true,
        component: NewsDetails,
    },
}
export default contentCreatorUrls;