/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router";
import {makeRequest} from "../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {numberWithCommas, parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import Swal from "sweetalert2";
import CircularProgress from "@material-ui/core/CircularProgress";


function IncomeDetails(props) {
    const [loading, setLoading] = useState();
    const [income, setIncome] = useState();
    const [loadingDelete, setLoadingDelete] = useState();
    const history = useHistory();

    const getInventory = () => {
        const {id} = props.match.params;
        setLoading(true);
        makeRequest(
            GET_REQUEST,
            `${endpoints.income}${id}`,
            {},
            response => {
                setIncome(response.data);
            },
            error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }, () => {
                setLoading(false);
            }).then();
    }

    const handleDelete = () => {
        Swal.fire({
                title: 'Information!',
                text: 'Are you sure you want delete this?',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancel'
            }
        ).then(result => {
            if (result.isConfirmed) {
                setLoadingDelete(true);
                makeRequest(DELETE_REQUEST, `${endpoints.income}${income?.id}`, {}, () => {
                    Swal.fire(
                        'Success!',
                        'Income deleted successfully!',
                        'success',
                    ).then(() => history.push('/income'));
                }, error => {
                    showAlert('error', 'Error', parseErrorResponse(error))
                }, () => {
                    setLoadingDelete(false);
                }).then()
            }

        });
    }

    useEffect(() => {
        getInventory();
    }, []);

    return (
        <div className="my-5 row justify-content-center">
            {
                loading ?
                    <div className="text-center">
                        <CircularProgress/>
                    </div> :
                    income ?
                        <div className="col-lg-8 col-md-10 px-5">
                            <div className="d-flex justify-content-end">
                                <button onClick={() => {
                                    history.goBack()
                                }}
                                        className="btn btn-sm btn-primary ml-3">Go Back
                                </button>
                                <button onClick={() => {
                                    history.push(`/income/add/${income.id}`)
                                }}
                                        className="btn btn-sm btn-info ml-3">Edit
                                </button>
                                <button
                                    disabled={loadingDelete}
                                    onClick={handleDelete}
                                    className="btn btn-sm btn-danger ml-3">{loadingDelete ? "Loading.." : "Delete"}
                                </button>
                            </div>
                            <div className="card my-3">
                                <div className="card-body">
                                    <h5>Name</h5>
                                    <p>{income?.name}</p>
                                    <h5>Amount</h5>
                                    <p>KES {numberWithCommas(income?.amount || "-")}</p>
                                    <h5>Type</h5>
                                    <p>{income?.type?.name}</p>
                                    <h5>Uploaded By</h5>
                                    <p className="m-0">{income?.uploaded_by?.name}</p>
                                    <p>{income?.uploaded_by?.email}</p>
                                    <h5>Center</h5>
                                    <p>{income?.branch?.name}</p>
                                    <h5>Uploaded On</h5>
                                    <p>{new Date(income?.created_at).toLocaleDateString()}</p>
                                </div>
                            </div>
                        </div> :
                        <div className="text-center">
                            <p>Income could not be loaded.</p>
                        </div>
            }
        </div>
    );
}

export default IncomeDetails;