import React, {useEffect, useState} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../../components/Table";
import Loader_fn from "../../../../providers/loader_fn";
import endpoints from "../../../../routes/endpoints";
import UnitAdd from "./add";

const $ = window.$;

function Units(props) {
    const [loading, setLoading] = useState();
    const [units, setUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState();

    useEffect(() => {
        return getUnits();
    }, [])


    const getUnits = () => {
        const loader = new Loader_fn(`${endpoints.units}`, setLoading, setUnits);
        return loader.onUnMount();
    }

    const handleEditUnit = e => {
        e?.preventDefault();
        $('#addUnitModal').modal('show');
    }

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            //  formatter: row => `${row.first_name} ${row.last_name}`
        },
        {
            dataField: 'name',
            text: 'Name',
            //  formatter: row => `${row.first_name} ${row.last_name}`
        },
        {
            dataField: 'iddd',
            text: 'Action',
            formatter: (id, row) => <button onClick={() => {
                setSelectedUnit(row);
                setTimeout(handleEditUnit, 100);
            }}
                                            className="btn btn-sm btn-primary">
                Edit
            </button>

        },
    ]


    return (
        loading ?
            <div className="text-center">
                <CircularProgress/>
            </div> :
            <div>
                <UnitAdd unit={selectedUnit} key={Math.random()}
                         getUnits={getUnits}/>
                <Table
                    caption={"Units"}
                    actionText={"Add Unit"}
                    columns={columns}
                    actionFunc={handleEditUnit}
                    data={units}
                />
            </div>
    );
}

export default Units;